/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { fetchPrivacyPolicy, updatePrivacyPolicy } from "../../redux/actions/calls";
import Container from "../../components/Container";
import MyButton from "../../components/MyButton";
import { enqueueSnackbar } from "notistack";
import Config from "../../config.json";
import MyEditor from "../../components/MyEditor";

export default function PrivacyPolicyPage() {
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [buttonLoading, setButtonLoading] = useState(false);

	useEffect(() => {
		getPrivacyPolicy();
	}, []);

	const getPrivacyPolicy = () => {
		dispatch(
			fetchPrivacyPolicy({}, (response) => {
				setData(response);
			})
		);
	};

	const onDataChange = (column, value) => {
		setData((prevData) => ({ ...prevData, [column]: value }));
	};

	const validInput = () => {
		for (const language of Config.languages) {
			if (!data[language.code] || data[language.code].replace(/(<([^>]+)>)/gi, "").length === 0) {
				enqueueSnackbar(`Please insert privacy policy in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		return true;
	};

	const updateDataHandle = (e) => {
		e.preventDefault();
		if (!validInput()) {
			return;
		}
		setButtonLoading(true);
		dispatch(
			updatePrivacyPolicy(
				data,
				(response) => {
					setButtonLoading(false);
					enqueueSnackbar("Privacy Policy successfully updated", { variant: "success" });
				},
				(error) => {
					setButtonLoading(false);
				}
			)
		);
	};

	return (
		<div className="page-container privacy-policy">
			<form onSubmit={updateDataHandle}>
				<Container title="Privacy Policy">
					{Config.languages.map((language, i) => (
						<div key={language.code} className="data-editor">
							<p className="data-tite">{language.label}</p>
							<MyEditor onChange={(code) => onDataChange(language.code, code)} setContents={data[language.code]} rtl={language.rtl} />
						</div>
					))}
				</Container>
				<div className="action-buttons-wrapper">
					<MyButton type="submit" label="Update Privacy Policy" isLoading={buttonLoading} disabled={false} />
				</div>
			</form>
		</div>
	);
}
