/* eslint-disable react-hooks/exhaustive-deps */
import { CircularProgress } from "@mui/material";
import Checkbox from "@material-ui/core/Checkbox";
import { translateString } from "../../assets/helpers/namesHelper";
import "./style.scss";
import { toPrice } from "../../assets/helpers/numbersHelper";
import MySelect from "../MySelect";
import MyButton from "../MyButton";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { getImage } from "../../assets/helpers/imagesHelper";
import { useEffect, useState } from "react";
import { convertUtcToLocal } from "../../assets/helpers/dateHelper";

let typingTimeout = null;

export default function MyTable(props) {
	const {
		primaryKey = "id",
		data,
		columns,
		onPageNumberChange,
		pageNumber,
		pageLimitOptions,
		pageLimit,
		onPageLimitChange,
		title,
		onSearch,
		onAddNew,
		actionButtons,
		hideCheckbox = false,
		hideSearch = false,
		tableTopBar,
		exportButton = false,
	} = props;

	const [searchInput, setSearchInput] = useState("");
	const [loadingTable, setLoadingTable] = useState(true);
	const [pageNum, setPageNum] = useState(pageNumber);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : pageLimit);
	const [selectedRows, setSelectedRows] = useState([]);

	useEffect(() => {
		setLoadingTable(true);
		const tableLimit = localStorage.getItem("table-limit");
		if (tableLimit) {
			setLimit(Number(tableLimit));
			onPageLimitChange && onPageLimitChange(tableLimit);
		}
	}, []);

	useEffect(() => {
		setPageNum(pageNumber);
	}, [pageNumber]);

	useEffect(() => {
		const tableLimit = localStorage.getItem("table-limit");
		setLimit(Number(tableLimit || pageLimit));
	}, [pageLimit]);

	useEffect(() => {
		setLoadingTable(false);
	}, [data]);

	const getColumnValue = (row, column) => {
		switch (column.type) {
			case "number":
				return row[column.name].toLocaleString();
			case "price":
				return toPrice(row[column.name], "ILS");
			case "date":
				return convertUtcToLocal(row[column.name]).toLocaleString().replace(",", "");
			case "image":
				return <img className="row-image" src={getImage(Array.isArray(row[column.name]) ? row[column.name][0] : row[column.name], column.folder)} alt="" />;
			case "select":
				return (
					<select
						key={row[column.name]}
						className="table-select-style"
						defaultValue={typeof row[column.name] === "string" ? row[column.name].toLowerCase() : row[column.name]}
						onChange={(e) => column.onChange(row, column, e.target.value)}
					>
						{column.options?.map((option, o) => (
							<option className="select-option-style" key={o} value={typeof option.value === "string" ? option.value.toLowerCase() : option.value}>
								{option.label}
							</option>
						))}
					</select>
				);
			default:
				return translateString(row[column.name], "en");
		}
	};

	const onSearchHandle = (e) => {
		e.preventDefault();
		if (onSearch) {
			setLoadingTable(true);
			onSearch(searchInput);
		}
	};

	const onSearchClear = () => {
		if (searchInput !== "") {
			setSelectedRows([]);
			setSearchInput("");
			if (onSearch) {
				setLoadingTable(true);
				onSearch("");
			}
		}
	};

	useEffect(() => {
		if (typingTimeout) {
			clearTimeout(typingTimeout);
		}
		typingTimeout = setTimeout(() => {
			onSearch && onSearch(searchInput);
		}, 300);
	}, [searchInput]);

	const onPageChange = (num) => {
		if (Number(num) === pageNum) {
			return;
		}
		const pagesNum = Math.ceil(data.totalRows / limit);
		if (num === "") {
			onPageNumberChange("");
			return;
		}
		num = Number(num);
		let destPage;
		if (num > pagesNum) {
			destPage = pagesNum;
		} else if (num < 1) {
			destPage = 1;
		} else {
			destPage = num;
		}
		setSelectedRows([]);
		if (onPageNumberChange) {
			setLoadingTable(true);
			onPageNumberChange(destPage);
		} else {
			setPageNum(destPage);
		}
	};

	const onTableRowClick = (e) => {
		if (e.target.type !== "checkbox" && e.target.type !== "select-one" && e.target.type !== "button" && e.target.className !== "my-button-label") {
			e.target.closest(".my-table-row").classList.toggle("full-content");
		}
	};

	const searchForm = (
		<form className="search-form" onSubmit={onSearchHandle}>
			<SearchIcon />
			<input
				className="search-input"
				name="searchVal"
				type="text"
				placeholder="search..."
				autoComplete="off"
				value={searchInput}
				onChange={(e) => {
					onSearch && setLoadingTable(true);
					setSearchInput(e.target.value);
					setSelectedRows([]);
				}}
			/>
			<ClearIcon className="clear-search-icon" onClick={onSearchClear} />
			<button className="search-button">Search</button>
		</form>
	);

	let dataToRender = onPageNumberChange ? data.data : data.data ? [...data.data] : [];

	const stringifyObjectValues = (obj) => {
		const result = [];

		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				const value = obj[key];

				if (typeof value === "object" && value !== null) {
					const nestedString = stringifyObjectValues(value);
					result.push(nestedString.toLowerCase());
				} else {
					result.push(JSON.stringify(value).toLowerCase());
				}
			}
		}

		return result.join("");
	};
	if (!onSearch && !hideSearch) {
		dataToRender = dataToRender.filter((data) => stringifyObjectValues(data).includes(searchInput.toLowerCase()));
		if (data.totalRows !== dataToRender.length) {
			data.totalRows = dataToRender.length;
			setPageNum(1);
		}
	}

	if (!onPageLimitChange) {
		dataToRender = dataToRender.slice((pageNum - 1) * limit, (pageNum - 1) * limit + limit);
	}

	const pages_buttons = [];
	for (let p = 1; p <= Math.ceil(data.totalRows / limit) && p <= 5; p++) {
		pages_buttons.push(
			<button key={p} value={p} className={p === pageNum ? "pagenation-button active" : "pagenation-button"} onClick={(e) => onPageChange(Number(e.target.value))}>
				{p}
			</button>
		);
	}

	const onRowCheck = (data) => {
		if (selectedRows.includes(data[primaryKey])) {
			selectedRows.splice(selectedRows.indexOf(data[primaryKey]), 1);
		} else {
			selectedRows.push(data[primaryKey]);
		}
		setSelectedRows([...selectedRows]);
	};

	const onPrimaryCheckboxClick = () => {
		let newSelectedRows = [];

		if (selectedRows.length === dataToRender.length) {
			newSelectedRows = [];
		} else {
			newSelectedRows = dataToRender.map((data) => data[primaryKey]);
		}
		setSelectedRows(newSelectedRows);
	};

	const extractLabelFromNestedChildren = (dataValue) => {
		if (!dataValue) {
			return "";
		}
		if (typeof dataValue !== "object") {
			return dataValue;
		}
		if (dataValue.props && dataValue.props.children) {
			return extractLabelFromNestedChildren(dataValue.props.children);
		}
		return dataValue;
	};

	const onExportBtn = (e) => {
		const exportColumns = columns;
		let csvContent = "data:text/csv;charset=utf-8-sig,";
		exportColumns.forEach((column) => {
			if (column.type !== "image") {
				csvContent += (column.label || column.name) + ",";
			}
		});
		csvContent = csvContent.slice(0, -1) + "\r\n";
		const selectedData = dataToRender.filter((dataRow) => selectedRows.includes(dataRow[primaryKey]));
		selectedData.forEach((data) => {
			exportColumns.forEach((column) => {
				if (column.type !== "image") {
					let dataValue = "";
					if (column.render) {
						dataValue = column.render(data);
						if (dataValue && typeof dataValue === "object") {
							dataValue = extractLabelFromNestedChildren(dataValue);
						}
						dataValue = dataValue?.toString();
						csvContent += JSON.stringify(dataValue?.split(",").join("-")) + ",";
					} else if (data[column.name]) {
						dataValue = data[column.name];
						if (typeof data[column.name] === "object") {
							dataValue = translateString(data[column.name], "en");
						}
						dataValue = dataValue?.toString();
						csvContent += JSON.stringify(dataValue?.split(",").join("-")) + ",";
					} else {
						csvContent += `${dataValue},`;
					}
				}
			});
			csvContent = csvContent.slice(0, -1) + "\r\n";
		});
		csvContent.replaceAll('"', "");
		var encodedUri = encodeURI(csvContent);
		var link = document.createElement("a");
		link.setAttribute("href", encodedUri);
		link.setAttribute("download", selectedRows.length + " Table Rows.csv");
		document.body.appendChild(link);
		link.click();
	};

	return (
		<div className="my-table-scrollable">
			<div className="my-table-name">
				{title.icon}
				<p>{title.label}</p>
			</div>
			{!hideSearch && searchForm}
			<div className="my-table-navbar">
				<div className="my-table-navbar-left">
					<div className="table-rows-options-select">
						<MySelect
							label="Rows"
							options={pageLimitOptions.map((rowsOption) => {
								return { label: rowsOption + "", value: rowsOption };
							})}
							withoutSearch
							value={limit}
							onChange={(value) => {
								onPageChange(1);
								setSelectedRows([]);
								localStorage.setItem("table-limit", Number(value));
								onPageLimitChange ? onPageLimitChange(value) : setLimit(value);
							}}
							style={{ minWidth: "unset", width: 80 }}
						/>
					</div>
				</div>
				<div className="my-table-navbar-right">
					{!hideSearch && searchForm}
					{onAddNew && onAddNew.permission && (
						<div className="add-new-product">
							<MyButton label={onAddNew.label} icon={<AddIcon />} onClick={onAddNew.onClick} />
						</div>
					)}
				</div>
			</div>
			<div>{tableTopBar}</div>
			<div className="action-buttons">
				{selectedRows.length > 0 && (
					<>
						{exportButton && <MyButton label="Export to CSV" buttonStyle="softDark" onClick={onExportBtn} />}
						{actionButtons?.map(
							(actionBtn, i) =>
								actionBtn.permission && <MyButton key={i} label={actionBtn.label} buttonStyle="softDark" onClick={() => actionBtn.onClick(dataToRender.filter((dataRow) => selectedRows.includes(dataRow[primaryKey])))} />
						)}
					</>
				)}
			</div>
			<div className="my-table">
				<div className="my-table-header">
					<div className="my-table-row">
						{!hideCheckbox && (
							<Checkbox
								indeterminate={selectedRows.length > 0 && selectedRows.length < dataToRender?.length}
								checked={selectedRows.length === dataToRender?.length}
								size="medium"
								style={{ color: selectedRows.length === dataToRender?.length ? "var(--tint)" : "var(--lightText)" }}
								onChange={onPrimaryCheckboxClick}
							/>
						)}
						{columns.map((column, i) =>
							column.permission === false ? (
								<div key={i}></div>
							) : (
								<div
									key={i}
									className="my-table-column"
									style={{
										textAlign: column.align || "unset",
										minWidth: column.minWidth || "unset",
										maxWidth: column.maxWidth || "unset",
										width: column.width || null,
										flex: column.width || column.maxWidth || column.minWidth ? "unset" : 1,
									}}
								>
									{column.label || column.name}
								</div>
							)
						)}
					</div>
				</div>
				<div className="my-table-body">
					<div className={`my-table-loading-body${loadingTable ? " visible" : ""}`}>
						<CircularProgress style={{ color: "var(--tint)" }} />
					</div>
					{!loadingTable &&
						(dataToRender?.length === 0 ? (
							<p className="empty-table">Nothing to show</p>
						) : (
							dataToRender?.map((row, r) => (
								<div key={r} className={row.hidden || row.hiddenEntry ? "my-table-row hidden" : "my-table-row"} onClick={onTableRowClick}>
									{!hideCheckbox && (
										<Checkbox
											size="medium"
											style={{ color: selectedRows.includes(row[primaryKey]) ? "var(--tint)" : "var(--lightText)" }}
											checked={selectedRows.includes(row[primaryKey])}
											onChange={() => onRowCheck(row)}
										/>
									)}
									{columns.map((column, c) =>
										column.permission === false ? (
											<div key={c}></div>
										) : (
											<div
												key={c}
												className={row.hidden ? "my-table-column hidden-product" : "my-table-column"}
												style={{
													textAlign: column.align || "unset",
													minWidth: column.minWidth || "unset",
													maxWidth: column.maxWidth || "unset",
													width: column.width || null,
													flex: column.width || column.maxWidth || column.minWidth ? "unset" : 1,
												}}
											>
												{column.render ? column.render(row) : getColumnValue(row, column)}
											</div>
										)
									)}
								</div>
							))
						))}
				</div>
			</div>
			{data.totalRows > 0 && (
				<div className="my-table-footer">
					<div className="table-pagenation">
						<label className="pagenation-label">
							Showing <b>{((data.totalRows ? 1 : 0) + (pageNum - 1) * limit).toLocaleString()}</b> to <b>{(data.totalRows < pageNum * limit ? data.totalRows : pageNum * limit).toLocaleString()}</b> of{" "}
							<b>{data.totalRows.toLocaleString()}</b> entries
						</label>
						<div className="pagenation-buttons">
							<button className="pagenation-button" disabled={pageNum === 1} onClick={(e) => pageNum > 1 && onPageChange(pageNum - 1)}>
								Previous
							</button>
							{pages_buttons}
							<label style={{ margin: "0px 5px" }}>...</label>
							<input className="pagenation-number-input" type="number" value={pageNum} onWheel={(e) => e.target.blur()} onChange={(e) => onPageChange(e.target.value)} />
							<button className="pagenation-button" disabled={pageNum >= Math.ceil(data.totalRows / limit)} onClick={(e) => pageNum < Math.ceil(data.totalRows / limit) && onPageChange(pageNum + 1)}>
								Next
							</button>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
