/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useEffect, useState } from "react";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import { scrollTo } from "../../assets/helpers/navHelper";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

function HomeFooter(props) {
	const { t } = props;
	const history = useHistory();

	const system = useSelector((state) => state.system);

	const [navButtons, setNavButtons] = useState([]);
	const [socialMedia, setSocialMedia] = useState([]);

	useEffect(() => {
		initNavButton();
	}, [system.lang]);

	const initNavButton = () => {
		setNavButtons([
			{
				label: t("ourStory"),
				onClick: () => {
					history.push("/");
					scrollTo("#about_us");
				},
			},
			{
				label: t("privacyPolicy"),
				onClick: () => history.push("/privacy"),
			},
		]);
	};

	useEffect(() => {
		setSocialMedia([
			system?.app?.instagram && {
				icon: <InstagramIcon className="social-media-icon" />,
				onClick: () => openNewTab("https://www.instagram.com/" + system?.app?.instagram),
			},
			system?.app?.facebook && {
				icon: <FacebookIcon className="social-media-icon" />,
				onClick: () => openNewTab("https://www.facebook.com/" + system?.app?.facebook),
			},
		]);
	}, [system]);

	const openNewTab = (url) => {
		window.open(url, "_blank");
	};

	return (
		<div className="home-footer">
			<div className="home-footer-content">
				<div className="footer-navigations">
					{navButtons.map((navButton, i) => (
						<button key={i} className="footer-nav-button" onClick={navButton.onClick}>
							{navButton.label}
						</button>
					))}
				</div>
				<div className="footer-navigations">
					{socialMedia?.map((socialMediaBtn, i) => (
						<button key={i} className="footer-nav-button" onClick={socialMediaBtn?.onClick}>
							{socialMediaBtn?.icon}
						</button>
					))}
				</div>
				<div className="copyright-wrapper">
					<p className="copyright-message">© {new Date().getFullYear()} Protein+</p>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(HomeFooter);
