import axios from "axios";
import config from "../config.json";
import { deepParseJSON } from "../assets/helpers/objectsHelper";
import { setError } from "../redux/actions/error";
import { enqueueSnackbar } from "notistack";
import { logout } from "../redux/actions/user";

export async function call(dispatch, method, rest, request, headers = {}) {
	let response = null;
	try {
		response = await axios({
			method: method,
			headers: headers,
			url: config.server_url + "/" + rest,
			data: request,
		});
		const res = response.data;
		deepParseJSON(res);
		return res;
	} catch (error) {
		if (error?.response) {
			dispatch(setError(error.response.data?.errorMessage, error.response.data?.errorCode));
			switch (error.response?.data?.errorCode) {
				case 401:
					enqueueSnackbar(`You are not allowed to perform this activity`, { variant: "error" });
					throw error;
				case 1009:
					enqueueSnackbar(`This activity allowed to staff members ONLY`, { variant: "error" });
					dispatch(logout());
					throw error;
				default:
					throw error;
			}
		} else {
			dispatch(setError("please check your internet connection and try again.", 500));
			throw new Error("please check your internet connection and try again.");
		}
	}
}
