/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import Logo from "../../assets/images/logo.jpg";
import { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { scrollTo } from "../../assets/helpers/navHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import i18n from "../../i18n";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions/system";
import Config from "../../config.json";
import { withTranslation } from "react-i18next";

function HomeHeader(props) {
	const { t } = props;
	const dispatch = useDispatch();
	const history = useHistory();

	const system = useSelector((state) => state.system);

	const [chooseLanguage, setChooseLanguage] = useState(false);
	const [openMobileMenu, setOpenMobileMenu] = useState(false);
	const [navButtons, setNavButtons] = useState([]);

	useEffect(() => {
		initNavButton();
	}, [system.lang]);

	const initNavButton = () => {
		setNavButtons([
			{
				label: t("home"),
				onClick: () => {
					history.push("/");
					scrollTo("#home");
					toggleMobileMenu(false);
				},
			},
			{
				label: t("aboutUs"),
				onClick: () => {
					history.push("/");
					scrollTo("#about_us");
					toggleMobileMenu(false);
				},
			},
			{
				label: t("services"),
				onClick: () => {
					history.push("/");
					scrollTo("#services");
					toggleMobileMenu(false);
				},
			},
			{
				label: t("contactUs"),
				onClick: () => {
					history.push("/");
					scrollTo("#contact_us");
					toggleMobileMenu(false);
				},
			},
		]);
	};

	const toggleMenu = () => {
		setOpenMobileMenu(!openMobileMenu);
	};

	const onMenuBGClick = (e) => {
		if (e.target.closest(".nav-menu")) {
			return;
		}
		setOpenMobileMenu(!openMobileMenu);
	};

	const toggleMobileMenu = (value) => {
		setOpenMobileMenu(value);
	};

	const onLogoClick = () => {
		history.push("/");
		scrollTo("#home");
	};

	const onChangeLanguage = (lang) => {
		i18n.changeLanguage(lang);
		localStorage.setItem("language", lang);
		dispatch(changeLanguage(lang));
		setChooseLanguage(false);
	};

	useEffect(() => {
		document.addEventListener("click", onScreenClick);
		return () => {
			document.removeEventListener("click", onScreenClick);
		};
	}, []);

	const onScreenClick = (e) => {
		if (!e.target.closest(".change-lang-container")) {
			setChooseLanguage(false);
		}
	};

	return (
		<div className="home-header">
			<div className="header-content">
				<img className="header-logo" src={Logo} alt="" onClick={onLogoClick} />
				<div className="navigations">
					<div className="change-lang-container">
						<button className="change-lang-btn" onClick={() => setChooseLanguage(!chooseLanguage)}>
							{i18n.language}
						</button>
						{chooseLanguage && (
							<div className="languages-dd">
								{Config.languages.map((language, l) => (
									<button key={l} className="language-option" onClick={() => onChangeLanguage(language.code)}>
										{language.label}
									</button>
								))}
							</div>
						)}
					</div>
					<MenuIcon className="mobile-menu-btn" onClick={toggleMenu} />
					<div className={openMobileMenu ? "nav-menu-bg opened" : "nav-menu-bg"} onClick={onMenuBGClick}>
						<div className="nav-menu">
							<CloseIcon className="close-mobile-menu-btn" onClick={() => toggleMobileMenu(false)} />
							{navButtons.map((navButton, i) => (
								<button key={i} className="nav-button" onClick={navButton.onClick}>
									{navButton.label}
								</button>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default withTranslation()(HomeHeader);
