/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { getVariationValues } from "../../redux/actions/products";
import { translateString } from "../../assets/helpers/namesHelper";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { openModal } from "../../redux/actions/modal";
import { deleteVariationValues } from "../../redux/actions/calls";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import TuneIcon from "@mui/icons-material/Tune";
import AddNewVariationValue from "../../components/AddNewVariationValue";
import EditVariationValue from "../../components/EditVariationValue";
import { enqueueSnackbar } from "notistack";

export default function VariationsTablePage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const variations = useSelector((state) => state.products.variations);

	const params = useParams();
	const option = params.option;
	const variation = variations?.find((variation) => variation.name === option);

	const [data, setData] = useState({});

	const columns = [
		{
			name: "value",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-variation-value") ? (
					<button className="table-row-name" onClick={() => openEditVariationModal(data)}>
						{translateString(data.value, "en")}
					</button>
				) : (
					translateString(data.value, "en")
				),
		},
	];

	useEffect(() => {
		if (variation) {
			fetchVariations();
		}
	}, [option, variation]);

	const fetchVariations = () => {
		const request = {
			variationTable: variation?.tableName,
		};
		dispatch(
			getVariationValues(request, (response) => {
				const data = {
					totalRows: response.length,
					data: response,
				};
				setData(data);
			})
		);
	};

	const openEditVariationModal = (variationData) => {
		dispatch(openModal(<EditVariationValue onFinish={fetchVariations} variation={variation} variationInitValue={variationData} />, `Edit ${translateString(variation.variation, "en")} Variation`));
	};

	const addNewVariationHandle = () => {
		dispatch(openModal(<AddNewVariationValue variation={variation} onFinish={fetchVariations} />, `Add New ${translateString(variation.variation, "en")}`));
	};

	const onDelete = (selectedData) => {
		const request = {
			ids: selectedData.map((data) => data.id),
			tableName: variation?.tableName,
		};
		dispatch(
			deleteVariationValues(
				request,
				(response) => {
					fetchVariations();
					enqueueSnackbar(`Variation value deleted`, { variant: "success" });
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
				}
			)
		);
	};

	return (
		<div className="page-container variations-table">
			<MyTable
				title={{
					icon: <TuneIcon />,
					label: `${translateString(variation?.variation, "en")} Table`,
				}}
				data={data}
				columns={columns}
				pageNumber={1}
				pageLimit={10}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onAddNew={{
					label: `Add New ${translateString(variation?.variation, "en")}`,
					onClick: () => addNewVariationHandle(),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-variation-value"),
				}}
				actionButtons={[
					{
						label: "Delete",
						onClick: onDelete,
						permission: isAllowedToPerformActivity(user, "/panel/delete-variation-values"),
					},
				]}
			/>
		</div>
	);
}
