/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import { useState } from "react";
import Container from "../Container";
import Config from "../../config.json";
import { enqueueSnackbar } from "notistack";
import { addNewSpecialty, editSpecialty } from "../../redux/actions/calls";

export default function AddOrEditSpecialty(props) {
	const { specialty, onFinish } = props;
	const dispatch = useDispatch();

	const [specialtyData] = useState(specialty || {});
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label={specialty ? "Update" : "Add"} onClick={submitButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, specialtyData]);

	const onSpecialtyNameChange = (language, value) => {
		if (!specialtyData.name) {
			specialtyData.name = {};
		}
		specialtyData.name[language.code] = value;
	};

	const onSpecialtyColorChange = (e) => {
		const color = e.target.value;
		specialtyData.color = color;
	};

	const submitButtonHandle = () => {
		for (const language of Config.languages) {
			if (!specialtyData.name || !specialtyData.name[language.code] || specialtyData.name[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert specialty name in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		if (!specialtyData.color) {
			specialtyData.color = "#000";
		}
		const request = {
			name: JSON.stringify(specialtyData.name),
			color: specialtyData.color,
		};
		if (specialty) {
			request.id = specialtyData.id;
		}
		setLoadingButton(true);
		if (!specialty) {
			//ADD NEW SPECIALTY
			dispatch(
				addNewSpecialty(
					request,
					(response) => {
						setLoadingButton(false);
						onFinish && onFinish();
						dispatch(closeModal());
					},
					(error) => {
						setLoadingButton(false);
					}
				)
			);
		} else {
			//EDIT SPECIALTY
			dispatch(
				editSpecialty(
					request,
					(response) => {
						setLoadingButton(false);
						onFinish && onFinish();
						dispatch(closeModal());
					},
					(error) => {
						setLoadingButton(false);
					}
				)
			);
		}
	};

	return (
		<div className="add-or-edit-coupon">
			<Container vertical>
				<Container title="Specialty Name">
					{Config.languages.map((language, i) => (
						<TextInput key={i} label={language.label} value={specialty?.name[language.code] || ""} onChange={(value) => onSpecialtyNameChange(language, value)} dir={language.rtl ? "rtl" : "ltr"} />
					))}
				</Container>
				<div style={{ display: "flex", alignItems: "center", gap: 10 }}>
					<label>Background Color:</label>
					<input type="color" defaultValue={specialtyData.color || "#000"} onChange={onSpecialtyColorChange} />
				</div>
			</Container>
		</div>
	);
}
