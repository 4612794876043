const user = {
	id: null,
	username: null,
	fullName: null,
	phoneNumber: null,
	sysCreationDate: null,
	addresses: [],
	defaultAddress: null,
	defaultPayment: null,
	profilePicture: null,
	permissions: [],
};

const userReducer = (state = user, action) => {
	switch (action.type) {
		case "SET_USER":
			if (action.user) {
				state = action.user;
			}
			localStorage.setItem("userId", state.id);
			return { ...state };
		case "LOGOUT":
			state.id = null;
			state.username = null;
			state.fullName = null;
			state.phoneNumber = null;
			state.sysCreationDate = null;
			state.addresses = [];
			state.defaultAddress = null;
			state.defaultPayment = null;
			state.profilePicture = null;
			state.permissions = [];
			localStorage.removeItem("userId");
			return { ...state };
		default:
			return state;
	}
};
export default userReducer;
