/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { updateProducPrice } from "../../redux/actions/products";
import { validateDoubleString } from "../../assets/helpers/validations";

export default function EditProductPrice(props) {
	const { initData, onFinish } = props;
	const dispatch = useDispatch();
	const [data, setData] = useState(initData);
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label="Update" onClick={editButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, data]);

	const onDataChange = (key, value) => {
		data[key] = value;
		setData({ ...data });
	};

	const editButtonHandle = () => {
		const discountRegex = /^(\d{1,2}(\.\d+)?|100)%$|^\d+(\.\d+)?$/;
		if (!data.productPrice || data.productPrice < 0 || !validateDoubleString(data.productPrice)) {
			enqueueSnackbar(`Please insert a valid product price`, { variant: "error" });
			return false;
		}

		if (data.productDiscount?.length > 0 && !discountRegex.test(data.productDiscount)) {
			enqueueSnackbar(`Please insert a valid discount product`, { variant: "error" });
			return false;
		}
		const request = {
			productId: data.productEntryId,
			price: data.productPrice,
			discount: data.productDiscount,
		};
		setLoadingButton(true);
		dispatch(
			updateProducPrice(
				request,
				(response) => {
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
				},
				(error) => {
					setLoadingButton(false);
				}
			)
		);
	};

	return (
		<div className="edit-base-price">
			<TextInput label="Product Price" onChange={(value) => onDataChange("productPrice", value)} value={data.productPrice} type="number" inputMode="numeric" required />
			<br />
			<TextInput label="Discount" onChange={(value) => onDataChange("productDiscount", value)} value={data.productDiscount !== null ? data.productDiscount + "" : ""} placeholder="ex: 10%, 13.5%, 50, 99.5" />
		</div>
	);
}
