export function convertFileToBase64(file) {
	return new Promise((resolve, reject) => {
		if (!(file instanceof Blob)) {
			console.error("Parameter is not of type Blob.", file);
			reject(new Error("Parameter is not of type Blob."));
			return;
		}

		const reader = new FileReader();

		reader.onload = () => {
			const base64 = reader.result.split(",")[1];
			resolve(base64);
		};

		reader.onerror = (error) => {
			console.error("Error reading file:", error);
			reject(error);
		};

		reader.readAsDataURL(file);
	});
}
