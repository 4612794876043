/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import Config from "../../config.json";
import Container from "../Container";
import { addNewVariationValue } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { translateString } from "../../assets/helpers/namesHelper";

export default function AddNewVariationValue(props) {
	const { variation, onFinish } = props;
	const dispatch = useDispatch();
	const [variationValue] = useState({});
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label="Add" onClick={addButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, variationValue]);

	const onVariationValueChange = (language, value) => {
		variationValue[language.code] = value;
	};

	const addButtonHandle = () => {
		for (const language of Config.languages) {
			if (!variationValue[language.code] || variationValue[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert variation value in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		const request = {
			variationTable: variation.tableName,
			value: JSON.stringify(variationValue),
		};
		setLoadingButton(true);
		dispatch(
			addNewVariationValue(
				request,
				(response) => {
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
					enqueueSnackbar(`New variation value added`, { variant: "success" });
				},
				(error) => {
					setLoadingButton(false);
				}
			)
		);
	};

	return (
		<div className="add-new-variation-option">
			<Container title={`${translateString(variation.variation, "en")} Value`}>
				{Config.languages.map((language, i) => (
					<TextInput key={i} label={language.label} onChange={(value) => onVariationValueChange(language, value)} dir={language.rtl ? "rtl" : "ltr"} />
				))}
			</Container>
		</div>
	);
}
