import Constants from "../../constants/Constants";

export function validateNumberString(inputString) {
	const numberRegex = /^[0-9]+$/;
	return numberRegex.test(inputString);
}

export function validateDoubleString(inputString, allowNegative = false) {
	const signPart = allowNegative ? "[-+]?" : "";
	const doubleRegex = new RegExp(`^${signPart}(\\d+(\\.\\d*)?|\\.\\d+)([eE][-+]?\\d+)?$`);
	return doubleRegex.test(inputString);
}

export function isValidEmail(email) {
	const emailRegex = /^[a-zA-Z0-9_+&*-]+(?:\.[a-zA-Z0-9_+&*-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}$/;
	return emailRegex.test(email);
}

export function isValidUsername(username) {
	if (!username || username.length < Constants.usernameMinLength || username.length > Constants.usernameMaxLength || username.includes(":")) {
		return false;
	}
	return true;
}

export function isValidFullName(fullName) {
	if (!fullName || fullName.length < Constants.fullnameMinLength || fullName.length > Constants.fullnameMaxLength) {
		return false;
	}
	return true;
}
