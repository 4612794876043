/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { getSupportTickets } from "../../redux/actions/calls";
import { convertUtcToLocal } from "../../assets/helpers/dateHelper";
import MyButton from "../../components/MyButton";
import Ticket from "../../components/Ticket";
import CircularProgress from "@mui/material/CircularProgress";

const limit = 15;

export default function UserTicketsPage() {
	const dispatch = useDispatch();
	const [tickets, setTickets] = useState([]);
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [loadingTickets, setLoadingTickets] = useState(false);
	const [selectedTicket, setSelectedTicket] = useState(null);

	useEffect(() => {
		fetchSupportTickets();
	}, [offset]);

	const fetchSupportTickets = () => {
		const request = {
			offset: offset,
			limit: limit,
		};
		setLoadingTickets(true);
		dispatch(
			getSupportTickets(
				request,
				(response) => {
					setTotalRows(response.totalRows);
					setTickets(offset === 0 ? response.data : [...tickets, ...response.data]);
					setLoadingTickets(false);
				},
				(error) => {
					setTotalRows(0);
					setTickets([]);
					setLoadingTickets(false);
				}
			)
		);
	};

	const onLoadMoreTickets = () => {
		if (offset + limit < totalRows) {
			setOffset(offset + limit);
		} else if (offset < totalRows - limit) {
			setOffset(totalRows - limit);
		}
	};

	const onTicketClick = (ticket) => {
		ticket.supportHasNewMessages = false;
		setSelectedTicket(ticket);
	};

	const updateTicketStatus = (status) => {
		const ticket = tickets.find((ticket) => ticket.id === selectedTicket.id);
		if (ticket) {
			ticket.closed = status;
			setTickets([...tickets]);
		}
	};

	const closeTicket = () => {
		setSelectedTicket(null);
	};

	return (
		<div className="page-container user-tickets">
			<div className={selectedTicket ? "usert-tickets-left closed" : "usert-tickets-left"}>
				{loadingTickets && (
					<div className="loading-tickets">
						<CircularProgress size={24} style={{ color: "var(--tint)" }} />
					</div>
				)}
				{totalRows === 0 ? (
					<p className="empty-list">Nothing to show</p>
				) : (
					tickets.map((ticket, index) => (
						<button key={index} className={ticket.id === selectedTicket?.id ? "user-ticket-button clicked" : "user-ticket-button"} onClick={() => onTicketClick(ticket)}>
							{ticket.supportHasNewMessages && <div className="has-new-messages-dot" />}
							<div className="ticket-info-wrapper">
								<div className="ticket-id-container">
									<label className="ticket-word">Ticket:</label>
									<div className="ticket-id-wrapper">
										<div className="triangle" />
										<label className="ticket-id">{ticket.id}</label>
									</div>
								</div>
								<div className="ticket-status-wrapper">
									<label className="status-label">
										Status: <label className="status-value">{ticket.closed ? "Closed" : "Waiting Response"}</label>
									</label>
									{ticket.supportHasNewMessages && <label className="new-messages-text">New unread messages</label>}
								</div>
								<label className="ticket-date-time">{convertUtcToLocal(ticket.sysCreationDate).toLocaleString().replace(",", "")}</label>
							</div>
						</button>
					))
				)}
				{totalRows > 0 && !loadingTickets && tickets.length < totalRows && (
					<div className="action-buttons-wrapper">
						<MyButton type="button" label="View More" isLoading={loadingTickets} disabled={loadingTickets} onClick={onLoadMoreTickets} />
					</div>
				)}
			</div>
			<div className={selectedTicket ? "usert-tickets-right" : "usert-tickets-right closed"}>
				{selectedTicket && <Ticket key={selectedTicket.id} id={selectedTicket.id} closed={selectedTicket.closed} updateStatus={updateTicketStatus} closeTicket={closeTicket} />}
			</div>
		</div>
	);
}
