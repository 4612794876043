/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { getPackages, getProducts } from "../../redux/actions/products";
import ProductCard from "../ProductCard";
import { CircularProgress } from "@mui/material";
import PackageCard from "../PackageCard";

export default function OrderViewer(props) {
	const { order } = props;
	const dispatch = useDispatch();

	const [products, setProducts] = useState([]);
	const [packages, setPackages] = useState([]);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchProducts(order);
	}, []);

	const fetchProducts = (order) => {
		const productEntryIds = new Set();
		const packageIds = new Set();
		order.items.forEach((item) => {
			if (item.type === "package") {
				packageIds.add(item.id);
				item.entryIds.forEach((entryId) => productEntryIds.add(entryId));
			} else {
				productEntryIds.add(item.id);
			}
		});
		const request = {
			productEntryIds: [...productEntryIds],
			limit: -1,
			showHidden: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setProducts(response.data);
					fetchPackages([...packageIds]);
				},
				(error) => {
					setProducts([]);
					setLoading(false);
				}
			)
		);
	};

	const fetchPackages = async (packageIds) => {
		if (!packageIds || packageIds.length === 0) {
			setPackages([]);
			setLoading(false);
			return;
		}
		const request = {
			packageIds: packageIds,
			limit: -1,
			hideExpired: false,
		};
		dispatch(
			getPackages(
				request,
				(response) => {
					setPackages(response.data);
					setLoading(false);
				},
				(error) => {
					setProducts([]);
					setPackages([]);
					setLoading(false);
				}
			)
		);
	};

	return (
		<div className="order-viewer">
			{loading ? (
				<div className="loading-products">
					<CircularProgress style={{ color: "var(--tint)" }} />
				</div>
			) : (
				order.items.map((item, index) => {
					if (item.type === "package") {
						const packageData = packages.find((packageData) => packageData.id === item.id);
						if (packageData) {
							return <PackageCard key={index} orderItem={item} packageData={packageData} products={products} />;
						} else return <></>;
					} else {
						const productEntry = products.find((product) => product.entryId === item.id);
						if (productEntry) {
							return <ProductCard key={index} orderItem={item} product={productEntry} />;
						} else return <></>;
					}
				})
			)}
		</div>
	);
}
