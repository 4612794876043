/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { closeSideBar, openSideBar, toggleSideBarKeepOpen } from "../../redux/actions/sideBar";
import SideBarButton from "../SideBarButton";
import GridViewIcon from "@mui/icons-material/GridView";
import InventoryIcon from "@mui/icons-material/Inventory";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import Logo from "../../assets/images/logo.jpg";
import { NavLink } from "react-router-dom";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import CategoryIcon from "@mui/icons-material/Category";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { translateString } from "../../assets/helpers/namesHelper";
import TuneIcon from "@mui/icons-material/Tune";
import { useEffect, useState } from "react";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import CampaignIcon from "@mui/icons-material/Campaign";
import DiscountIcon from "@mui/icons-material/Discount";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import SpaIcon from "@mui/icons-material/Spa";
import GroupIcon from "@mui/icons-material/Group";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PolicyIcon from "@mui/icons-material/Policy";

const sideBarButtonsList = [
	{
		icon: <GridViewIcon />,
		to: "/panel",
		label: "Dashboard",
	},
	{
		icon: <InventoryIcon />,
		label: "Products",
		options: [
			{ label: "Products Table", to: "/panel/products-table" },
			{ label: "Packages Table", to: "/panel/packages-table" },
			{ label: "Add New Product", to: "/panel/add-new-product", permission: "/panel/add-new-product" },
			{ label: "Add New Package", to: "/panel/add-new-package", permission: "/panel/add-new-package" },
		],
	},
	{
		icon: <CategoryIcon />,
		label: "Categories",
		options: [
			{ label: "Categories Table", to: "/panel/categories-table" },
			{ label: "Shop by category", to: "/panel/shop-by-category" },
		],
	},
	{
		icon: <PrecisionManufacturingIcon />,
		label: "Brands",
		options: [{ label: "Brands Table", to: "/panel/brands-table" }],
	},
	{
		icon: <SpaIcon />,
		label: "Specialties",
		options: [{ label: "Specialties Table", to: "/panel/specialties-table" }],
	},
	{
		icon: <ShoppingBagIcon />,
		label: "Orders",
		options: [{ label: "Orders Table", to: "/panel/orders-table" }],
		permission: "/panel/get-orders",
	},
	{
		icon: <CampaignIcon />,
		label: "Advertising",
		options: [
			{ label: "Advertisings", to: "/panel/advertisings" },
			{ label: "Add New Ad", to: "/panel/add-ads", permission: "/panel/add-new-ad" },
		],
	},
	{
		icon: <DiscountIcon />,
		label: "Coupons",
		options: [{ label: "Coupons List", to: "/panel/coupons-list", permission: "/panel/fetch-coupons" }],
	},
	{
		icon: <NotificationsActiveIcon />,
		label: "Notifications",
		options: [{ label: "Send Push Notification", to: "/panel/push-notification", permission: "/panel/send-push-notifications" }],
	},
	{
		icon: <GroupIcon />,
		label: "Accounts",
		options: [
			{ label: "Staff Members", to: "/panel/staff-members" },
			{ label: "Accounts", to: "/panel/accounts" },
		],
	},
	{
		icon: <AttachMoneyIcon />,
		label: "Base Prices",
		to: "/panel/base-prices-table",
		permission: "/panel/get-base-prices",
	},
	{
		icon: <SupportAgentIcon />,
		label: "Support",
		options: [
			{ label: "Tickets", to: "/panel/user-tickets" },
			{ label: "Website Contact Us", to: "/panel/contact-us" },
		],
	},
	{
		icon: <SettingsIcon />,
		label: "App Configurations",
		to: "/panel/app-config",
	},
	{
		icon: <PolicyIcon />,
		label: "Privacy Policy",
		to: "/panel/privacy-policy",
		permission: "/panel/update-privacy-policy",
	},
];

export default function SideBar() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const sideBar = useSelector((state) => state.sideBar);
	const variations = useSelector((state) => state.products.variations);

	const [sideBarButtons, setSideBarButtons] = useState(sideBarButtonsList);

	useEffect(() => {
		if (variations && !sideBarButtons.find((sideBarButton) => sideBarButton.label === "Variations")) {
			sideBarButtons.push({
				icon: <TuneIcon />,
				label: "Variations",
				options: variations.map((variation) => {
					return { label: `${translateString(variation.variation, "en")} Table`, to: `/panel/variation-table/${variation.name}` };
				}),
			});
			setSideBarButtons([...sideBarButtons]);
		}
	}, [variations]);

	const onMouseOver = () => {
		if (!sideBar.keepOpen && window.innerWidth >= 767) {
			dispatch(openSideBar());
		}
	};

	const onMouseLeave = () => {
		if (!sideBar.keepOpen && window.innerWidth >= 767) {
			dispatch(closeSideBar());
		}
	};

	const onBackgroundClick = (e) => {
		if (e.target.classList.contains("side-bar-wrapper")) {
			dispatch(closeSideBar());
		}
	};

	const onLogoClick = () => {
		if (window.innerWidth < 767) {
			dispatch(closeSideBar());
		}
	};

	const onKeepMenuOpenClick = () => {
		dispatch(toggleSideBarKeepOpen());
	};

	const optionsLengthGreaterThanZero = (sideBarButton) => {
		if (sideBarButton.options) {
			return (
				sideBarButton.options.filter((option) => {
					return option.permission ? isAllowedToPerformActivity(user, option.permission) : true;
				}).length > 0
			);
		}
		return true;
	};

	return (
		<div className={sideBar.isOpen || sideBar.keepOpen ? "side-bar-wrapper open" : "side-bar-wrapper"} onClick={onBackgroundClick}>
			<div className="side-bar" onMouseOver={onMouseOver} onMouseLeave={onMouseLeave}>
				<div className="sidebar-logo-wrapper">
					<NavLink exact to="/panel" onClick={onLogoClick}>
						<img className="sidebar-logo" src={Logo} alt="" />
					</NavLink>
				</div>
				<div className="side-bar-buttons">
					{sideBarButtons.map((sideBarButton, i) =>
						sideBarButton.permission
							? isAllowedToPerformActivity(user, sideBarButton.permission) && optionsLengthGreaterThanZero(sideBarButton) && <SideBarButton key={i} {...sideBarButton} />
							: optionsLengthGreaterThanZero(sideBarButton) && <SideBarButton key={i} {...sideBarButton} />
					)}
				</div>
				<div className="bottom-buttons">
					<SideBarButton className={sideBar.keepOpen ? `active-keep-open-button` : "keep-open-button"} icon={<MenuOpenIcon />} label="Keep Open" onClick={onKeepMenuOpenClick} />
				</div>
			</div>
		</div>
	);
}
