const products = {
	variations: null,
	specialties: null,
};

const productsReducer = (state = products, action) => {
	switch (action.type) {
		case "SET_VARIATIONS":
			state.variations = action.variations;
			return { ...state };
		case "SET_SPECIALTIES":
			state.specialties = action.specialties;
			return { ...state };
		default:
			return state;
	}
};

export default productsReducer;
