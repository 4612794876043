import { call } from "../../api/call";

export const initAppConfig = () => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "fetch-app-config", {});
			dispatch({
				type: "SET_APP_CONFIG",
				config: response,
			});
		} catch (error) {
			dispatch({
				type: "SET_APP_CONFIG",
				config: [],
			});
		}
	};
};

export const changeLanguage = (lang) => {
	return {
		type: "CHANGE_LANGUAGE",
		lang: lang,
	};
};
