export const setError = (errorMsg, errorCode) => {
	return {
		type: "SET_ERROR",
		payload: {
			errorMsg,
			errorCode,
		},
	};
};

export const clearError = () => {
	return {
		type: "CLEAR_ERROR",
	};
};
