const modal = {
	view: null,
	title: null,
	footer: null,
};

const modalReducer = (state = modal, action) => {
	switch (action.type) {
		case "OPEN_MODAL":
			state.view = action.view;
			state.title = action.title;
			state.footer = action.footer;
			return { ...state };
		case "SET_MODAL_FOOTER":
			state.footer = action.footer;
			return { ...state };
		case "CLOSE_MODAL":
			state.view = null;
			state.title = null;
			state.footer = null;
			return { ...state };
		default:
			return state;
	}
};

export default modalReducer;
