/* eslint-disable react-hooks/exhaustive-deps */
import { NavLink, useHistory, useParams } from "react-router-dom";
import "./style.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { decrypt, editAccount, getAccountAddresses, getAccountCards, getAccountLogs, getAccounts, getPermissions, toggleAccountPermission, toggleStaffMember } from "../../redux/actions/calls";
import { CircularProgress, LinearProgress } from "@mui/material";
import { getImage } from "../../assets/helpers/imagesHelper";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import { enqueueSnackbar } from "notistack";
import { stringToColour } from "../../assets/helpers/objectsHelper";
import BadgeIcon from "@mui/icons-material/Badge";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import InfoIcon from "@mui/icons-material/Info";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import PersonIcon from "@mui/icons-material/Person";
import KeyIcon from "@mui/icons-material/Key";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { login, logout } from "../../redux/actions/user";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { isValidEmail, isValidFullName, isValidUsername } from "../../assets/helpers/validations";
import Constants from "../../constants/Constants";
import { convertUtcToLocal } from "../../assets/helpers/dateHelper";
import HubIcon from "@mui/icons-material/Hub";
import RateReviewIcon from "@mui/icons-material/RateReview";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MyTable from "../../components/MyTable";
import MyButton from "../../components/MyButton";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import MoneyIcon from "@mui/icons-material/Money";
import Config from "../../config.json";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";

const logsLimit = 10;

export default function UserViewerPage() {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useParams();
	const id = Number(params.id);

	const user = useSelector((state) => state.user);

	const [loadingData, setLoadingData] = useState(true);
	const [loadingPermissions, setLoadingPermissions] = useState(true);
	const [data, setData] = useState(null);
	const [permissions, setPermissions] = useState([]);
	const [accountAddresses, setAccountAddresses] = useState([]);
	const [accountCards, setAccountCards] = useState([]);
	const [logs, setLogs] = useState([]);
	const [logsTotalRows, setLogsTotalRows] = useState(0);
	const [logsOffset, setLogsOffset] = useState(0);

	const addressesColumns = [
		{
			label: "Location",
			render: (data) => data.locationName || data.addressType,
			width: 60,
			align: "center",
		},
		{
			name: "address",
		},
		{
			name: "notes",
		},
		{
			label: "Waze",
			render: (data) => <MyButton label="Open in waze" icon={<LocationOnIcon />} onClick={() => handleWazeButtonClick(data.latitude, data.longitude)} />,
			align: "center",
			width: 150,
		},
	];

	const cardsColumns = [
		{
			name: "userId",
			label: "Id Number",
		},
		{
			name: "fullName",
			label: "Full Name",
		},
		{
			name: "l4digits",
			label: "Last 4 Digits",
		},
		{
			name: "tmonth",
			label: "Expiration Month",
		},
		{
			name: "tyear",
			label: "Expiration Year",
		},
		{
			name: "brand",
			label: "Brand",
			render: (data) => Config.cardBrands.find((brand) => brand.code === data.brand)?.label || "Undefined",
		},
	];

	const handleWazeButtonClick = (latitude, longitude) => {
		const wazeLink = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
		window.open(wazeLink, "_blank");
	};

	useEffect(() => {
		if (!id) {
			history.push("/panel");
		}
		if (user.id) {
			getAccount();
			getPermissionsList();
			if (isAllowedToPerformActivity(user, "/panel/get-account-addresses")) {
				getAccountAddressesList();
			}
			if (isAllowedToPerformActivity(user, "/panel/get-account-cards")) {
				getAccountCardsList();
			}
			if (isAllowedToPerformActivity(user, "/panel/get-account-logs")) {
				setLogsOffset(0);
				getAccountLogsList();
			}
		}
	}, [id, user]);

	const getAccountAddressesList = () => {
		const request = {
			accountId: id,
		};
		dispatch(
			getAccountAddresses(
				request,
				(response) => {
					response.forEach((address) => (address["hidden"] = address.deleted));
					const accountAddressesData = {
						totalRows: response.length,
						data: response,
					};
					setAccountAddresses(accountAddressesData);
				},
				(error) => {}
			)
		);
	};

	const getAccountCardsList = () => {
		const request = {
			accountId: id,
		};
		dispatch(
			getAccountCards(
				request,
				(response) => {
					const accountCardsData = {
						totalRows: response.length,
						data: response,
					};
					setAccountCards(accountCardsData);
				},
				(error) => {}
			)
		);
	};

	useEffect(() => {
		if (isAllowedToPerformActivity(user, "/panel/get-account-logs")) {
			getAccountLogsList();
		}
	}, [logsOffset]);

	const getAccountLogsList = () => {
		const request = {
			accountId: id,
			offset: logsOffset,
			limit: logsLimit,
		};
		dispatch(
			getAccountLogs(
				request,
				(response) => {
					setLogsTotalRows(response.totalRows);
					setLogs(logsOffset === 0 ? response.data : [...logs, ...response.data]);
				},
				(error) => {}
			)
		);
	};

	const getAccount = () => {
		const request = {
			ids: [id],
			fetchPermissions: true,
			limit: 1,
			offset: 0,
		};
		setLoadingData(true);
		dispatch(
			getAccounts(
				request,
				(response) => {
					if (response?.totalRows > 0) {
						setData(response.data[0]);
						setLoadingData(false);
					} else {
						history.push("/panel");
					}
				},
				(error) => {
					history.push("/panel");
				}
			)
		);
	};

	const getPermissionsList = () => {
		setLoadingPermissions(true);
		dispatch(
			getPermissions(
				{},
				(response) => {
					setPermissions(response);
					setLoadingPermissions(false);
				},
				(error) => {
					setPermissions([]);
					setLoadingPermissions(false);
				}
			)
		);
	};

	const validInput = (column, value, allowempty) => {
		if (!allowempty && value.trim().length === 0) {
			enqueueSnackbar(`${column} can't be empty.`, { variant: "warning" });
			return;
		}
		if (column === "username") {
			if (value.includes("@")) {
				if (!isValidEmail(value)) {
					enqueueSnackbar("Enter email in a correct format", { variant: "error" });
					return false;
				}
			} else {
				if (!isValidUsername(value)) {
					if (value.includes(":")) {
						enqueueSnackbar("Invalid Email/Username", { variant: "error" });
					} else {
						enqueueSnackbar(`${column} must have a length between ${Constants.usernameMinLength} and  ${Constants.usernameMaxLength} characters`, { variant: "error" });
					}
					return false;
				}
			}
		}

		if (column === "full_name") {
			if (value.trim().length > 0) {
				if (!isValidFullName(value)) {
					enqueueSnackbar(`${column} must have a length between ${Constants.fullnameMinLength} and  ${Constants.fullnameMaxLength} characters`, { variant: "error" });
					return false;
				}
			}
		}
		return true;
	};

	const onEditClick = (e) => {
		const element = e.target.closest(".editable-parent").querySelector(".editable-content");
		const column = e.target.closest(".editable-parent").getAttribute("column");
		const type = e.target.closest(".editable-parent").getAttribute("type") || "input";
		const allowempty = e.target.closest(".editable-parent").getAttribute("allowempty") === "true" || false;
		if (element.classList.contains("label")) {
			var input = document.createElement(type);
			input.className = "editable-content edit-input";
			input.defaultValue = element.innerText;
			input.placeholder = column;
			element.replaceWith(input);
			e.target.closest(".icon-wrapper").classList.add("submit");
		} else {
			const value = element.value;
			if (!validInput(column, value, allowempty)) {
				return;
			}
			const request = {
				columns: [column],
				values: [value],
				id: data.id,
			};
			dispatch(
				editAccount(
					request,
					(response) => {
						if (column === "username") {
							const headers = {
								Authorization: user.id,
							};
							dispatch(
								login(
									{},
									headers,
									() => {},
									(error) => {
										switch (error.response?.data?.errorCode) {
											case 1005:
												dispatch(logout());
												break;
											default:
												return;
										}
									}
								)
							);
						}
						data[column] = value;
						var label = document.createElement("label");
						label.className = "editable-content label";
						label.innerText = data[column].trim().length > 0 ? `${data[column]}` : ``;
						element.replaceWith(label);
						e.target.closest(".icon-wrapper").classList.remove("submit");
						enqueueSnackbar(`User[${data.username}] successfully updated.`, { variant: "success" });
					},
					(error) => {
						var label = document.createElement("label");
						label.className = "editable-content label";
						label.innerText = data[column]?.trim().length > 0 ? `${data[column]}` : ``;
						element.replaceWith(label);
						e.target.closest(".icon-wrapper").classList.remove("submit");
						switch (error.response?.data?.errorCode) {
							case 401:
								return;
							default:
								enqueueSnackbar("Invalid value. Please try another", { variant: "error" });
								break;
						}
					}
				)
			);
		}
	};

	const onPermissionSwitchChange = (isChecked, permission) => {
		const request = {
			accountId: data.id,
			permissionId: permission.id,
		};
		dispatch(
			toggleAccountPermission(
				request,
				(response) => {
					if (isChecked) {
						data.permissions.push({ id: null, label: null, endpoint: permission.endpoint });
					} else {
						data.permissions = data.permissions.filter((perm) => perm.endpoint !== permission.endpoint);
					}
					setData((prevData) => ({ ...prevData }));
					enqueueSnackbar(`User[${data.username}] successfully updated.`, { variant: "success" });
				},
				(error) => {}
			)
		);
	};

	const onColumnSwitchChange = (isChecked, column, dataColumn) => {
		const request = {
			columns: [column],
			values: [isChecked],
			id: data.id,
		};
		dispatch(
			editAccount(
				request,
				(response) => {
					setData({ ...data, [dataColumn]: isChecked ? 1 : 0 });
					enqueueSnackbar(`User[${data.username}] successfully updated.`, { variant: "success" });
				},
				(error) => {}
			)
		);
	};

	const toggleIsStaffMember = (isChecked, dataColumn) => {
		const request = {
			accountId: data.id,
		};
		dispatch(
			toggleStaffMember(
				request,
				(response) => {
					setData({ ...data, [dataColumn]: isChecked ? 1 : 0 });
					enqueueSnackbar(`User[${data.username}] successfully updated.`, { variant: "success" });
				},
				(error) => {}
			)
		);
	};

	const decryptData = (data, key) => {
		if (data[key]["decryptedValue"]) {
			data[key] = data[key].encryptedValue;
			setLogs([...logs]);
			return;
		}

		const request = {
			data: data[key],
		};
		dispatch(
			decrypt(
				request,
				(response) => {
					const encryptedValue = data[key];
					data[key] = {
						encryptedValue: encryptedValue,
						decryptedValue: response.decryptedValue,
					};
					setLogs([...logs]);
				},
				(error) => {}
			)
		);
	};

	const generateAccountLog = (log) => {
		switch (log?.action) {
			case "update_phone_number":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">
							Phone number has been updated to: <b>{log.data.newNumber}</b>
						</p>
					</div>
				);
			case "update_profile_picture":
				return (
					<div className="recent-user-log-body-card">
						{log.data?.newPicture ? (
							<p className="recent-user-log-label">Profile picture has been updated to the image below</p>
						) : (
							<p className="recent-user-log-label">User updated their profile picture, but it was subsequently overwritten by a new profile picture</p>
						)}
						{log.data?.newPicture && (
							<div className="recent-user-log-image-wrapper">
								<img className="recent-user-log-image" src={getImage(log.data.newPicture, "profile_pictures")} alt="" />
							</div>
						)}
					</div>
				);
			case "change_password":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label"> Password has been updated through the application settings</p>
						<p className="recent-user-log-label">
							Old Password:
							<button className="hidden-data-btn" onClick={() => decryptData(log.data, "oldPassword")} disabled={!isAllowedToPerformActivity(user, "/panel/decrypt")}>
								{log.data.oldPassword.decryptedValue ? (
									<label>{log.data.oldPassword.decryptedValue}</label>
								) : (
									<div className="hidden-data-label-wrapper">
										<VisibilityOffIcon style={{ fontSize: 20 }} />
										<label>Hidden Data</label>
									</div>
								)}
							</button>
						</p>
						<p className="recent-user-log-label">
							New Password:
							<button className="hidden-data-btn" onClick={() => decryptData(log.data, "newPassword")} disabled={!isAllowedToPerformActivity(user, "/panel/decrypt")}>
								{log.data.newPassword.decryptedValue ? (
									<label>{log.data.newPassword.decryptedValue}</label>
								) : (
									<div className="hidden-data-label-wrapper">
										<VisibilityOffIcon style={{ fontSize: 20 }} />
										<label>Hidden Data</label>
									</div>
								)}
							</button>
						</p>
					</div>
				);
			case "edit_profile":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">Profile data has been updated</p>
						<p className="recent-user-log-label">
							Username: <b>{log.data.username}</b>
						</p>
						<p className="recent-user-log-label">
							Full Name: <b>{log.data.fullName}</b>
						</p>
					</div>
				);
			case "set_new_password":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">User password has been modified through the password reset process</p>
						<p className="recent-user-log-label">
							New Password:
							<button className="hidden-data-btn" onClick={() => decryptData(log.data, "newPassword")} disabled={!isAllowedToPerformActivity(user, "/panel/decrypt")}>
								{log.data.newPassword.decryptedValue ? (
									<label>{log.data.newPassword.decryptedValue}</label>
								) : (
									<div className="hidden-data-label-wrapper">
										<VisibilityOffIcon style={{ fontSize: 20 }} />
										<label>Hidden Data</label>
									</div>
								)}
							</button>
						</p>
					</div>
				);
			case "delete_account_request":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">Delete account request has been recorded in the system, indicating the initiation of the account deletion process</p>
					</div>
				);
			case "reactive_account":
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">User has successfully reactivated the account and disabled the deletion request</p>
					</div>
				);
			case "add_account_permission":
				return (
					permissions?.length > 0 && (
						<div className="recent-user-log-body-card">
							<p className="recent-user-log-label">
								User has been granted permission to <b>{permissions.find((permission) => permission.id === log.data.permissionId).label}</b>
							</p>
						</div>
					)
				);
			case "delete_account_permission":
				return (
					permissions?.length > 0 && (
						<div className="recent-user-log-body-card">
							<p className="recent-user-log-label">
								User's permission for <b>{permissions.find((permission) => permission.id === log.data.permissionId).label}</b> has been revoked
							</p>
						</div>
					)
				);
			case "admin_edit_account":
				const columns = JSON.parse(log.data.columns);
				const values = JSON.parse(log.data.values);
				return (
					<div className="recent-user-log-body-card">
						<p className="recent-user-log-label">Admin has changed the user information with the below new values</p>
						{columns.map((column, index) => (
							<p key={index} className="recent-user-log-label">
								New {column.replace("_", " ")}: <b>{values[index]}</b>
							</p>
						))}
					</div>
				);
			default:
				return;
		}
	};

	const onLoadMoreLogs = () => {
		if (logsOffset + logsLimit < logsTotalRows) {
			setLogsOffset(logsOffset + logsLimit);
		} else if (logsOffset < logsTotalRows - logsLimit) {
			setLogsOffset(logsTotalRows - logsLimit);
		}
	};

	return (
		<div className="page-container user-viewer-page">
			<div className="seprated-view">
				<div className="user-viewer-left">
					<div className="user-profile-picture-wrapper">
						{loadingData ? (
							<CircularProgress style={{ color: "var(--tint)", display: "inline-block" }} />
						) : (
							<div className="user-profile-picture-container">
								<img className="user-profile-picture" src={getImage(data.profilePicture, "profile_pictures")} alt="" />
							</div>
						)}
					</div>
					<div className="editable-parent user-username-wrapper" column="username">
						{loadingData ? (
							<LinearProgress color="inherit" style={{ maxWidth: "100%", width: "200px", height: "10px", borderRadius: "10px" }} />
						) : (
							<>
								<label className="editable-content label user-username">{data.username}</label>
								{isAllowedToPerformActivity(user, "/panel/edit-account") && (
									<div className="icon-wrapper">
										<EditIcon className="icon edit" onClick={onEditClick} />
										<CheckIcon className="icon submit" onClick={onEditClick} />
									</div>
								)}
							</>
						)}
					</div>

					<div className="editable-parent user-info" column="full_name" allowempty="true">
						<div className="info-label">
							<div className="dot" style={{ backgroundColor: stringToColour(data?.fullName || ""), boxShadow: "0 2px 4px 0 " + stringToColour(data?.fullName || "") }} />
							<label className="label">Full Name</label>
							{!loadingData && isAllowedToPerformActivity(user, "/panel/edit-account") && (
								<div className="icon-wrapper">
									<EditIcon className="icon edit" onClick={onEditClick} />
									<CheckIcon className="icon submit" onClick={onEditClick} />
								</div>
							)}
						</div>
						<div className="info-value">
							<BadgeIcon className="icon" />
							{loadingData ? <LinearProgress color="inherit" style={{ maxWidth: "100%", width: "200px", height: "10px", borderRadius: "10px" }} /> : <label className="editable-content label">{data?.fullName || ""}</label>}
						</div>
					</div>

					<div className="editable-parent user-info" column="phone_number">
						<div className="info-label">
							<div className="dot" style={{ backgroundColor: stringToColour(data?.phoneNumber || ""), boxShadow: "0 2px 4px 0 " + stringToColour(data?.phoneNumber || "") }} />
							<label className="label">Mobile</label>
							{!loadingData && isAllowedToPerformActivity(user, "/panel/edit-account") && (
								<div className="icon-wrapper">
									<EditIcon className="icon edit" onClick={onEditClick} />
									<CheckIcon className="icon submit" onClick={onEditClick} />
								</div>
							)}
						</div>
						<div className="info-value">
							<PhoneIphoneIcon className="icon" />
							{loadingData ? <LinearProgress color="inherit" style={{ maxWidth: "100%", width: "200px", height: "10px", borderRadius: "10px" }} /> : <label className="editable-content label">{data?.phoneNumber || ""}</label>}
						</div>
					</div>

					{data?.ipAddress && (
						<div className="editable-parent user-info" column="phone_number">
							<div className="info-label">
								<div className="dot" style={{ backgroundColor: stringToColour(data?.ipAddress || ""), boxShadow: "0 2px 4px 0 " + stringToColour(data?.ipAddress || "") }} />
								<label className="label">IP Address</label>
							</div>
							<div className="info-value">
								<HubIcon className="icon" />
								{loadingData ? (
									<LinearProgress color="inherit" style={{ maxWidth: "100%", width: "200px", height: "10px", borderRadius: "10px" }} />
								) : (
									<label className="editable-content label">{data?.ipAddress || ""}</label>
								)}
							</div>
						</div>
					)}

					<div className="editable-parent user-info" column="phone_number">
						<div className="info-label">
							<div className="dot" style={{ backgroundColor: stringToColour(data?.authMethod || "Email"), boxShadow: "0 2px 4px 0 " + stringToColour(data?.authMethod || "Email") }} />
							<label className="label">Authentication Method</label>
						</div>
						<div className="info-value">
							{data?.authMethod === "apple" && <AppleIcon className="icon" />}
							{data?.authMethod === "google" && <GoogleIcon className="icon" />}
							{data?.authMethod === null && <AlternateEmailIcon className="icon" />}
							{loadingData ? (
								<LinearProgress color="inherit" style={{ maxWidth: "100%", width: "200px", height: "10px", borderRadius: "10px" }} />
							) : (
								<label className="editable-content label" style={{ textTransform: "capitalize", display: "flex", alignItems: "center" }}>
									{data?.authMethod || "Email"}
								</label>
							)}
						</div>
					</div>

					{data?.staffMember === 1 && (
						<div className="section editable-parent user-permissions">
							<div className="section-title-wrapper">
								<KeyIcon className="icon" />
								<label className="section-title">User Permissions</label>
							</div>
							{loadingData || loadingPermissions ? (
								<CircularProgress style={{ color: "var(--tint)", display: "inline-block" }} />
							) : (
								<div className="user-permissions-content">
									{permissions.map((permission, p) => (
										<div key={p}>
											<FormControlLabel
												control={
													<Switch
														checked={data.permissions?.find((perm) => permission.endpoint === perm.endpoint) ? true : false}
														color="primary"
														size="medium"
														onChange={(e, isChecked) => onPermissionSwitchChange(isChecked, permission)}
														disabled={!isAllowedToPerformActivity(user, "/panel/toggle-account-permission")}
													/>
												}
												label={permission.label}
											/>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>
				<div className="user-viewer-right">
					<div className="section user-info">
						<div className="section-title-wrapper">
							<InfoIcon className="icon" />
							<label className="section-title">Information</label>
						</div>
						<div className="user-info-content">
							<div className="horizontal-information">
								<div className="information-content">
									<p className="information-label">Joined Date</p>
									<div className="information-value">
										<CalendarMonthIcon className="icon" />
										{loadingData ? (
											<LinearProgress color="inherit" style={{ maxWidth: "100%", width: "140px", height: "10px", borderRadius: "10px" }} />
										) : (
											convertUtcToLocal(data?.sysCreationDate || "")
												.toLocaleString()
												.replace(",", "")
										)}
									</div>
								</div>
								<div className="information-content">
									<p className="information-label">Last Login Date</p>
									<div className="information-value">
										<CalendarMonthIcon className="icon" />
										{loadingData ? (
											<LinearProgress color="inherit" style={{ maxWidth: "100%", width: "140px", height: "10px", borderRadius: "10px" }} />
										) : (
											convertUtcToLocal(data?.lastLoginDate || "")
												.toLocaleString()
												.replace(",", "")
										)}
									</div>
								</div>
							</div>
							<div className="horizontal-information">
								<div className="information-content">
									<p className="information-label">Allowed to place order ?</p>
									<div className="information-value">
										<AddShoppingCartIcon className="icon" />
										<FormControlLabel
											key={data?.allowedToPlaceOrder}
											control={
												<Switch
													checked={data?.allowedToPlaceOrder > 0}
													color="primary"
													size="medium"
													onChange={(e, isChecked) => onColumnSwitchChange(isChecked, "allowed_to_place_order", "allowedToPlaceOrder")}
													disabled={!isAllowedToPerformActivity(user, "/panel/edit-account")}
												/>
											}
											label={data?.allowedToPlaceOrder > 0 ? "Allowed" : "Not Allowed"}
										/>
									</div>
								</div>
								<div className="information-content">
									<p className="information-label">Allowed to add review ?</p>
									<div className="information-value">
										<RateReviewIcon className="icon" />
										<FormControlLabel
											key={data?.allowedToAddReview}
											control={
												<Switch
													checked={data?.allowedToAddReview > 0}
													color="primary"
													size="medium"
													onChange={(e, isChecked) => onColumnSwitchChange(isChecked, "allowed_to_add_review", "allowedToAddReview")}
													disabled={!isAllowedToPerformActivity(user, "/panel/edit-account")}
												/>
											}
											label={data?.allowedToAddReview > 0 ? "Allowed" : "Not Allowed"}
										/>
									</div>
								</div>
								<div className="information-content">
									<p className="information-label">Allowed to pay in cash ?</p>
									<div className="information-value">
										<MoneyIcon className="icon" />
										<FormControlLabel
											key={data?.allowedToPayCash}
											control={
												<Switch
													checked={data?.allowedToPayCash > 0}
													color="primary"
													size="medium"
													onChange={(e, isChecked) => onColumnSwitchChange(isChecked, "allowed_to_pay_cash", "allowedToPayCash")}
													disabled={!isAllowedToPerformActivity(user, "/panel/edit-account")}
												/>
											}
											label={data?.allowedToPayCash > 0 ? "Allowed" : "Not Allowed"}
										/>
									</div>
								</div>
							</div>
							<div className="horizontal-information">
								<div className="information-content">
									<p className="information-label">Staff Member ?</p>
									<div className="information-value">
										<PersonIcon className="icon" />
										<FormControlLabel
											key={data?.staffMember}
											control={
												<Switch
													checked={data?.staffMember > 0}
													color="primary"
													size="medium"
													onChange={(e, isChecked) => toggleIsStaffMember(isChecked, "staffMember")}
													disabled={!isAllowedToPerformActivity(user, "/panel/toggle-staff-member")}
												/>
											}
											label={data?.staffMember > 0 ? "Yes" : "No"}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					{isAllowedToPerformActivity(user, "/panel/get-account-addresses") && (
						<div className="section">
							<MyTable
								title={{
									icon: <LocationOnIcon />,
									label: "Account Addresses Table",
								}}
								data={accountAddresses}
								columns={addressesColumns}
								pageNumber={1}
								pageLimit={10}
								pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
								hideCheckbox
							/>
						</div>
					)}
					{isAllowedToPerformActivity(user, "/panel/get-account-cards") && (
						<div className="section">
							<MyTable
								title={{
									icon: <CreditCardIcon />,
									label: "Account Cards Table",
								}}
								data={accountCards}
								columns={cardsColumns}
								pageNumber={1}
								pageLimit={10}
								pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
								hideCheckbox
							/>
						</div>
					)}
					{isAllowedToPerformActivity(user, "/panel/get-account-logs") && (
						<div className="section user-logs">
							<div className="section-title-wrapper">
								<ManageHistoryIcon className="icon" />
								<label className="section-title">Account Logs</label>
							</div>
							<div className="user-logs-body">
								{logs.length === 0 && <p className="empty-logs-list">Nothing to show</p>}
								{logs?.map((log, index) => (
									<div key={index} className={logs.length === index + 1 ? "recent-user-log-card last-child" : "recent-user-log-card"}>
										<div className="dot" style={{ backgroundColor: stringToColour(log.action + ""), boxShadow: `${stringToColour(log.action + "")} 0px 2px 4px 0px` }} />
										<p className="datetime">{convertUtcToLocal(log.sysCreationDate).toLocaleString().replace(",", "")}</p>
										<div>
											{generateAccountLog(log)}
											{log.triggeredById && log.accountId !== log.triggeredById && (
												<p className="recent-user-log-card-triggered-by">
													The activity was triggered by:{" "}
													<NavLink className="recent-logs-label-username" to={"/panel/accounts/" + log.triggeredById}>
														<b>{log.triggeredByUsername}</b>
													</NavLink>
												</p>
											)}
											<p className="recent-user-log-card-ip">
												The activity happened with the IP address: <b>{log.ip}</b>
											</p>
										</div>
									</div>
								))}
								{logs.length !== logsTotalRows && (
									<button className="load-more-messages-btn" onClick={onLoadMoreLogs}>
										Load more logs
									</button>
								)}
							</div>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
