import { call } from "../../api/call";

export const initVariations = () => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-variations", {}, headers);
			dispatch({
				type: "SET_VARIATIONS",
				variations: response,
			});
		} catch (error) {
			dispatch({
				type: "SET_VARIATIONS",
				variations: [],
			});
		}
	};
};

export const initSpecialties = () => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "get-specialties");
			dispatch({
				type: "SET_SPECIALTIES",
				specialties: response,
			});
		} catch (error) {
			dispatch({
				type: "SET_SPECIALTIES",
				specialties: null,
			});
		}
	};
};

export const getVariations = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-variations", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getBrands = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-brands", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getSpecialties = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "get-specialties", request, headers);
			onSuccess && onSuccess(response);
			dispatch({
				type: "SET_VARIATIONS",
				variations: response,
			});
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getCategories = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-categories", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getVariationValues = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-variation-values", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewProduct = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-product", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getProducts = (payload, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "get-products", payload, {});
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getPackages = (payload, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "get-packages", payload, {});
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getPackage = (payload, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "get-package", payload, {});
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getDescription = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "get-product-description", request, {});
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateProduct = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-product", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateProductEntries = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-product-entries", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateProductDescription = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-product-description", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getOrders = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-orders", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateOrderStatus = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/update-order-status", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const fetchCoupons = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/fetch-coupons", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getWebContactUs = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-web-contact-us", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getBasePrices = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-base-prices", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateProductBasePrice = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/update-product-base-price", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updateProducPrice = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/update-product-price", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewPackage = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-package", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getCardDetails = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-card-details", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};
