/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import Config from "../../config.json";
import Container from "../Container";
import { editCategory } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

export default function EditCategory(props) {
	const { category, onFinish } = props;
	const dispatch = useDispatch();
	const [categoryName, setCategoryName] = useState({});
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		const initName = {};
		Config.languages.forEach((language) => {
			initName[language.code] = category.name[language.code];
		});
		setCategoryName(initName);
	}, []);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label="Update" onClick={editButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, categoryName]);

	const onCategoryNameChange = (language, value) => {
		categoryName[language.code] = value;
	};

	const editButtonHandle = () => {
		for (const language of Config.languages) {
			if (!categoryName[language.code] || categoryName[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert category name in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		const request = {
			id: category.id,
			categoryName: JSON.stringify(categoryName),
		};
		setLoadingButton(true);
		dispatch(
			editCategory(
				request,
				(response) => {
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
				},
				(error) => {
					setLoadingButton(false);
				}
			)
		);
	};

	return (
		<div className="edit-category">
			<Container title="Category Name">
				{Config.languages.map((language, i) => (
					<TextInput key={i} label={language.label} onChange={(value) => onCategoryNameChange(language, value)} value={categoryName[language.code]} dir={language.rtl ? "rtl" : "ltr"} />
				))}
			</Container>
		</div>
	);
}
