/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch } from "react-redux";
import { getCardDetails } from "../../redux/actions/products";
import { CircularProgress } from "@mui/material";
import Config from "../../config.json";

export default function CardViewer(props) {
	const { cardId } = props;
	const dispatch = useDispatch();

	const [cardDetails, setCardDetails] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		fetchCardDetails();
	}, []);

	const fetchCardDetails = () => {
		setLoading(true);
		const request = {
			cardId: cardId,
		};
		dispatch(
			getCardDetails(
				request,
				(response) => {
					setCardDetails(response);
					setLoading(false);
				},
				(error) => {
					setCardDetails(null);
					setLoading(false);
				}
			)
		);
	};

	return (
		<div className="card-viewer">
			{loading ? (
				<div className="loading-card">
					<CircularProgress style={{ color: "var(--tint)" }} />
				</div>
			) : (
				<div className="box-more-info-container">
					<p className="box-data-more-info">
						<b>ID Number: </b>
						{cardDetails.userId}
					</p>
					<p className="box-data-more-info">
						<b>Full Name: </b>
						{cardDetails.fullName}
					</p>
					<p className="box-data-more-info">
						<b>Last 4 Digits: </b>
						{cardDetails.l4digits}
					</p>
					<p className="box-data-more-info">
						<b>Card Expiration: </b>
						{cardDetails.tmonth}/{cardDetails.tyear}
					</p>
					<p className="box-data-more-info">
						<b>Card Barnd: </b>
						{Config.cardBrands.find((brand) => brand.code === cardDetails.brand)?.label || "Undefined"}
					</p>
				</div>
			)}
		</div>
	);
}
