import { combineReducers } from "redux";
import userReducer from "./user";
import sideBarReducer from "./sideBar";
import productsReducer from "./products";
import errorReducer from "./error";
import modalReducer from "./modal";
import systemReducer from "./system";

const combinedReducers = combineReducers({
	user: userReducer,
	sideBar: sideBarReducer,
	products: productsReducer,
	error: errorReducer,
	modal: modalReducer,
	system: systemReducer,
});

export default combinedReducers;
