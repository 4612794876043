const Constants = {
	phoneNumberMaxDigits: 10,
	otpLength: 4,
	resetPasswordOTPLength: 6,
	usernameMinLength: 6,
	usernameMaxLength: 45,
	passwordMinLength: 8,
	passwordMaxLength: 45,
	locationNameMaxLength: 45,
	notesMaxLength: 128,
	fullnameMinLength: 2,
	fullnameMaxLength: 45,
	reviewMaxLength: 400,
	couponMaxLength: 8,
	ticketMessageMinLength: 1,
	ticketMessageMaxLength: 512,
};

export default Constants;
