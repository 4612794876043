/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import Cropper from "react-easy-crop";
import { useEffect, useState } from "react";
import MyButton from "../../components/MyButton";
import { addNewAd } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import CampaignIcon from "@mui/icons-material/Campaign";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MySelect from "../../components/MySelect";
import Container from "../../components/Container";
import { getProductNameWithVariations, translateString } from "../../assets/helpers/namesHelper";
import { getBrands, getCategories, getProducts } from "../../redux/actions/products";
import TextInput from "../../components/TextInput";
import { convertLocalDateStringToUTC } from "../../assets/helpers/dateHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const onClickActions = [
	{ label: "Go to screen", value: "GO_TO_SCREEN" },
	{ label: "Go to product", value: "GO_TO_PRODUCT" },
	{ label: "Search for", value: "SEARCH_FOR" },
	{ label: "Open URL", value: "OPEN_URL" },
];

const screensOptions = [
	{ label: "Cart screen", value: "/(tabs)/cart" },
	{ label: "Orders screen", value: "/(tabs)/orders" },
	{ label: "Profile screen", value: "/(tabs)/profile" },
];

export default function AddAdsPage() {
	const dispatch = useDispatch();
	const history = useHistory();

	const variations = useSelector((state) => state.products.variations);

	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [image, setImage] = useState(null);
	const [openCropper, setOpenCropper] = useState(false);
	const [croppedImage, setCroppedImage] = useState(null);
	const [expirationDate, setExpirationDate] = useState("");
	const [onClickAction, setOnClickAction] = useState(null);
	const [products, setProducts] = useState([]);
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [data, setData] = useState({});
	const [searchCustoms, setSearchCustoms] = useState([]);
	const [loadingBtn, setLoadingBtn] = useState(false);

	useEffect(() => {
		const now = new Date();
		const nextWeek = new Date(now);
		nextWeek.setDate(now.getDate() + 7);

		const formattedDate = nextWeek.toISOString().slice(0, -8);

		setExpirationDate(formattedDate);
		fetchProducts();
		fetchBrands();
		fetchCategories();
	}, []);

	const fetchProducts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
			hideOutOfStock: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setProducts(response.data);
				},
				(error) => {
					setProducts([]);
				}
			)
		);
	};

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		const croppedImg = getCroppedImg(image, croppedAreaPixels);
		setCroppedImage(croppedImg);
	};

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result);
				setOpenCropper(true);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCropSubmit = () => {
		setOpenCropper(false);
	};

	const getCroppedImg = (imageSrc, pixelCrop, bgColor = "#ffffff") => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		const img = new Image();
		img.src = imageSrc;

		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;

		ctx.fillStyle = bgColor;
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

		const base64Image = canvas.toDataURL("image/jpeg");
		return base64Image;
	};

	const handleDateChange = (event) => {
		const newDate = event.target.value;
		setExpirationDate(newDate);
	};

	const onActionChange = (value) => {
		setOnClickAction(value);
		setData({});
		setSearchCustoms([]);
	};

	const onDataChange = (key, value) => {
		setData({ ...data, [key]: value });
	};

	const onProductSelect = (entryId) => {
		const product = products.find((product) => product.entryId === Number(entryId));
		if (product) {
			setData({ ...data, productId: product.id, entryId: product.entryId });
		}
	};

	const onSearchCustomsChange = (key, id, values) => {
		const newSearchCustoms = searchCustoms.filter((searchCustom) => searchCustom[id] !== key);
		setSearchCustoms([
			...newSearchCustoms,
			...values.map((value) => {
				return { [id]: key, id: Number(value.value) };
			}),
		]);
	};

	const onSubmitAd = () => {
		if (!expirationDate) {
			enqueueSnackbar(`Please set expiration date.`, { variant: "error" });
			return;
		}
		if (onClickAction && onClickAction !== "SEARCH_FOR" && Object.keys(data).length === 0) {
			enqueueSnackbar(`Please complete the on click action.`, { variant: "error" });
			return;
		}
		setLoadingBtn(true);
		const requestData = { ...data };
		if (searchCustoms.length > 0) {
			requestData["customs"] = searchCustoms;
		}
		const request = {
			image: croppedImage,
			expirationDate: convertLocalDateStringToUTC(expirationDate),
			action: onClickAction,
			data: onClickAction ? JSON.stringify(requestData) : null,
		};
		dispatch(
			addNewAd(
				request,
				(response) => {
					setLoadingBtn(false);
					enqueueSnackbar(`New Ad successfully added.`, { variant: "success" });
					history.push("/panel/advertisings");
				},
				(error) => {
					setLoadingBtn(false);
				}
			)
		);
	};

	return (
		<div className="page-container add-ads">
			<div className="page-container-name">
				<CampaignIcon />
				<p>Add New Ad</p>
			</div>
			<div>
				<p className="aa-label-title">Choose image:</p>
				<input type="file" onChange={handleImageUpload} accept="image/*" />
			</div>
			{openCropper ? (
				<div className="image-cropper">
					<div className="image-cropper-wrapper">
						<Cropper image={image} crop={crop} zoom={zoom} aspect={4 / 2} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
					</div>
					<MyButton label="Crop Image" onClick={handleCropSubmit} />
				</div>
			) : (
				croppedImage && (
					<div className="ad-data-container">
						<img className="cropped-image" src={croppedImage} alt="" />
						<Container title="On Click Action - OPTIONAL" vertical>
							<MySelect label="Action" options={onClickActions} withoutSearch onChange={onActionChange} />
							{onClickAction === "GO_TO_SCREEN" ? (
								<MySelect key="path" label="Path" options={screensOptions} withoutSearch onChange={(value) => onDataChange("path", value)} required />
							) : onClickAction === "OPEN_URL" ? (
								<div style={{ textAlign: "start" }}>
									<TextInput key="url" label="URL" onChange={(value) => onDataChange("url", value)} />
									<FormControlLabel
										control={<Switch checked={data.openInApp === true} color="primary" size="medium" onChange={() => onDataChange("openInApp", data.openInApp ? false : true)} />}
										label="Open in App"
									/>
								</div>
							) : onClickAction === "GO_TO_PRODUCT" ? (
								<MySelect
									key="product"
									label="Product"
									options={products?.map((product) => {
										return { label: getProductNameWithVariations(product, variations, "en", true), value: product.entryId };
									})}
									onChange={onProductSelect}
									onSearch={(input) => fetchProducts(input)}
									required
								/>
							) : onClickAction === "SEARCH_FOR" ? (
								<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
									<TextInput key="searchInput" label="Search input" onChange={(value) => onDataChange("keyword", value)} />
									<Container title="Custom Search">
										<MySelect
											options={brands.map((brand) => {
												return { label: brand.name, value: brand.id };
											})}
											label="Brand"
											onChange={(values) => onSearchCustomsChange("brand", "type", values)}
											onRefresh={fetchBrands}
											multiSelect
										/>
										<MySelect
											options={categories.map((category) => {
												return { label: translateString(category.name, "en"), value: category.id };
											})}
											label="Category"
											onChange={(values) => onSearchCustomsChange("category", "type", values)}
											onRefresh={fetchCategories}
											multiSelect
										/>
									</Container>
								</div>
							) : (
								<></>
							)}
						</Container>
						<Container title="Expiration Date">
							<input type="datetime-local" className="date-range-picker" value={expirationDate} onChange={handleDateChange} required />
						</Container>
						<MyButton label="Submit" onClick={onSubmitAd} isLoading={loadingBtn} />
					</div>
				)
			)}
		</div>
	);
}
