import { call } from "../../api/call";

export const initUser = () => {
	return async (dispatch) => {
		return new Promise(async (resolve, reject) => {
			try {
				const userId = localStorage.getItem("userId");
				if (userId) {
					const headers = {
						Authorization: userId,
					};
					await dispatch(
						login(
							{},
							headers,
							(response) => {
								dispatch(setUser(response));
								resolve();
							},
							(error) => {
								switch (error.response?.data?.errorCode) {
									case 1005:
										dispatch(logout());
										break;
									default:
										dispatch(logout());
										return;
								}
							}
						)
					);
				} else {
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	};
};

export const setUser = (user) => {
	delete user.aiId;
	return {
		type: "SET_USER",
		user,
	};
};

export const login = (request, headers, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "panel/login", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const logout = () => {
	return {
		type: "LOGOUT",
	};
};
