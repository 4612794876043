/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import Container from "../Container";
import MySelect from "../MySelect";
import { getBrands, getCategories } from "../../redux/actions/products";
import { translateString } from "../../assets/helpers/namesHelper";
import { addNewCoupon, editCoupon, getAccounts } from "../../redux/actions/calls";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Constants from "../../constants/Constants";
import { convertDateToInputFormat, convertLocalDateStringToUTC } from "../../assets/helpers/dateHelper";

export default function AddOrEditCoupon(props) {
	const { coupon, onFinish } = props;
	const dispatch = useDispatch();

	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [accounts, setAccounts] = useState([]);

	const [couponData, setCouponData] = useState(
		coupon
			? {
					...coupon,
					startDate: convertDateToInputFormat(coupon.startDate),
					endDate: convertDateToInputFormat(coupon.endDate),
			  }
			: {}
	);
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		fetchAccounts();
		fetchBrands();
		fetchCategories();
	}, []);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label={coupon ? "Update" : "Add"} onClick={editButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, couponData]);

	const fetchAccounts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
		};
		dispatch(
			getAccounts(request, (response) => {
				setAccounts(response.data);
			})
		);
	};

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onCouponCodeChange = (value) => {
		couponData.code = value;
		setCouponData({ ...couponData });
	};

	const onDiscountChange = (value) => {
		couponData.discount = value;
		setCouponData({ ...couponData });
	};

	const onUsernameChange = (value) => {
		couponData.username = value;
		setCouponData({ ...couponData });
	};

	const onBrandSelect = (value) => {
		couponData.brandId = value ? Number(value) : null;
		setCouponData({ ...couponData });
	};

	const onCategorySelect = (value) => {
		couponData.categoryId = value ? Number(value) : null;
		setCouponData({ ...couponData });
	};

	const onStartDateChange = (event) => {
		const date = event.target.value;
		couponData.startDate = date;
		setCouponData({ ...couponData });
	};

	const onEndDateChange = (event) => {
		const date = event.target.value;
		couponData.endDate = date;
		setCouponData({ ...couponData });
	};

	const onNewUsersOnlyCheckChange = () => {
		couponData.newUsersOnly = !couponData.newUsersOnly;
		setCouponData({ ...couponData });
	};

	const isDateBefore = (date1Str, date2Str) => {
		const date1 = new Date(date1Str);
		const date2 = new Date(date2Str);

		return date1 < date2;
	};

	const editButtonHandle = () => {
		const discountRegex = /^(\d{1,2}(\.\d+)?|100)%$|^\d+(\.\d+)?$/;
		if (!couponData.code || couponData.code.length === 0 || couponData.code.length > Constants.couponMaxLength) {
			enqueueSnackbar(`Coupon code must have a length between 0 and  ${Constants.couponMaxLength} characters`, { variant: "error" });
			return;
		}
		if (!discountRegex.test(couponData.discount)) {
			enqueueSnackbar(`Please insert a valid discount value`, { variant: "error" });
			return false;
		}
		if (!couponData.startDate || couponData.startDate.length === 0) {
			enqueueSnackbar(`Please insert start date`, { variant: "error" });
			return;
		}
		if (!couponData.endDate || couponData.endDate.length === 0) {
			enqueueSnackbar(`Please insert end date`, { variant: "error" });
			return;
		}
		if (!isDateBefore(couponData.startDate, couponData.endDate)) {
			enqueueSnackbar(`Make sure that start date is before end date`, { variant: "error" });
			return;
		}
		const request = {
			...couponData,
			startDate: convertLocalDateStringToUTC(couponData.startDate),
			endDate: convertLocalDateStringToUTC(couponData.endDate),
		};
		setLoadingButton(true);
		if (!coupon) {
			//ADD NEW COUPON
			dispatch(
				addNewCoupon(
					request,
					(response) => {
						setLoadingButton(false);
						onFinish && onFinish();
						dispatch(closeModal());
					},
					(error) => {
						setLoadingButton(false);
						switch (error.response?.data?.errorCode) {
							case 1005:
								enqueueSnackbar(`Can't find account with username [${couponData.username}]`, { variant: "error" });
								break;
							case 1003:
								enqueueSnackbar(`Another coupon with same code already exist.`, { variant: "error" });
								break;
							default:
								return;
						}
					}
				)
			);
		} else {
			//EDIT COUPON
			dispatch(
				editCoupon(
					request,
					(response) => {
						setLoadingButton(false);
						onFinish && onFinish();
						dispatch(closeModal());
					},
					(error) => {
						setLoadingButton(false);
						switch (error.response?.data?.errorCode) {
							case 1005:
								enqueueSnackbar(`Can't find account with username [${couponData.username}]`, { variant: "error" });
								break;
							case 1003:
								enqueueSnackbar(`Another coupon with same code already exist.`, { variant: "error" });
								break;
							default:
								return;
						}
					}
				)
			);
		}
	};

	return (
		<div className="add-or-edit-coupon">
			<Container vertical>
				<TextInput label="Coupon code" onChange={onCouponCodeChange} value={couponData.code} required />
				<TextInput label="Discount" onChange={onDiscountChange} value={couponData.discount} required />
				<MySelect
					label="Username"
					options={accounts?.map((account) => {
						return { label: account.username, value: account.username };
					})}
					value={couponData.username}
					onChange={onUsernameChange}
					onSearch={(input) => fetchAccounts(input)}
				/>
				<FormControlLabel control={<Switch checked={couponData.newUsersOnly || false} color="primary" size="medium" onChange={onNewUsersOnlyCheckChange} />} label="New Users ONLY" />
				<Container title="Brand & Category">
					<MySelect
						options={brands.map((brand) => {
							return { label: brand.name, value: brand.id };
						})}
						label="Brand"
						onChange={onBrandSelect}
						onRefresh={fetchBrands}
						value={couponData.brandId}
					/>
					<MySelect
						options={categories.map((category) => {
							return { label: translateString(category.name, "en"), value: category.id };
						})}
						label="Category"
						onChange={onCategorySelect}
						onRefresh={fetchCategories}
						value={couponData.categoryId}
					/>
				</Container>
				<Container title="Coupon Effective Date">
					<div>
						<p className="aa-label-title">Start Date:</p>
						<input type="datetime-local" className="date-range-picker" value={couponData.startDate || ""} onChange={onStartDateChange} required />
					</div>
					<div>
						<p className="aa-label-title">End Date:</p>
						<input type="datetime-local" className="date-range-picker" value={couponData.endDate || ""} onChange={onEndDateChange} required />
					</div>
				</Container>
			</Container>
		</div>
	);
}
