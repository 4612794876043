import React, { Component } from "react";
import "./styles.scss";
import { stringToColour } from "../../assets/helpers/objectsHelper";
import { toFixedNumber } from "../../assets/helpers/numbersHelper";

export default class LineChart extends Component {
	render() {
		const { style, title, icon, data, ignoreZeros, vertical, currency } = this.props;
		const largest_value = data.values.length > 0 ? Math.max(...data.values) : 0;
		return (
			<div className="line-chart" style={style}>
				<div className="chart-title-wrapper">
					{icon}
					<label className="chart-title">{title}</label>
				</div>
				<div className={vertical ? "chart vertical" : "chart"} style={{ zIndex: 2, position: "relative" }}>
					{vertical && (
						<div className="chart-line values">
							<label className="chart-label"></label>
							<div className="chart-value-wrapper values-line">
								<label className="value-display">0</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 5), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 4), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 3), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 2), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 1), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value), 1)}</label>
							</div>
						</div>
					)}
					{ignoreZeros && data?.values.filter((value) => value === 0).length === data?.values.length ? (
						<p className="chart-no-data">Nothing to show</p>
					) : (
						data.labels.map((label, i) =>
							ignoreZeros && data.values[i] ? (
								<div key={(data.values[i] || 0) + i} className="chart-line">
									<label className="chart-label">{label}</label>
									<div className="chart-value-wrapper">
										<div
											className="line-chart-value"
											style={{
												width: vertical ? "100%" : (data.values[i] / largest_value) * 100 + "%",
												height: (data.values[i] / largest_value) * 100 + "%",
												backgroundColor: stringToColour(label + data.values[i]),
												boxShadow: "0 2px 4px 0 " + stringToColour(label + data.values[i]) + "90",
											}}
										>
											<div className="value-viewer">
												<p className="viewer-title">{label}</p>
												<label className="viewer-value">
													{Number(data.values[i]?.toFixed(0)).toLocaleString()}
													{currency && <b style={{ marginLeft: "2px", fontSize: 9 }}>{currency}</b>}
												</label>
											</div>
										</div>
									</div>
								</div>
							) : (
								!ignoreZeros && (
									<div key={(data.values[i] || 0) + i} className="chart-line">
										<label className="chart-label">{label}</label>
										<div className="chart-value-wrapper">
											<div
												className="line-chart-value"
												style={{
													width: vertical ? "100%" : (data.values[i] / largest_value) * 100 + "%",
													height: (data.values[i] / largest_value) * 100 + "%",
													backgroundColor: stringToColour(label + data.values[i]),
													boxShadow: "0 2px 4px 0 " + stringToColour(label + data.values[i]) + "90",
												}}
											>
												<div className="value-viewer">
													<p className="viewer-title">{label}</p>
													<label className="viewer-value">
														{Number(data.values[i]?.toFixed(0)).toLocaleString()}
														{currency && <b style={{ marginLeft: "2px", fontSize: 9 }}>{currency}</b>}
													</label>
												</div>
											</div>
										</div>
									</div>
								)
							)
						)
					)}
					{!vertical && (
						<div className="chart-line values">
							{!ignoreZeros || (data?.values.filter((value) => value === 0).length !== data?.values.length && <label className="chart-label"></label>)}
							<div className="chart-value-wrapper values-line">
								<label className="value-display">0</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 5), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 4), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 3), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 2), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value - (largest_value / 6) * 1), 1)}</label>
								<label className="value-display">{toFixedNumber(parseInt(largest_value), 1)}</label>
							</div>
						</div>
					)}
				</div>
			</div>
		);
	}
}
