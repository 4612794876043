export function deepParseJSON(obj) {
	for (const key in obj) {
		if (typeof obj[key] === "string") {
			try {
				if (/^\s*[{[]/.test(obj[key])) {
					obj[key] = JSON.parse(obj[key]);
				}
			} catch (error) {
				console.error(`Error parsing JSON for key '${key}': ${error.message}`);
			}
		} else if (typeof obj[key] === "object" && obj[key] !== null) {
			deepParseJSON(obj[key]);
		}
	}
}

export function stringToColour(str) {
	if (str) {
		let hash = 0;
		for (let i = 0; i < str.length; i++) {
			hash = str.charCodeAt(i) + ((hash << 5) - hash);
		}
		let c = (hash & 0x00ffffff).toString(16).toUpperCase();
		let color = "00000".substring(0, 6 - c.length) + c;
		return "#" + color;
	}
}
