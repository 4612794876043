/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { getSpecialties } from "../../redux/actions/products";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { openModal } from "../../redux/actions/modal";
import { deleteSpecialties } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import { translateString } from "../../assets/helpers/namesHelper";
import SpaIcon from "@mui/icons-material/Spa";
import AddOrEditSpecialty from "../../components/AddOrEditSpecialty";

export default function SpecialtiesTablePage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const [data, setData] = useState({});

	const columns = [
		{
			name: "name",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-specialty") ? (
					<button className="table-row-name" onClick={() => openEditBrandModal(data)}>
						{translateString(data.name, "en")}
					</button>
				) : (
					translateString(data.name, "en")
				),
		},
		{
			name: "color",
			render: (data) => <input type="color" value={data.color} readOnly disabled />,
		},
	];

	useEffect(() => {
		fetchSpecialties();
	}, []);

	const fetchSpecialties = () => {
		dispatch(
			getSpecialties({}, (response) => {
				const data = {
					totalRows: response.length,
					data: response,
				};
				setData(data);
			})
		);
	};

	const openEditBrandModal = (specialty) => {
		dispatch(openModal(<AddOrEditSpecialty onFinish={fetchSpecialties} specialty={specialty} />, "Edit Specialty"));
	};

	const addNewSpecialtyHandle = () => {
		dispatch(openModal(<AddOrEditSpecialty onFinish={fetchSpecialties} />, "Add New Specialty"));
	};

	const onDelete = (selectedData) => {
		const request = {
			ids: selectedData.map((data) => data.id),
		};
		dispatch(
			deleteSpecialties(
				request,
				(response) => {
					fetchSpecialties();
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
				}
			)
		);
	};

	return (
		<div className="page-container brands-table">
			<MyTable
				title={{
					icon: <SpaIcon />,
					label: "Specialties Table",
				}}
				data={data}
				columns={columns}
				pageNumber={1}
				pageLimit={10}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onAddNew={{
					label: "Add New Specialty",
					onClick: () => addNewSpecialtyHandle(),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-specialty"),
				}}
				actionButtons={[
					{
						label: "Delete",
						onClick: onDelete,
						permission: isAllowedToPerformActivity(user, "/panel/delete-specialties"),
					},
				]}
			/>
		</div>
	);
}
