import { useDispatch, useSelector } from "react-redux";
import { getImage } from "../../assets/helpers/imagesHelper";
import "./style.scss";
import { logout } from "../../redux/actions/user";
import { useState } from "react";
import LogoutIcon from "@mui/icons-material/Logout";
import { useEffect } from "react";
import { openSideBar } from "../../redux/actions/sideBar";
import MenuIcon from "@mui/icons-material/Menu";

export default function Header() {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);
	const [openProfileDD, setOpenProfileDD] = useState(false);
	const [profileButtons] = useState([
		{
			label: "Logout",
			icon: <LogoutIcon />,
			onClick: () => dispatch(logout()),
		},
	]);

	useEffect(() => {
		document.addEventListener("click", onDocumentClick);

		return () => {
			document.removeEventListener("click", onDocumentClick);
		};
	}, []);

	const onDocumentClick = (e) => {
		if (!e.target.closest(".profile-button")) {
			setOpenProfileDD(false);
		}
	};

	const openProfileDropDown = () => {
		setOpenProfileDD(!openProfileDD);
	};

	const openMobileMenu = () => {
		dispatch(openSideBar());
	};

	return (
		<div className="header">
			<div className="header-left">
				<button className="mobile-menu-button" onClick={openMobileMenu}>
					<MenuIcon className="menu-icon" />
				</button>
			</div>
			<div className="header-center"></div>
			<div className="header-right">
				<div className="profile-button">
					<button className="profile-img-btn" onClick={openProfileDropDown}>
						<img className="profile-button-image" src={getImage(user.profilePicture, "profile_pictures")} alt="" />
					</button>
					{openProfileDD && (
						<div className="profile-dropdown">
							{profileButtons.map((profileButton, i) => (
								<button key={i} className="profile-dropdown-button" onClick={profileButton.onClick}>
									<div className="pb-icon">{profileButton.icon}</div>
									{profileButton.label}
								</button>
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
}
