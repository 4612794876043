export const openModal = (view, title = null, footer = null) => {
	return {
		type: "OPEN_MODAL",
		view,
		title,
		footer,
	};
};

export const setModalFooter = (footer) => {
	return {
		type: "SET_MODAL_FOOTER",
		footer,
	};
};

export const closeModal = () => {
	return {
		type: "CLOSE_MODAL",
	};
};
