import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { closeModal } from "../../redux/actions/modal";

export default function Modal() {
	const dispatch = useDispatch();
	const modal = useSelector((state) => state.modal);

	useEffect(() => {
		if (modal.view) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = null;
		}
	}, [modal]);

	const closeModalHandle = () => {
		dispatch(closeModal());
	};

	return (
		modal.view && (
			<div className="modal">
				<div className="modal-container">
					<div className="modal-header">
						<p className="mdoal-title">{modal.title}</p>
						<CloseIcon className="modal-close-button" onClick={closeModalHandle} />
					</div>
					<div className="modal-body">{modal.view}</div>
					{modal.footer && <div className="modal-footer">{modal.footer}</div>}
				</div>
			</div>
		)
	);
}
