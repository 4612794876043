import { useEffect, useMemo, useState } from "react";
import "./style.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import IconButton from "@material-ui/core/IconButton";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RefreshIcon from "@mui/icons-material/Refresh";

export default function MySelect(props) {
	const { options, label, addNewAction, onChange, multiSelect, required, withoutSearch = false, value: propValue, style, onRefresh, onSearch = null } = props;
	const [searchInput, setSearchInput] = useState("");
	const [selectedLabel, setSelectedLabel] = useState("");
	const [selectedCheckbox, setSelectedCheckbox] = useState([]);

	useEffect(() => {
		document.addEventListener("mousedown", handleMouseDown);

		return () => {
			document.removeEventListener("mousedown", handleMouseDown);
		};
	}, []);

	useEffect(() => {
		if (propValue) {
			if (multiSelect) {
				if (Array.isArray(propValue)) {
					const initValues = [];
					propValue.forEach((val) => {
						const initialSelected = options.find((option) => option.value === val);
						if (initialSelected) {
							initValues.push(initialSelected);
						}
					});
					setSelectedCheckbox(initValues);
				} else {
					const initialSelected = options.find((option) => option.value === propValue);
					if (initialSelected) {
						setSelectedCheckbox([initialSelected]);
					}
				}
			} else {
				const initialSelected = options.find((option) => option.value === propValue);
				if (initialSelected) {
					setSelectedLabel(initialSelected.label);
				}
			}
		}
	}, [options, propValue, multiSelect]);

	useEffect(() => {
		if (!propValue) {
			setSelectedCheckbox([]);
			setSelectedLabel("");
		}
	}, [propValue]);

	const toggleDropdown = (e) => {
		const dropDowns = document.getElementsByClassName("my-select");
		const mySelect = e.target.closest(".my-select");
		const mySelectOpened = mySelect.classList.contains("opened");
		for (let i = 0; i < dropDowns.length; i++) {
			dropDowns[i].classList.remove("opened");
		}
		if (!mySelectOpened) {
			mySelect.classList.add("opened");
		}
	};

	const handleMouseDown = (e) => {
		const mySelect = e.target.closest(".my-select");
		if (!mySelect) {
			const dropDowns = document.getElementsByClassName("my-select");
			for (let i = 0; i < dropDowns.length; i++) {
				dropDowns[i].classList.remove("opened");
			}
			setSearchInput("");
		}
	};

	const selectValue = (e) => {
		const value = e.target.getAttribute("value");
		const label = e.target.innerText;
		onChange && onChange(!required && selectedLabel === label ? null : value);
		setSelectedLabel(!required && selectedLabel === label ? "" : label);
		e.target.closest(".my-select").classList.remove("opened");
	};

	const onSelect = (option) => {
		const alreadtExist = selectedCheckbox.find((sc) => sc.value === option.value);
		if (alreadtExist) {
			const index = selectedCheckbox.indexOf(alreadtExist);
			selectedCheckbox.splice(index, 1);
		} else {
			selectedCheckbox.push({ ...option });
		}
		const selected = [...selectedCheckbox];
		setSelectedCheckbox(selected);
		onChange && onChange(selected);
	};

	const onSearchInputChange = (value) => {
		setSearchInput(value);
		onSearch && onSearch(value);
	};

	const filteredOptions = useMemo(() => (onSearch ? options : options.filter((option) => option.label.toLowerCase().includes(searchInput.toLowerCase()))), [options, searchInput, onSearch]);

	return (
		<div className="my-select" style={style}>
			<button className={"my-select-button"} type="button" onClick={toggleDropdown}>
				<label className={`my-select-label${selectedLabel || selectedCheckbox.length > 0 ? " with-value" : ""}${required ? " required" : ""}`}>{label}</label>
				<label className="selected-value">{multiSelect ? selectedCheckbox.map((sc) => sc.label).join(", ") : selectedLabel}</label>
				<KeyboardArrowDownIcon className={"button-toggle-arrow"} />
			</button>
			<div className={"my-select-dropDown"}>
				{(!withoutSearch || addNewAction) && (
					<div className="dropdown-header">
						{!withoutSearch && (
							<input className="my-select-search-bar" type="text" value={searchInput} onChange={(e) => onSearchInputChange(e.target.value)} placeholder={`Search for ${label.toLowerCase()}`} />
						)}
						{addNewAction && (
							<IconButton onClick={addNewAction}>
								<AddIcon />
							</IconButton>
						)}
						{onRefresh && (
							<IconButton onClick={onRefresh}>
								<RefreshIcon />
							</IconButton>
						)}
					</div>
				)}
				<div className="my-select-options">
					{filteredOptions.length === 0 ? (
						<p className="empty-list">Nothing to show</p>
					) : (
						filteredOptions.map((option, i) => {
							return multiSelect ? (
								<FormControlLabel
									key={i}
									className="my-select-option"
									control={<Checkbox style={{ color: "var(--tint)" }} onChange={() => onSelect(option)} checked={selectedCheckbox.find((sc) => sc.value === option.value) ? true : false} />}
									label={option.label}
									style={{ userSelect: "none" }}
								/>
							) : (
								<div className={selectedLabel === option.label ? "my-select-option selected" : "my-select-option"} key={i} value={option.value} onClick={selectValue}>
									{option.label}
								</div>
							);
						})
					)}
				</div>
			</div>
		</div>
	);
}
