import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useEffect, useMemo, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { closeSideBar } from "../../redux/actions/sideBar";

export default function SideBarButton(props) {
	const { icon, label, options, to, onClick, className } = props;

	const dispatch = useDispatch();

	const location = useLocation();
	const memoizedLocation = useMemo(() => location, [location]);
	const user = useSelector((state) => state.user);
	const sideBar = useSelector((state) => state.sideBar);

	const [openOptions, setOpenOptions] = useState(false);
	const [isActive, setIsActive] = useState(false);

	useEffect(() => {
		setIsActive(options?.map((option) => option.to).includes("/panel/" + memoizedLocation.pathname.split("/")[2]));
	}, [memoizedLocation, options]);

	const toggleDropdown = () => {
		setOpenOptions(!openOptions);
	};

	const onButtonClick = () => {
		onClick && onClick();
		if (window.innerWidth < 767) {
			dispatch(closeSideBar());
		}
	};

	return (
		<div className={sideBar.isOpen || sideBar.keepOpen ? "side-bar-button open" : "side-bar-button"}>
			{options ? (
				<div className={isActive ? "sidebar-button active" : "sidebar-button"} onClick={toggleDropdown}>
					<div className="button-icon">{icon}</div>
					<div className="sidebar-button-label-wrapper">
						<span className="button-label">{label}</span>
						<KeyboardArrowDownIcon className={openOptions ? "button-toggle-arrow opened" : "button-toggle-arrow"} />
					</div>
				</div>
			) : onClick ? (
				<button onClick={onButtonClick} className={`sidebar-button${className ? " " + className : ""}`}>
					<div className="button-icon">{icon}</div>
					<div className="sidebar-button-label-wrapper">
						<span className="button-label">{label}</span>
						<div className="button-toggle-arrow"></div>
					</div>
				</button>
			) : (
				<NavLink exact to={to} className="sidebar-button" onMouseDown={onButtonClick}>
					<div className="button-icon">{icon}</div>
					<div className="sidebar-button-label-wrapper">
						<span className="button-label">{label}</span>
						<div className="button-toggle-arrow"></div>
					</div>
				</NavLink>
			)}
			{options && (
				<div className={openOptions ? "side-bar-button-options opened" : "side-bar-button-options"}>
					{options.map((option, i) =>
						option.permission ? (
							isAllowedToPerformActivity(user, option.permission) && (
								<NavLink exact key={i} to={option.to} className="side-bar-option" onClick={onButtonClick}>
									<FiberManualRecordIcon className="option-icon" />
									<span className="option-label">{option.label}</span>
								</NavLink>
							)
						) : (
							<NavLink key={i} to={option.to} className="side-bar-option" onClick={onButtonClick}>
								<FiberManualRecordIcon className="option-icon" />
								<span className="option-label">{option.label}</span>
							</NavLink>
						)
					)}
				</div>
			)}
		</div>
	);
}
