const sideBar = {
	isOpen: false,
	keepOpen: localStorage.getItem("keepOpenSideBar") ? JSON.parse(localStorage.getItem("keepOpenSideBar")) : false,
};

const sideBarReducer = (state = sideBar, action) => {
	switch (action.type) {
		case "OPEN":
			if (!state.isOpen) {
				state.isOpen = true;
				return { ...state };
			} else return state;
		case "CLOSE":
			state.keepOpen = false;
			state.isOpen = false;
			localStorage.setItem("keepOpenSideBar", JSON.stringify(false));
			return { ...state };

		case "TOGGLE_KEEP_OPEN":
			localStorage.setItem("keepOpenSideBar", JSON.stringify(!state.keepOpen));
			return { ...state, keepOpen: !state.keepOpen };
		default:
			return state;
	}
};
export default sideBarReducer;
