/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { getAccounts } from "../../redux/actions/calls";
import { useDispatch } from "react-redux";
import MyTable from "../../components/MyTable";
import GroupIcon from "@mui/icons-material/Group";
import { getImage } from "../../assets/helpers/imagesHelper";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";

export default function AccountsTable() {
	const dispatch = useDispatch();
	const [data, setData] = useState({});

	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [searchInput, setSearchInput] = useState("");

	const columns = [
		{
			label: "",
			render: (data) => {
				if (data.authMethod === "apple") {
					return <AppleIcon />;
				} else if (data.authMethod === "google") {
					return <GoogleIcon />;
				} else {
					<></>;
				}
			},
			width: 20,
			align: "center",
		},
		{
			name: "username",
			render: (data) => (
				<div className="user-data">
					<img className="user-profile-picture" src={getImage(data.profilePicture, "profile_pictures")} alt="" />
					<NavLink to={"/panel/accounts/" + data.id} className="username-value">
						{data.username}
					</NavLink>
				</div>
			),
		},
		{
			label: "Full Name",
			name: "fullName",
		},
		{
			label: "Phone Number",
			name: "phoneNumber",
		},
		{
			label: "Last Login",
			name: "lastLoginDate",
			type: "date",
		},
		{
			label: "Creation Date",
			name: "sysCreationDate",
			type: "date",
		},
		{
			label: "IP",
			name: "ipAddress",
		},
	];

	useEffect(() => {
		fetchAccounts();
	}, [pageNumber, limit, searchInput]);

	const fetchAccounts = () => {
		const request = {
			offset: (pageNumber - 1) * limit,
			limit: limit,
			searchInput: searchInput,
		};
		dispatch(
			getAccounts(request, (response) => {
				setData({ ...response });
			})
		);
	};

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	const onTableSearch = (input) => {
		if (input === searchInput) {
			setData((prevData) => ({ ...prevData }));
			return;
		}
		setSearchInput(input);
		setPageNumber(1);
	};

	return (
		<div className="page-container accounts-table">
			<MyTable
				title={{
					icon: <GroupIcon />,
					label: "Accounts Table",
				}}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onSearch={onTableSearch}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
			/>
		</div>
	);
}
