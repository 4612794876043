/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import { getImage } from "../../assets/helpers/imagesHelper";
import { getProductNameWithVariations, translateString } from "../../assets/helpers/namesHelper";
import ProductPrice from "../ProductPrice";
import { useSelector } from "react-redux";

export default function PackageCard(props) {
	const { packageData, orderItem, products } = props;

	const variations = useSelector((state) => state.products.variations);

	return (
		<div className="product-card">
			<img className="product-image" src={getImage(packageData.images[0], "package_images")} alt="" />
			<div className="product-info">
				<p className="product-name">{translateString(packageData.name, "en")}</p>
				<div className="package-products-container">
					{orderItem?.entryIds?.map((entryId, index) => {
						const productEntry = products?.find((product) => product.entryId === entryId);
						return (
							<div className="order-package-product-wrapper" key={index}>
								<img className="item-product-image" src={getImage(productEntry.images[0], "product_images")} alt="" />
								<label className="item-text">{getProductNameWithVariations(productEntry, variations, "en", true)}</label>
							</div>
						);
					})}
				</div>
				<div className="order-info">
					<ProductPrice product={packageData} quantity={orderItem.quantity} price={orderItem.price} oldPrice={orderItem.fullPrice} />
					<span>
						Qty: <b>{orderItem.quantity}</b>
					</span>
				</div>
			</div>
		</div>
	);
}
