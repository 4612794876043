/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { getStaffMembers } from "../../redux/actions/calls";
import { useDispatch } from "react-redux";
import MyTable from "../../components/MyTable";
import GroupIcon from "@mui/icons-material/Group";
import { getImage } from "../../assets/helpers/imagesHelper";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function StaffMembersTable() {
	const dispatch = useDispatch();
	const [data, setData] = useState({});

	const columns = [
		{
			name: "username",
			render: (data) => (
				<div className="user-data">
					<img className="user-profile-picture" src={getImage(data.profilePicture, "profile_pictures")} alt="" />
					<NavLink to={"/panel/staff-members/" + data.id} className="username-value">
						{data.username}
					</NavLink>
				</div>
			),
		},
		{
			name: "fullName",
		},
		{
			name: "phoneNumber",
		},
		{
			name: "ipAddress",
		},
		{
			name: "sysCreationDate",
			type: "date",
		},
	];

	useEffect(() => {
		fetchStaffMembers();
	}, []);

	const fetchStaffMembers = () => {
		dispatch(
			getStaffMembers({}, (response) => {
				const data = {
					totalRows: response.length,
					data: response,
				};
				setData(data);
			})
		);
	};

	return (
		<div className="page-container staff-members-table">
			<MyTable
				title={{
					icon: <GroupIcon />,
					label: "Staff Members Table",
				}}
				data={data}
				columns={columns}
				pageNumber={1}
				pageLimit={10}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
			/>
		</div>
	);
}
