/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { getImage } from "../../assets/helpers/imagesHelper";
import { editAd } from "../../redux/actions/calls";
import { getBrands, getCategories, getProducts } from "../../redux/actions/products";
import { getProductNameWithVariations, translateString } from "../../assets/helpers/namesHelper";
import MySelect from "../MySelect";
import TextInput from "../TextInput";
import Container from "../Container";
import { convertDateToInputFormat, convertLocalDateStringToUTC } from "../../assets/helpers/dateHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

const onClickActions = [
	{ label: "Go to screen", value: "GO_TO_SCREEN" },
	{ label: "Go to product", value: "GO_TO_PRODUCT" },
	{ label: "Search for", value: "SEARCH_FOR" },
	{ label: "Open URL", value: "OPEN_URL" },
];

const screensOptions = [
	{ label: "Cart screen", value: "/(tabs)/cart" },
	{ label: "Orders screen", value: "/(tabs)/orders" },
	{ label: "Profile screen", value: "/(tabs)/profile" },
];

export default function EditAd(props) {
	const { ad, onFinish } = props;
	const dispatch = useDispatch();
	const [expirationDate, setExpirationDate] = useState(convertDateToInputFormat(ad.expirationDate));
	const [loadingButton, setLoadingButton] = useState(false);
	const [onClickAction, setOnClickAction] = useState(ad.action || null);
	const [products, setProducts] = useState([]);
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [data, setData] = useState(ad.data || {});
	const [searchCustoms, setSearchCustoms] = useState(ad.data?.customs || []);

	const variations = useSelector((state) => state.products.variations);

	useEffect(() => {
		fetchProducts();
		fetchBrands();
		fetchCategories();
	}, []);

	const fetchProducts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
			hideOutOfStock: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setProducts(response.data);
				},
				(error) => {
					setProducts([]);
				}
			)
		);
	};

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label="Update" onClick={editButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, expirationDate, onClickAction, data, searchCustoms]);

	const handleDateChange = (event) => {
		const newDate = event.target.value;
		setExpirationDate(newDate);
	};

	const onActionChange = (value) => {
		setOnClickAction(value);
		setData({});
		setSearchCustoms([]);
	};

	const onDataChange = (key, value) => {
		setData({ ...data, [key]: value });
	};

	const onProductSelect = (entryId) => {
		const product = products.find((product) => product.entryId === Number(entryId));
		if (product) {
			setData({ ...data, productId: product.id, entryId: product.entryId });
		}
	};

	const onSearchCustomsChange = (key, id, values) => {
		const newSearchCustoms = searchCustoms.filter((searchCustom) => searchCustom[id] !== key);
		setSearchCustoms([
			...newSearchCustoms,
			...values.map((value) => {
				return { [id]: key, id: Number(value.value) };
			}),
		]);
	};

	const editButtonHandle = () => {
		if (!expirationDate) {
			enqueueSnackbar(`Please set expiration date.`, { variant: "error" });
			return;
		}
		if (onClickAction && onClickAction !== "SEARCH_FOR" && Object.keys(data).length === 0) {
			enqueueSnackbar(`Please complete the on click action.`, { variant: "error" });
			return;
		}
		setLoadingButton(true);
		const requestData = { ...data };
		if (searchCustoms.length > 0) {
			requestData["customs"] = searchCustoms;
		}
		const request = {
			id: ad.id,
			expirationDate: convertLocalDateStringToUTC(expirationDate),
			action: onClickAction,
			data: onClickAction ? JSON.stringify(requestData) : null,
		};
		dispatch(
			editAd(
				request,
				(response) => {
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
				},
				(error) => {
					setLoadingButton(false);
				}
			)
		);
	};

	return (
		<div className="edit-ad">
			<img className="edit-ad-image" src={getImage(ad.image, "advertisings")} alt="" />
			<Container title="On Click Action - OPTIONAL" vertical>
				<MySelect label="Action" options={onClickActions} withoutSearch value={onClickAction} onChange={onActionChange} />
				{onClickAction === "GO_TO_SCREEN" ? (
					<MySelect key="path" label="Path" options={screensOptions} withoutSearch value={data.path} onChange={(value) => onDataChange("path", value)} required />
				) : onClickAction === "OPEN_URL" ? (
					<div style={{ textAlign: "start" }}>
						<TextInput key="url" label="URL" onChange={(value) => onDataChange("url", value)} value={data.url} />
						<FormControlLabel control={<Switch checked={data.openInApp === true} color="primary" size="medium" onChange={() => onDataChange("openInApp", data.openInApp ? false : true)} />} label="Open in App" />
					</div>
				) : onClickAction === "GO_TO_PRODUCT" ? (
					<MySelect
						key="product"
						label="Product"
						options={products?.map((product) => {
							return { label: getProductNameWithVariations(product, variations, "en", true), value: product.entryId };
						})}
						value={data.entryId}
						onChange={onProductSelect}
						onSearch={(input) => fetchProducts(input)}
						required
					/>
				) : onClickAction === "SEARCH_FOR" ? (
					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<TextInput key="searchInput" label="Search input" value={data.keyword} onChange={(value) => onDataChange("keyword", value)} />
						<Container title="Custom Search">
							<MySelect
								options={brands.map((brand) => {
									return { label: brand.name, value: brand.id };
								})}
								label="Brand"
								onChange={(values) => onSearchCustomsChange("brand", "type", values)}
								onRefresh={fetchBrands}
								value={searchCustoms.filter((custom) => custom.type === "brand").map((custom) => custom.id)}
								multiSelect
							/>
							<MySelect
								options={categories.map((category) => {
									return { label: translateString(category.name, "en"), value: category.id };
								})}
								label="Category"
								onChange={(values) => onSearchCustomsChange("category", "type", values)}
								onRefresh={fetchCategories}
								value={searchCustoms.filter((custom) => custom.type === "category").map((custom) => custom.id)}
								multiSelect
							/>
						</Container>
					</div>
				) : (
					<></>
				)}
			</Container>
			<Container title="Expiration Date">
				<input type="datetime-local" className="date-range-picker" value={expirationDate} onChange={handleDateChange} required />
			</Container>
		</div>
	);
}
