/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import Logo from "../../assets/images/logo.jpg";
import { t } from "i18next";

export default function ThankYouPage() {
	return (
		<div className="page-container thankyou-page">
			<div className="thankyou-page-body">
				<img className="app-logo" src={Logo} alt="" />
				<p className="thankyou-message">{t("thankyouMessage1")}</p>
				<p className="thankyou-message">{t("thankyouMessage2")}</p>
			</div>
		</div>
	);
}
