/* eslint-disable react-hooks/exhaustive-deps */
import "./App.scss";
import { Switch, Route, Redirect } from "react-router-dom";
import HomePage from "./pages/Home";
import { useDispatch, useSelector } from "react-redux";
import LoginPage from "./pages/Login";
import { SnackbarProvider } from "notistack";
import Header from "./base-components/Header";
import Colors from "./constants/Colors";
import { useEffect, useState } from "react";
import SideBar from "./base-components/SideBar";
import AddNewProductPage from "./pages/AddNewProduct";
import Modal from "./base-components/Modal";
import ProductsTablePage from "./pages/ProductsTable";
import { initUser } from "./redux/actions/user";
import { isAllowedToPerformActivity } from "./assets/helpers/AuthHelper";
import EditProductPage from "./pages/EditProduct";
import CategoriesTablePage from "./pages/CategoriesTable";
import BrandsTablePage from "./pages/BrandsTable";
import { initSpecialties, initVariations } from "./redux/actions/products";
import VariationsTablePage from "./pages/VariationsTable";
import OrdersTablePage from "./pages/OrdersTable";
import AddAdsPage from "./pages/AddAds";
import ShopByCategory from "./pages/ShopByCategory";
import PrivacyPage from "./pages/privacy";
import Advertisings from "./pages/Advertisings";
import CouponsTablePage from "./pages/CouponsTable";
import PushNotification from "./pages/PushNotification";
import SpecialtiesTablePage from "./pages/SpecialtiesTable";
import LandingPage from "./pages/LandingPage";
import HomeHeader from "./base-components/HomeHeader";
import HomeFooter from "./base-components/HomeFooter";
import UserViewerPage from "./pages/UserViewer";
import StaffMembersTable from "./pages/StaffMembersTable";
import AccountsTable from "./pages/AccountsTable";
import AppConfigPage from "./pages/AppConfig";
import UserTicketsPage from "./pages/UserTickets";
import WebContactUsPage from "./pages/WebContactUs";
import { changeLanguage, initAppConfig } from "./redux/actions/system";
import BasePricesTablePage from "./pages/BasePricesTable";
import Logo from "./assets/images/logo.jpg";
import { CircularProgress } from "@mui/material";
import AddNewPackagePage from "./pages/AddNewPackage";
import PackagesTablePage from "./pages/PackagesTable";
import EditPackagePage from "./pages/EditPackage";
import i18n from "./i18n";
import Config from "./config.json";
import PrivacyPolicyPage from "./pages/PrivacyPolicy";
import ThankYouPage from "./pages/ThankyouPage";
import { deepLinkToApp } from "./assets/helpers/deepLinkHandler";

const handleDeepLink = () => {
	const pathname = window.location.pathname;
	if (pathname.startsWith("/ppa")) {
		deepLinkToApp(pathname);
	}
};
handleDeepLink();

function App() {
	const [isLoading, setIsLoading] = useState(true);

	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const system = useSelector((state) => state.system);

	const isPanel = window.location.pathname.includes("panel");

	useEffect(() => {
		const params = new URLSearchParams(window.location.search);
		const langParam = params.get("lang");
		if (langParam) {
			const language = Config.languages.find((language) => language.code === langParam)?.code || Config.fallbackLng;
			i18n.changeLanguage(language);
			localStorage.setItem("language", language);
			dispatch(changeLanguage(language));
		}
	}, []);

	useEffect(() => {
		if (!isPanel) {
			checkRTL();
		} else {
			i18n.changeLanguage("en");
			localStorage.setItem("language", "en");
			dispatch(changeLanguage("en"));
		}
	}, [system.lang]);

	const checkRTL = () => {
		const language = Config.languages.find((language) => language.code === i18n.language) || Config.languages.find((language) => language.code === Config.fallbackLng);
		const htmlTag = document.documentElement;
		htmlTag.setAttribute("dir", language.rtl ? "rtl" : "ltr");
	};

	useEffect(() => {
		(async () => {
			await dispatch(initAppConfig());
			await dispatch(initUser());
			if (user.id) {
				await dispatch(initVariations());
				await dispatch(initSpecialties());
			}
			setIsLoading(false);
		})();
		Colors.forEach(setCssVariable);
	}, [dispatch, user.id]);

	const setCssVariable = ([variable, value]) => {
		document.body.style.setProperty(variable, value);
	};

	return isPanel && isLoading ? (
		<div className="panel-loading">
			<img className="panel-loading-image" src={Logo} alt="" />
			<CircularProgress style={{ color: "var(--tint)" }} />
		</div>
	) : (
		<div className="App">
			{isPanel && user.id && (
				<div className="left">
					<SideBar />
				</div>
			)}
			<div className={isPanel && user.id ? "right" : "right no-margin"}>
				{!window.location.pathname.includes("thankyou") && (isPanel ? user.id && <Header /> : <HomeHeader />)}
				<Switch>
					<Route exact path="/" component={LandingPage} />
					<Route exact path="/thankyou" component={ThankYouPage} />
					<Route exact path="/view/:viewId" component={LandingPage} />
					<Route exact path="/privacy" component={PrivacyPage} />
					<Route exact path="/panel" component={user.id ? HomePage : LoginPage} />
					{isAllowedToPerformActivity(user, "/panel/add-new-product") && <Route exact path="/panel/add-new-product" component={AddNewProductPage} />}
					{isAllowedToPerformActivity(user, "/panel/add-new-package") && <Route exact path="/panel/add-new-package" component={AddNewPackagePage} />}
					{user.id && <Route exact path="/panel/products-table" component={ProductsTablePage} />}
					{user.id && <Route exact path="/panel/packages-table" component={PackagesTablePage} />}
					{isAllowedToPerformActivity(user, "/panel/edit-package") && <Route exact path="/panel/packages-table/:id" component={EditPackagePage} />}
					{(isAllowedToPerformActivity(user, "/panel/edit-product") || isAllowedToPerformActivity(user, "/panel/edit-product-description") || isAllowedToPerformActivity(user, "/panel/edit-product-entries")) && (
						<Route exact path="/panel/products-table/:id" component={EditProductPage} />
					)}
					{user.id && <Route exact path="/panel/categories-table" component={CategoriesTablePage} />}
					{user.id && <Route exact path="/panel/brands-table" component={BrandsTablePage} />}
					{isAllowedToPerformActivity(user, "/panel/get-base-prices") && <Route exact path="/panel/base-prices-table" component={BasePricesTablePage} />}
					{user.id && <Route exact path="/panel/specialties-table" component={SpecialtiesTablePage} />}
					{user.id && <Route exact path="/panel/variation-table/:option" component={VariationsTablePage} />}
					{isAllowedToPerformActivity(user, "/panel/get-orders") && <Route exact path="/panel/orders-table" component={OrdersTablePage} />}
					{isAllowedToPerformActivity(user, "/panel/add-new-ad") && <Route exact path="/panel/add-ads" component={AddAdsPage} />}
					{user.id && <Route exact path="/panel/shop-by-category" component={ShopByCategory} />}
					{user.id && <Route exact path="/panel/advertisings" component={Advertisings} />}
					{isAllowedToPerformActivity(user, "/panel/fetch-coupons") && <Route exact path="/panel/coupons-list" component={CouponsTablePage} />}
					{isAllowedToPerformActivity(user, "/panel/send-push-notifications") && <Route exact path="/panel/push-notification" component={PushNotification} />}
					{user.id && <Route exact path="/panel/staff-members" component={StaffMembersTable} />}
					{user.id && <Route exact path="/panel/staff-members/:id" component={UserViewerPage} />}
					{user.id && <Route exact path="/panel/accounts" component={AccountsTable} />}
					{user.id && <Route exact path="/panel/accounts/:id" component={UserViewerPage} />}
					{user.id && <Route exact path="/panel/app-config" component={AppConfigPage} />}
					{user.id && <Route exact path="/panel/user-tickets" component={UserTicketsPage} />}
					{user.id && <Route exact path="/panel/contact-us" component={WebContactUsPage} />}
					{isAllowedToPerformActivity(user, "/panel/update-privacy-policy") && <Route exact path="/panel/privacy-policy" component={PrivacyPolicyPage} />}
					<Redirect to="/" />
				</Switch>
				{!window.location.pathname.includes("thankyou") && !isPanel && <HomeFooter />}
				<Modal />
				<SnackbarProvider maxSnack={5} />
			</div>
		</div>
	);
}

export default App;
