export function translateString(object, languageCode) {
	return object ? (Object.keys(object).includes(languageCode) ? object[languageCode] : object?.en || object) : object;
}

export function getProductNameWithVariations(product, variations, languageCode, includeBrand = false, nameId = null) {
	if (!variations) return;
	const variationNames = variations.filter((variation) => product[variation.name]).map((variation) => translateString(product[variation.name], languageCode));
	const brandName = includeBrand ? [product.brand] : [];
	return [...brandName, translateString(product[nameId || "name"], languageCode), ...variationNames].join(", ");
}
