/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { getPackages } from "../../redux/actions/products";
import InventoryIcon from "@mui/icons-material/Inventory";
import { NavLink, useHistory } from "react-router-dom";
import { translateString } from "../../assets/helpers/namesHelper";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function PackagesTablePage() {
	const dispatch = useDispatch();

	const history = useHistory();

	const user = useSelector((state) => state.user);

	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [data, setData] = useState({});
	const [hideExpired, setHideExpired] = useState(false);

	const columns = [
		{
			name: "images",
			type: "image",
			folder: "package_images",
			align: "center",
			width: 120,
		},
		{
			name: "name",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-package") ? (
					<NavLink exact to={`/panel/packages-table/${data.id}`} className="table-package-name">
						{translateString(data.name, "en")}
					</NavLink>
				) : (
					translateString(data.name, "en")
				),
		},
		{
			name: "price",
			type: "price",
			align: "center",
		},
		{
			name: "discount",
			align: "center",
		},
		{
			name: "expirationDate",
			type: "date",
			width: 150,
		},
		{
			name: "sysCreationDate",
			type: "date",
			width: 150,
		},
	];

	useEffect(() => {
		if (pageNumber > 0 && limit > 0) {
			const request = {
				offset: (pageNumber - 1) * limit,
				limit: limit,
				hideExpired: hideExpired,
			};
			dispatch(
				getPackages(
					request,
					(response) => {
						response.data.forEach((data) => (data["hidden"] = data.isExpired));
						setData(response);
					},
					(error) => {}
				)
			);
		}
	}, [pageNumber, limit, hideExpired]);

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	return (
		<div className="page-container packages-table">
			<MyTable
				title={{ icon: <InventoryIcon />, label: "Packages Table" }}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onAddNew={{
					label: "Add New Package",
					onClick: () => history.push("/panel/add-new-package"),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-package"),
				}}
				tableTopBar={<FormControlLabel control={<Switch checked={hideExpired} color="primary" size="medium" onChange={() => setHideExpired(!hideExpired)} />} label="Hide Expired" />}
				hideSearch
			/>
		</div>
	);
}
