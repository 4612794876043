import Config from "../../config.json";

const system = {
	app: {
		appStoreLink: "https://apps.apple.com/il/app/proteinplus/id6478510507",
		playStoreLink: "https://play.google.com/store/apps/details?id=com.majdhanna.proteinplus",
	},
	lang: localStorage.getItem("language") || Config.fallbackLng,
};

const systemReducer = (state = system, action) => {
	switch (action.type) {
		case "SET_APP_CONFIG":
			state.app = action.config;
			return { ...state };
		case "CHANGE_LANGUAGE":
			state.lang = action.lang;
			return { ...state };
		default:
			return state;
	}
};

export default systemReducer;
