/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import Container from "../../components/Container";
import TextInput from "../../components/TextInput";
import "./style.scss";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import MyButton from "../../components/MyButton";
import MySelect from "../../components/MySelect";
import { useDispatch, useSelector } from "react-redux";
import { getBrands, getCategories, getProducts } from "../../redux/actions/products";
import { getProductNameWithVariations, translateString } from "../../assets/helpers/namesHelper";
import { enqueueSnackbar } from "notistack";
import { getAccounts, sendPushNotifications } from "../../redux/actions/calls";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Config from "../../config.json";

const onClickActions = [
	{ label: "Go to screen", value: "GO_TO_SCREEN" },
	{ label: "Go to product", value: "GO_TO_PRODUCT" },
	{ label: "Search for", value: "SEARCH_FOR" },
	{ label: "Open URL", value: "OPEN_URL" },
];

const screensOptions = [
	{ label: "Cart screen", value: "/(tabs)/cart" },
	{ label: "Orders screen", value: "/(tabs)/orders" },
	{ label: "Profile screen", value: "/(tabs)/profile" },
];

export default function PushNotification() {
	const dispatch = useDispatch();

	const variations = useSelector((state) => state.products.variations);

	const [loadingBtn, setLoadingBtn] = useState(false);
	const [pushNotification, setPushNotification] = useState({});
	const [data, setData] = useState({});
	const [accounts, setAccounts] = useState([]);
	const [searchCustoms, setSearchCustoms] = useState([]);
	const [products, setProducts] = useState([]);
	const [usernames, setUsernames] = useState([]);

	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);

	useEffect(() => {
		fetchAccounts();
		fetchProducts();
		fetchBrands();
		fetchCategories();
	}, []);

	const fetchAccounts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
		};
		dispatch(
			getAccounts(request, (response) => {
				setAccounts(response.data);
			})
		);
	};

	const fetchProducts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
			hideOutOfStock: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setProducts(response.data);
				},
				(error) => {
					setProducts([]);
				}
			)
		);
	};

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onActionChange = (value) => {
		setData({ action: value });
	};

	const onOptionChange = (key, value, language = null) => {
		if (language) {
			if (!pushNotification[key]) {
				pushNotification[key] = {};
			}
			pushNotification[key][language.code] = value;
			setPushNotification({ ...pushNotification });
		} else {
			setPushNotification({ ...pushNotification, [key]: value });
		}
	};

	const onDataChange = (key, value) => {
		setData({ ...data, [key]: value });
	};

	const onSearchCustomsChange = (key, id, values) => {
		const newSearchCustoms = searchCustoms.filter((searchCustom) => searchCustom[id] !== key);
		setSearchCustoms([
			...newSearchCustoms,
			...values.map((value) => {
				return { [id]: key, id: Number(value.value) };
			}),
		]);
	};

	const onProductSelect = (entryId) => {
		const product = products.find((product) => product.entryId === Number(entryId));
		if (product) {
			setData({ ...data, productId: product.id, entryId: product.entryId });
		}
	};

	const onPushNotificationClick = () => {
		if (data.action === "SEARCH_FOR") {
			data["customs"] = searchCustoms;
		}
		let request = { ...pushNotification, data: data };
		if (!pushNotification.sendToAll) {
			request["usernames"] = usernames;
		}
		if (!validRequest(request)) return;
		request = {
			...request,
			title: JSON.stringify(request.title),
			body: JSON.stringify(request.body),
		};
		setLoadingBtn(true);
		dispatch(
			sendPushNotifications(
				request,
				(response) => {
					enqueueSnackbar(`Notification sent successfully.`, { variant: "success" });
					setLoadingBtn(false);
				},
				(onError) => {
					setLoadingBtn(false);
				}
			)
		);
	};

	const validRequest = (request) => {
		for (const language of Config.languages) {
			if (!request.title || !request.title[language.code] || request.title[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert title in ${language.label}`, { variant: "error" });
				return false;
			}
			if (!request.body || !request.body[language.code] || request.body[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert body in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		if (!pushNotification.sendToAll && usernames.length === 0) {
			enqueueSnackbar(`Please add the usernames you want to send notification to`, { variant: "error" });
			return false;
		}
		if (data.action && Object.keys(data).filter((key) => key !== "action").length === 0) {
			enqueueSnackbar(`Please complete the on click action.`, { variant: "error" });
			return false;
		}
		return true;
	};

	const onRemoveUsername = (username) => {
		setUsernames(usernames.filter((user) => user !== username));
	};

	const onUsernameSelect = (accounts) => {
		setUsernames(accounts.map((account) => account.value));
	};

	return (
		<div className="page-container push-notification">
			<div className="page-container-name">
				<NotificationsActiveIcon />
				<p>Send Push Notification</p>
			</div>
			<Container title="Title">
				{Config.languages.map((language, i) => (
					<TextInput key={i} label={language.label} onChange={(value) => onOptionChange("title", value, language)} dir={language.rtl ? "rtl" : "ltr"} required />
				))}
			</Container>
			<Container title="Body">
				{Config.languages.map((language, i) => (
					<TextInput key={i} label={language.label} onChange={(value) => onOptionChange("body", value, language)} type="textarea" dir={language.rtl ? "rtl" : "ltr"} required />
				))}
			</Container>
			<Container title="On Click Action - OPTIONAL" vertical>
				<MySelect label="Action" options={onClickActions} withoutSearch onChange={onActionChange} />
				{data.action === "GO_TO_SCREEN" ? (
					<MySelect key="path" label="Path" options={screensOptions} withoutSearch onChange={(value) => onDataChange("path", value)} required />
				) : data.action === "OPEN_URL" ? (
					<>
						<TextInput key="url" label="URL" onChange={(value) => onDataChange("url", value)} />
						<FormControlLabel
							control={<Switch checked={data.openInApp === true} color="primary" size="medium" onChange={() => onDataChange("openInApp", data.openInApp ? false : true)} />}
							label="Open in App"
						/>
					</>
				) : data.action === "GO_TO_PRODUCT" ? (
					<MySelect
						key="product"
						label="Product"
						options={products?.map((product) => {
							return { label: getProductNameWithVariations(product, variations, "en", true), value: product.entryId };
						})}
						onChange={onProductSelect}
						onSearch={(input) => fetchProducts(input)}
						required
					/>
				) : data.action === "SEARCH_FOR" ? (
					<div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
						<TextInput key="searchInput" label="Search input" onChange={(value) => onDataChange("keyword", value)} />
						<Container title="Custom Search">
							<MySelect
								options={brands.map((brand) => {
									return { label: brand.name, value: brand.id };
								})}
								label="Brand"
								onChange={(values) => onSearchCustomsChange("brand", "type", values)}
								onRefresh={fetchBrands}
								multiSelect
							/>
							<MySelect
								options={categories.map((category) => {
									return { label: translateString(category.name, "en"), value: category.id };
								})}
								label="Category"
								onChange={(values) => onSearchCustomsChange("category", "type", values)}
								onRefresh={fetchCategories}
								multiSelect
							/>
						</Container>
					</div>
				) : (
					<></>
				)}
			</Container>
			<Container title="Send To" vertical>
				<FormControlLabel
					control={<Switch checked={pushNotification.sendToAll === true} color="primary" size="medium" onChange={() => onOptionChange("sendToAll", pushNotification.sendToAll ? false : true)} />}
					label="Send to all"
				/>
				{!pushNotification.sendToAll && (
					<div>
						<div className="selected-usernames-container">
							<p className="selected-usernames-label">Choosed usernames:</p>
							<div className="selected-usernames">
								{usernames.length === 0 && <label style={{ fontSize: 13, color: "var(--red)" }}>Empty List</label>}
								{usernames.map((username, i) => (
									<button key={i} className="selected-username-btn" onClick={() => onRemoveUsername(username)}>
										{username}
										<HighlightOffIcon className="delete-username-icon" />
									</button>
								))}
							</div>
						</div>
						<div className="add-userame-form">
							<MySelect
								label="Username"
								options={accounts?.map((account) => {
									return { label: account.username, value: account.username };
								})}
								onChange={onUsernameSelect}
								onSearch={(input) => fetchAccounts(input)}
								value={usernames}
								multiSelect
							/>
						</div>
					</div>
				)}
			</Container>
			<div className="action-buttons-wrapper">
				<MyButton type="submit" label="Push Notification" isLoading={loadingBtn} onClick={onPushNotificationClick} />
			</div>
		</div>
	);
}
