/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { getProducts } from "../../redux/actions/products";
import InventoryIcon from "@mui/icons-material/Inventory";
import { NavLink, useHistory } from "react-router-dom";
import { translateString } from "../../assets/helpers/namesHelper";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { toggleProductEntriesHiddenInd } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";

export default function ProductsTablePage() {
	const dispatch = useDispatch();

	const history = useHistory();

	const user = useSelector((state) => state.user);

	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [data, setData] = useState({});
	const [searchInput, setSearchInput] = useState("");
	const [showHidden, setShowHidden] = useState(false);

	const columns = [
		{
			name: "images",
			type: "image",
			folder: "product_images",
			align: "center",
		},
		{
			name: "name",
			width: 150,
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-product") || isAllowedToPerformActivity(user, "/panel/edit-product-description") || isAllowedToPerformActivity(user, "/panel/edit-product-entries") ? (
					<NavLink exact to={`/panel/products-table/${data.id}`} className="table-product-name">
						{translateString(data.name, "en")}
					</NavLink>
				) : (
					translateString(data.name, "en")
				),
		},
		{
			name: "brand",
		},
		{
			name: "category",
		},
		{
			name: "price",
			type: "price",
			align: "center",
		},
		{
			name: "quantity",
			type: "number",
			align: "center",
		},
		{
			name: "barcode",
		},
		{
			name: "discount",
			align: "center",
		},
		{
			name: "flavourOption",
		},
		{
			name: "weightOption",
		},
		{
			name: "colorOption",
		},
		{
			name: "sizeOption",
		},
		{
			name: "soldCount",
			type: "number",
			align: "center",
		},
		{
			name: "sysCreationDate",
			type: "date",
			width: 140,
		},
	];

	useEffect(() => {
		if (pageNumber > 0 && limit > 0) {
			const request = {
				offset: (pageNumber - 1) * limit,
				limit: limit,
				searchInput: searchInput,
				showHidden: showHidden,
			};
			dispatch(
				getProducts(
					request,
					(response) => {
						setData(response);
					},
					(error) => {}
				)
			);
		}
	}, [pageNumber, limit, searchInput, showHidden]);

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	const onTableSearch = (input) => {
		if (input === searchInput) {
			setData((prevData) => ({ ...prevData }));
			return;
		}
		setSearchInput(input);
		setPageNumber(1);
	};

	const toggleProductsHiddenIndHandle = (selectedData) => {
		const request = {
			ids: selectedData.map((data) => data.entryId),
		};
		dispatch(
			toggleProductEntriesHiddenInd(
				request,
				(response) => {
					selectedData.forEach((rowData) => {
						rowData.hiddenEntry = !rowData.hiddenEntry;
					});
					setData((prevData) => ({ ...prevData }));
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
				}
			)
		);
	};

	return (
		<div className="page-container products-table">
			<MyTable
				primaryKey="entryId"
				title={{ icon: <InventoryIcon />, label: "Products Table" }}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onSearch={onTableSearch}
				onAddNew={{
					label: "Add New Product",
					onClick: () => history.push("/panel/add-new-product"),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-product"),
				}}
				actionButtons={[
					{
						label: "Hide/Unhide",
						onClick: toggleProductsHiddenIndHandle,
						permission: isAllowedToPerformActivity(user, "/panel/toggle-product-entries-hidden-ind"),
					},
				]}
				tableTopBar={<FormControlLabel control={<Switch checked={showHidden} color="primary" size="medium" onChange={() => setShowHidden(!showHidden)} />} label="Show Hidden" />}
			/>
		</div>
	);
}
