/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { getOrders, updateOrderStatus } from "../../redux/actions/products";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import MyButton from "../../components/MyButton";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { openModal } from "../../redux/actions/modal";
import OrderViewer from "../../components/OrderViewer";
import { enqueueSnackbar } from "notistack";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import MySelect from "../../components/MySelect";
import CardViewer from "../../components/CardViewer";

const status_options = ["NEW", "Preparing", "Shipped", "Delivered", "Canceled", "Refunded", "On Hold"];

export default function OrdersTablePage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [data, setData] = useState({});
	const [searchByStatus, setSearchByStatus] = useState(-1);
	const [searchInput, setSearchInput] = useState("");

	const onStatusChange = (row, column, value) => {
		const request = {
			id: row.id,
			status: value,
			username: row.username,
		};
		dispatch(
			updateOrderStatus(
				request,
				(response) => {
					enqueueSnackbar(`Status successfully updated.`, { variant: "success" });
				},
				(error) => {}
			)
		);
	};

	const columns = [
		{
			label: "Order#",
			name: "id",
			width: 50,
		},
		{
			label: "Client name",
			render: (data) => (
				<NavLink className="table-row-name" to={"/panel/accounts/" + data.accountId}>
					{data.fullName || data.username}
				</NavLink>
			),
			width: 100,
		},
		{
			label: "Phone Number",
			name: "phoneNumber",
			width: 100,
		},
		{
			label: "Location",
			render: (data) => data.locationName || data.addressType,
			width: 60,
			align: "center",
		},
		{
			name: "address",
		},
		{
			name: "notes",
		},
		{
			label: "Method",
			name: "paymentMethod",
			align: "center",
			width: 100,
			render: (data) =>
				["new_card", "card"].includes(data.paymentMethod) ? (
					<button className="credit-card-btn" onClick={() => viewCard(data.cardId)}>
						Credit Card
					</button>
				) : (
					<label>{data.paymentMethod}</label>
				),
		},
		{
			name: "coupon",
		},
		{
			label: "Full Price",
			name: "fullPriceTotal",
			type: "price",
		},
		{
			name: "discounts",
			type: "price",
		},
		{
			label: "Coupon Discounts",
			name: "couponDiscounts",
			type: "price",
		},
		{
			name: "subtotal",
			type: "price",
		},
		{
			label: "Delivery Fee",
			name: "deliveryFee",
			type: "price",
			align: "center",
		},
		{
			name: "total",
			type: "price",
		},
		{
			name: "status",
			type: isAllowedToPerformActivity(user, "/panel/update-order-status") && "select",
			options: status_options.map((option, i) => {
				return { label: option, value: i };
			}),
			render: !isAllowedToPerformActivity(user, "/panel/update-order-status") ? (data) => status_options[data.status] : undefined,
			onChange: onStatusChange,
			width: 140,
		},
		{
			label: "Date",
			name: "sysCreationDate",
			type: "date",
			width: 140,
		},
		{
			label: "Order",
			render: (data) => <MyButton label={`View Order (${data.items.length})`} buttonStyle="secondary" onClick={() => openOrder(data)} />,
			align: "center",
			width: 150,
		},
		{
			label: "Waze",
			render: (data) => <MyButton label="Open in waze" icon={<LocationOnIcon />} onClick={() => handleWazeButtonClick(data.latitude, data.longitude)} />,
			align: "center",
			width: 150,
		},
	];

	const viewCard = (cardId) => {
		dispatch(openModal(<CardViewer cardId={cardId} />, `Credit Card Details`));
	};

	const openOrder = (order) => {
		dispatch(openModal(<OrderViewer order={order} />, `Order number: ${order.id}`));
	};

	useEffect(() => {
		if (pageNumber > 0 && limit > 0) {
			const request = {
				offset: (pageNumber - 1) * limit,
				limit: limit,
				searchInput: searchInput,
				statusId: searchByStatus >= 0 ? searchByStatus : null,
			};
			dispatch(
				getOrders(
					request,
					(response) => {
						setData(response);
					},
					(error) => {}
				)
			);
		}
	}, [pageNumber, limit, searchInput, searchByStatus]);

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	const onTableSearch = (input) => {
		if (input === searchInput) {
			setData((prevData) => ({ ...prevData }));
			return;
		}
		setSearchInput(input);
		setPageNumber(1);
	};

	const handleWazeButtonClick = (latitude, longitude) => {
		const wazeLink = `https://www.waze.com/ul?ll=${latitude},${longitude}&navigate=yes`;
		window.open(wazeLink, "_blank");
	};

	const onSearchByChange = (value) => {
		setSearchByStatus(value);
		setPageNumber(1);
	};

	return (
		<div className="page-container orders-table">
			<MyTable
				title={{ icon: <ShoppingBagIcon />, label: "Orders Table" }}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onSearch={onTableSearch}
				hideCheckbox
				tableTopBar={
					<div className="toolbar-container">
						<MySelect
							options={[{ label: "All", value: -1 }].concat(
								status_options.map((option, i) => {
									return { label: option, value: i };
								})
							)}
							value={searchByStatus}
							label="Search by status"
							onChange={onSearchByChange}
							required
							withoutSearch
						/>
					</div>
				}
			/>
		</div>
	);
}
