/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import TextInput from "../../components/TextInput";
import Container from "../../components/Container";
import Config from "../../config.json";
import MySelect from "../../components/MySelect";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { translateString } from "../../assets/helpers/namesHelper";
import { DropzoneArea } from "material-ui-dropzone";
import MyButton from "../../components/MyButton";
import { getBrands, getCategories, getDescription, getProducts, getVariationValues, getVariations, updateProduct, updateProductDescription, updateProductEntries } from "../../redux/actions/products";
import { enqueueSnackbar } from "notistack";
import MyEditor from "../../components/MyEditor";
import { openModal } from "../../redux/actions/modal";
import AddNewBrand from "../../components/AddNewBrand";
import AddNewCategory from "../../components/AddNewCategory";
import AddNewVariationValue from "../../components/AddNewVariationValue";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getImage } from "../../assets/helpers/imagesHelper";
import EditIcon from "@mui/icons-material/Edit";
import { convertFileToBase64 } from "../../assets/helpers/fileHelper";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { toggleProductEntriesHiddenInd, toggleProductsHiddenInd } from "../../redux/actions/calls";
import { validateDoubleString } from "../../assets/helpers/validations";

const newProductStructure = {
	name: {},
	brandId: null,
	categoryId: null,
	description: {},
	entries: [],
	optionWithImage: null,
	optionWithPrice: null,
};

export default function EditProductPage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const specialties = useSelector((state) => state.products.specialties);

	const params = useParams();
	const productId = Number(params.id);

	const history = useHistory();

	const [loading, setLoading] = useState(true);
	const [, setProductEntries] = useState(null);
	const [newProduct, setNewProduct] = useState(newProductStructure);
	const [variations, setVariations] = useState([]);
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [checkedVariations, setCheckedVariations] = useState([]);
	const [loadingUpateProductBtn, setLoadingUpateProductBtn] = useState(false);
	const [loadingUpateDescriptionBtn, setLoadingUpateDescriptionBtn] = useState(false);
	const [loadingUpateEntriesBtn, setLoadingUpateEntriesBtn] = useState(false);
	const [loadingFetchVariationValues, setLoadingFetchVariationValues] = useState(false);
	const [variationValues, setVariationValues] = useState({});
	const [entries, setEntries] = useState([
		{
			barcode: null,
			quantity: null,
			price: null,
			discount: null,
			images: [],
		},
	]);
	const [loadingHideEntry, setLoadingHideEntry] = useState(false);
	const [loadingHideProduct, setLoadingHideProduct] = useState(false);

	const init = useCallback(() => {
		dispatch(
			getVariations({}, (response) => {
				setVariations(response);
				if (productId) {
					fetchProductEntries(response);
				}
			})
		);
		fetchBrands();
		fetchCategories();
	}, [dispatch]);

	useEffect(() => {
		init();
	}, [init]);

	const fetchProductEntries = (variations) => {
		const request = {
			productIds: [productId],
			showHidden: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					if (response.totalRows === 0) {
						history.push("/panel/products-table");
					} else {
						const productEntries = response.data;
						setProductEntries(productEntries);
						mapProductStructure(productEntries, variations);
						fetchDescription();
					}
				},
				(error) => {}
			)
		);
	};

	const mapProductStructure = (productEntries, variations) => {
		newProduct.id = productEntries[0].id;
		newProduct.hidden = productEntries[0].hidden;
		newProduct.name = productEntries[0].name;
		newProduct.brandId = productEntries[0].brandId;
		newProduct.categoryId = productEntries[0].categoryId;
		const existCheckedVariations = [];
		Object.keys(productEntries[0]).forEach((key) => {
			if (key.endsWith("Option") && productEntries[0][key]) {
				const variation = variations.find((variation) => variation.name === key);
				fetchVariationValues(variation);
				existCheckedVariations.push(variation);
			}
		});
		newProduct.optionWithImage = productEntries[0].optionWithImage;
		newProduct.optionWithPrice = productEntries[0].optionWithPrice;
		newProduct.specialties = productEntries[0].specialties;
		const existProductEntries = [];
		productEntries.forEach((productEntry) => {
			const productImages = [];
			productEntry.images.forEach((image) => {
				productImages.push(getImage(image, "product_images"));
			});
			const productEntryStructure = {
				entryId: productEntry.entryId,
				barcode: productEntry.barcode,
				quantity: productEntry.quantity,
				price: productEntry.price,
				discount: productEntry.discount,
				images: productImages,
				hidden: productEntry.hiddenEntry,
			};
			existCheckedVariations.forEach((variation) => {
				productEntryStructure[variation.name] = productEntry[variation.name + "Id"];
			});
			productEntryStructure["flavourOptionId"] = productEntry.flavourOptionId;
			existProductEntries.push(productEntryStructure);
		});
		setNewProduct({ ...newProduct });
		setCheckedVariations([...existCheckedVariations]);
		setEntries([...existProductEntries]);
		setLoading(false);
	};

	const fetchDescription = () => {
		dispatch(
			getDescription(
				{ productId: productId },
				(response) => {
					newProduct.description = response.value;
					setNewProduct({ ...newProduct });
				},
				(error) => {}
			)
		);
	};

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onProductNameChange = (languageCode, value) => {
		newProduct.name[languageCode] = value;
	};

	const onBrandSelect = (value) => {
		newProduct.brandId = Number(value);
	};

	const onCategorySelect = (value) => {
		newProduct.categoryId = Number(value);
	};

	const onSpecialtyCheck = (isChecked, specialty) => {
		if (!newProduct.specialties) {
			newProduct.specialties = [];
		}
		if (isChecked) {
			newProduct.specialties.push(specialty.id);
		} else {
			const newSpecialties = newProduct.specialties?.filter((productSpecialty) => specialty.id !== productSpecialty);
			newProduct.specialties = newSpecialties;
		}
		setNewProduct({ ...newProduct });
	};

	const onOptionWithImageSelect = (value) => {
		if (newProduct.optionWithPrice === value) {
			newProduct.optionWithPrice = null;
		}
		newProduct.optionWithImage = value;
		setNewProduct({ ...newProduct });
	};

	const onOptionWithPriceSelect = (value) => {
		if (newProduct.optionWithImage === value) {
			newProduct.optionWithImage = null;
		}
		newProduct.optionWithPrice = value;
		setNewProduct({ ...newProduct });
	};

	const fetchVariationValues = (variation, forceFetch = false) => {
		setLoadingFetchVariationValues(true);
		if (!forceFetch) {
			if (Object.keys(variationValues).includes(variation.name)) {
				setLoadingFetchVariationValues(false);
				return;
			}
		}
		const request = {
			variationTable: variation.tableName,
		};
		dispatch(
			getVariationValues(
				request,
				(response) => {
					setVariationValues((prevVariationValues) => {
						return { ...prevVariationValues, [variation.name]: response };
					});
					setLoadingFetchVariationValues(false);
				},
				(error) => {
					setLoadingFetchVariationValues(false);
				}
			)
		);
	};

	const onEntryChange = (index, column, value) => {
		entries[index][column] = value;
	};

	const onEntryImagesChange = (entry, images) => {
		entry.images = images;
	};

	const onProductDescriptionChange = (languageCode, value) => {
		newProduct.description[languageCode] = value;
		setNewProduct({ ...newProduct });
	};

	const addNewBrandHandle = () => {
		dispatch(openModal(<AddNewBrand onFinish={fetchBrands} />, "Add New Brand"));
	};

	const addNewCategoryHandle = () => {
		dispatch(openModal(<AddNewCategory onFinish={fetchCategories} />, "Add New Category"));
	};

	const addNewVariationValueHandle = (variation) => {
		dispatch(openModal(<AddNewVariationValue onFinish={() => fetchVariationValues(variation, true)} variation={variation} />, `Add New ${translateString(variation.variation, "en")} Value`));
	};

	const onVariationCheck = (checkedVariation) => {
		const variation = checkedVariations.find((variation) => variation.name === checkedVariation.name);
		if (variation) {
			const index = checkedVariations.indexOf(variation);
			checkedVariations.splice(index, 1);
		} else {
			checkedVariations.push(checkedVariation);
		}
		entries.forEach((entry) => delete entry[checkedVariation.name]);
		fetchVariationValues(checkedVariation);
		setCheckedVariations([...checkedVariations]);
	};

	const onAddNewEntry = () => {
		entries.push({
			barcode: null,
			quantity: null,
			price: null,
			discount: null,
			images: [],
		});
		setEntries([...entries]);
	};

	const onRemoveEntry = (index) => {
		entries.splice(index, 1);
		setEntries([...entries]);
	};

	const validUpdateProduct = () => {
		for (const language of Config.languages) {
			if (!newProduct.name || !newProduct.name[language.code] || newProduct.name[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert product name in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		if (!newProduct.brandId) {
			enqueueSnackbar(`Please choose brand`, { variant: "error" });
			return false;
		}
		if (!newProduct.categoryId) {
			enqueueSnackbar(`Please choose category`, { variant: "error" });
			return false;
		}
		return true;
	};

	const updateProductHandle = (e) => {
		e.preventDefault();
		if (!validUpdateProduct()) {
			return;
		}
		setLoadingUpateProductBtn(true);
		const request = {
			productId: productId,
			name: JSON.stringify(newProduct.name),
			brandId: newProduct.brandId,
			categoryId: newProduct.categoryId,
			optionWithImage: newProduct.optionWithImage,
			optionWithPrice: newProduct.optionWithPrice,
			specialties: newProduct.specialties,
		};
		dispatch(
			updateProduct(
				request,
				(response) => {
					enqueueSnackbar(`Product updated successfully`, { variant: "success" });
					setLoadingUpateProductBtn(false);
				},
				(error) => {
					setLoadingUpateProductBtn(false);
				}
			)
		);
	};

	const validUpdateProductEntries = (request) => {
		const discountRegex = /^(\d{1,2}(\.\d+)?|100)%$|^\d+(\.\d+)?$/;
		if (request.entries.length === 0) {
			enqueueSnackbar(`Please add at least one entry`, { variant: "error" });
			return false;
		}
		for (let i = 0; i < request.entries.length; i++) {
			const entry = request.entries[i];
			for (let v = 0; v < checkedVariations.length; v++) {
				const variation = checkedVariations[v];
				if (!entry[variation.name]) {
					enqueueSnackbar(`Please choose [Entry number ${i + 1}] ${translateString(variation.variation, "en")}`, { variant: "error" });
					return false;
				}
			}
			if (!entry.barcode || entry.barcode.trim().length === 0) {
				enqueueSnackbar(`Please insert [Entry number ${i + 1}] product entry barcode`, { variant: "error" });
				return false;
			}
			if (entry.quantity === null || entry.quantity < 0) {
				enqueueSnackbar(`Please insert valid [Entry number ${i + 1}] product entry quantity`, { variant: "error" });
				return false;
			}
			if (entry.quantity > 500) {
				enqueueSnackbar(`The quantity of [Entry number ${i + 1}] product cannot exceed 500`, { variant: "error" });
				return false;
			}
			if (!entry.price || entry.price <= 0 || !validateDoubleString(entry.price)) {
				enqueueSnackbar(`Please insert valid [Entry number ${i + 1}] product entry price`, { variant: "error" });
				return false;
			}
			if (entry.images.length === 0) {
				enqueueSnackbar(`Please add at least on image for [Entry number ${i + 1}] product entry`, { variant: "error" });
				return false;
			}
			if (entry.discount?.length > 0 && !discountRegex.test(entry.discount)) {
				enqueueSnackbar(`Please insert a valid discount for [Entry number ${i + 1}] product`, { variant: "error" });
				return false;
			}
		}
		return true;
	};

	const updateProductEntriesHandle = async (e) => {
		e.preventDefault();
		const base64Entries = [];
		for (const entry of entries) {
			const base64Images = await Promise.all(
				entry.images.map(async (image) => {
					const base64 = await convertFileToBase64(image);
					return { path: base64 };
				})
			);
			base64Entries.push({
				...entry,
				images: base64Images,
			});
		}
		const request = {
			productId: productId,
			entries: base64Entries,
		};
		if (!validUpdateProductEntries(request)) {
			return;
		}
		setLoadingUpateEntriesBtn(true);
		dispatch(
			updateProductEntries(
				request,
				(response) => {
					enqueueSnackbar(`Product entries updated successfully`, { variant: "success" });
					setLoadingUpateEntriesBtn(false);
				},
				(error) => {
					setLoadingUpateEntriesBtn(false);
				}
			)
		);
	};

	const validUpdateProductDescription = () => {
		for (const language of Config.languages) {
			if (!newProduct.description || !newProduct.description[language.code] || newProduct.description[language.code].replace(/(<([^>]+)>)/gi, "").length === 0) {
				enqueueSnackbar(`Please insert product description in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		return true;
	};

	const updateProductDescriptionHandle = (e) => {
		e.preventDefault();
		if (!validUpdateProductDescription()) {
			return;
		}
		setLoadingUpateDescriptionBtn(true);
		const request = {
			productId: productId,
			description: JSON.stringify(newProduct.description),
		};
		dispatch(
			updateProductDescription(
				request,
				(response) => {
					enqueueSnackbar(`Product description updated successfully`, { variant: "success" });
					setLoadingUpateDescriptionBtn(false);
				},
				(error) => {
					setLoadingUpateDescriptionBtn(false);
				}
			)
		);
	};

	const onHideEntryHandle = (entry) => {
		setLoadingHideEntry(true);
		const request = {
			ids: [entry.entryId],
		};
		dispatch(
			toggleProductEntriesHiddenInd(
				request,
				(response) => {
					entry.hidden = !entry.hidden;
					setEntries([...entries]);
					setLoadingHideEntry(false);
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
					setLoadingHideEntry(false);
				}
			)
		);
	};

	const onHideProductHandle = () => {
		setLoadingHideProduct(true);
		const request = {
			ids: [newProduct.id],
		};
		dispatch(
			toggleProductsHiddenInd(
				request,
				(response) => {
					newProduct.hidden = !newProduct.hidden;
					setNewProduct({ ...newProduct });
					setLoadingHideProduct(false);
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
					setLoadingHideProduct(false);
				}
			)
		);
	};

	return (
		<div className="page-container edit-product">
			<div className="page-container-name">
				<EditIcon />
				<p>Edit Product</p>
			</div>
			{loading ? (
				<div className="loading-div">
					<CircularProgress style={{ color: "var(--tint)" }} />
				</div>
			) : (
				<>
					{isAllowedToPerformActivity(user, "/panel/edit-product") && (
						<form className={newProduct.hidden ? "edit-form hidden" : "edit-form"} onSubmit={updateProductHandle}>
							<Container title="Product Name">
								{Config.languages.map((language, i) => (
									<TextInput
										key={i}
										label={language.label}
										required
										value={translateString(newProduct.name, language.code)}
										onChange={(value) => onProductNameChange(language.code, value)}
										dir={language.rtl ? "rtl" : "ltr"}
									/>
								))}
							</Container>
							<Container title="Brand & Category">
								<MySelect
									options={brands.map((brand) => {
										return { label: brand.name, value: brand.id };
									})}
									label="Brand"
									addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-brand") && addNewBrandHandle}
									onChange={onBrandSelect}
									value={newProduct.brandId}
									required
								/>
								<MySelect
									options={categories.map((category) => {
										return { label: translateString(category.name, "en"), value: category.id };
									})}
									label="Category"
									addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-category") && addNewCategoryHandle}
									onChange={onCategorySelect}
									value={newProduct.categoryId}
									required
								/>
							</Container>
							<Container title="Product Specialties">
								{specialties?.map((specialty, i) => (
									<FormControlLabel
										key={i}
										control={
											<Switch checked={newProduct.specialties?.includes(specialty.id) || false} color="primary" size="medium" onChange={(e, isChecked) => onSpecialtyCheck(isChecked, specialty)} />
										}
										label={translateString(specialty.name, "en")}
									/>
								))}
							</Container>
							{checkedVariations.length > 0 && (
								<Container title="Options style">
									<MySelect
										options={checkedVariations.map((checkedVariation) => {
											return { label: translateString(checkedVariation.variation, "en"), value: checkedVariation.name };
										})}
										label="Option with image"
										withoutSearch
										onChange={onOptionWithImageSelect}
										value={newProduct.optionWithImage}
									/>
									<MySelect
										options={checkedVariations.map((checkedVariation) => {
											return { label: translateString(checkedVariation.variation, "en"), value: checkedVariation.name };
										})}
										label="Option with price"
										withoutSearch
										onChange={onOptionWithPriceSelect}
										value={newProduct.optionWithPrice}
									/>
								</Container>
							)}
							{newProduct.hidden && <p className="no-longer-available-message">Notice: This product is marked as hidden and won't be visible on the app.</p>}
							<div className="action-buttons-wrapper">
								{isAllowedToPerformActivity(user, "/panel/toggle-products-hidden-ind") && (
									<MyButton type="button" label={newProduct.hidden ? "Unhide Product" : "Hide Product"} buttonStyle="soft" onClick={onHideProductHandle} disabled={loadingHideProduct} />
								)}
								<MyButton type="submit" label="Update Product" isLoading={loadingUpateProductBtn} />
							</div>
						</form>
					)}
					{isAllowedToPerformActivity(user, "/panel/edit-product-entries") && (
						<form className="edit-form" onSubmit={updateProductEntriesHandle}>
							{variations.length > 0 && (
								<Container title="Variations">
									{variations?.map((variation, i) => (
										<FormControlLabel
											key={i}
											control={
												<Checkbox style={{ color: "var(--tint)" }} onChange={() => onVariationCheck(variation)} checked={checkedVariations.find((v) => v.name === variation.name) ? true : false} />
											}
											label={translateString(variation.variation, "en")}
											style={{ userSelect: "none" }}
										/>
									))}
								</Container>
							)}
							<Container title={`${entries.length} Product Entries`} childrenContainerStyle={{ backgroundColor: "var(--background)", borderRadius: "10px" }}>
								{entries.length === 0 ? (
									<p className="zero-entries-message">Please add at least one entry</p>
								) : (
									entries.map((entry, i) => (
										<div key={i} className={entry.hidden ? "add-new-entry-row hidden" : "add-new-entry-row"}>
											<div className="new-entry-header">
												<div style={{ display: "flex", gap: 10 }}>
													{!entry.entryId && <RemoveCircleIcon className="remove-entry" onClick={() => onRemoveEntry(i)} />}
													<label className="entry-number">{`Entry number ${i + 1}`}</label>
												</div>
												{entry.entryId && isAllowedToPerformActivity(user, "/panel/toggle-product-entries-hidden-ind") && (
													<button type="button" className="hide-entry-btn" onClick={() => onHideEntryHandle(entry)} disabled={loadingHideEntry}>
														{entry.hidden ? "Unhide Entry" : "Hide Entry"}
													</button>
												)}
											</div>
											<div className="new-entry-row-inputs">
												{checkedVariations.map(
													(variation) =>
														variationValues[variation.name] && (
															<MySelect
																key={variation.name}
																options={variationValues[variation.name].map((variationValue) => {
																	return { label: translateString(variationValue.value, "en"), name: variation.name, value: variationValue.id };
																})}
																label={translateString(variation.variation, "en")}
																addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-variation-value") ? () => addNewVariationValueHandle(variation) : null}
																onChange={(selected) => onEntryChange(i, variation.name, selected)}
																value={Number(entry[variation.name])}
																required
															/>
														)
												)}
												{loadingFetchVariationValues && <CircularProgress style={{ color: "var(--tint)" }} />}
												<TextInput label={"barcode"} onChange={(value) => onEntryChange(i, "barcode", value)} value={entry.barcode} required />
												<TextInput
													label={"Quantity"}
													onChange={(value) => onEntryChange(i, "quantity", value)}
													value={entry.quantity !== null ? entry.quantity + "" : ""}
													type="number"
													inputMode="numeric"
													required
												/>
												<TextInput label={"Price"} onChange={(value) => onEntryChange(i, "price", value)} value={entry.price} type="number" inputMode="numeric" required />
												<TextInput
													label={"Discount"}
													onChange={(value) => onEntryChange(i, "discount", value)}
													value={entry.discount !== null ? entry.discount + "" : ""}
													placeholder="ex: 10%, 13.5%, 50, 99.5"
												/>
											</div>
											<div className="new-product-entry-images">
												<DropzoneArea
													key={new Date().getTime() + i}
													clearOnUnmount
													acceptedFiles={["image/*"]}
													dropzoneText={"Drag and drop an image here or click"}
													onChange={(files) => onEntryImagesChange(entry, files)}
													initialFiles={entry.images}
													showPreviews
													showPreviewsInDropzone={false}
													filesLimit={5}
													showAlerts={false}
													onAlert={(message, alertType) => alertType === "error" && enqueueSnackbar(`${message}`, { variant: alertType })}
												/>
											</div>
										</div>
									))
								)}
							</Container>
							<div className="add-new-entry">
								<button type="button" className="add-new-entry-btn" onClick={onAddNewEntry}>
									<AddCircleIcon className="add-new-entry-icon" />
									<label className="add-new-entry-label">Add New Entry</label>
								</button>
							</div>
							<div className="action-buttons-wrapper">
								<MyButton type="submit" label="Update Entries" isLoading={loadingUpateEntriesBtn} />
							</div>
						</form>
					)}
					{isAllowedToPerformActivity(user, "/panel/edit-product-description") && (
						<form className="edit-form" onSubmit={updateProductDescriptionHandle}>
							<Container title="Description">
								{Config.languages.map((language, i) => (
									<div key={newProduct.description[language.code] + i || i} className="description-editor">
										<p className="description-tite">{language.label}</p>
										<MyEditor onChange={(code) => onProductDescriptionChange(language.code, code)} rtl={language.rtl} setContents={newProduct.description[language.code]} />
									</div>
								))}
							</Container>
							<div className="action-buttons-wrapper">
								<MyButton type="submit" label="Update Description" isLoading={loadingUpateDescriptionBtn} />
							</div>
						</form>
					)}
				</>
			)}
		</div>
	);
}
