/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import { calculateDiscountPercent, calculateProductPrice, toPrice } from "../../assets/helpers/numbersHelper";

export default function ProductPrice(props) {
	const { product, hideDiscountString = false, containerStyle, fontSize = null, tintColor = null, quantity = 1, price, oldPrice } = props;

	return (
		<div className="product-price-container" style={containerStyle}>
			<div className="price-wrapper">
				<p
					className="product-price"
					style={{
						color: price !== oldPrice ? (tintColor ? tintColor : "var(--tint)") : "var(--text)",
						fontSize: fontSize || 18,
					}}
				>
					{toPrice(quantity * (price || calculateProductPrice(product)), "ILS")}
				</p>
				{price !== oldPrice && <p className="old-price">{toPrice(quantity * (oldPrice || product.price), "ILS")}</p>}
			</div>
			{!hideDiscountString && price !== oldPrice && <p className="price-discount">{calculateDiscountPercent(oldPrice, price)}% OFF</p>}
		</div>
	);
}
