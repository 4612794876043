import Config from "../../config.json";

export function deepLinkToApp(pathname) {
	const segments = pathname.split("/");
	if (segments.length >= 3) {
		switch (segments[2].toLowerCase()) {
			case "product":
				handleProductDeepLink(segments);
				break;
			case "package":
				handlePackageDeepLink(segments);
				break;
			default:
				break;
		}
	}
}

const handleProductDeepLink = (segments) => {
	if (segments.length >= 5) {
		const productId = Number(segments[3]);
		const productEntryId = Number(segments[4]);
		if (productId && productEntryId) {
			const data = {
				action: "GO_TO_PRODUCT",
				productId: productId,
				entryId: productEntryId,
			};
			window.location.href = `${Config.appScheme}://?data=${JSON.stringify(data)}`;
		}
	}
};

const handlePackageDeepLink = (segments) => {
	if (segments.length >= 4) {
		const packageId = Number(segments[3]);
		if (packageId) {
			const data = {
				action: "GO_TO_PACKAGE",
				packageId: packageId,
			};
			window.location.href = `${Config.appScheme}://?data=${JSON.stringify(data)}`;
		}
	}
};
