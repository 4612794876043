/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch } from "react-redux";
import { getWebContactUs } from "../../redux/actions/products";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

export default function WebContactUsPage() {
	const dispatch = useDispatch();

	const [data, setData] = useState({});
	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [searchInput, setSearchInput] = useState("");

	const columns = [
		{
			name: "id",
		},
		{
			name: "fullName",
			label: "Full Name",
		},
		{
			name: "email",
		},
		{
			name: "phone",
		},
		{
			name: "message",
			width: 500,
		},
		{
			name: "sysCreationDate",
			type: "date",
		},
	];

	useEffect(() => {
		fetchWebContactUs();
	}, [pageNumber, limit, searchInput]);

	const fetchWebContactUs = () => {
		const request = {
			offset: (pageNumber - 1) * limit,
			limit: limit,
			searchInput: searchInput,
		};
		dispatch(
			getWebContactUs(
				request,
				(response) => {
					setData({ ...response });
				},
				(error) => {}
			)
		);
	};

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	const onTableSearch = (input) => {
		if (input === searchInput) {
			setData((prevData) => ({ ...prevData }));
			return;
		}
		setSearchInput(input);
		setPageNumber(1);
	};

	return (
		<div className="page-container web-contact-us-table">
			<MyTable
				title={{
					icon: <SupportAgentIcon />,
					label: "Website Contact Us",
				}}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onSearch={onTableSearch}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
			/>
		</div>
	);
}
