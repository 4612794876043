/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { deleteAds, fetchAds } from "../../redux/actions/calls";
import MyTable from "../../components/MyTable";
import CampaignIcon from "@mui/icons-material/Campaign";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { enqueueSnackbar } from "notistack";
import { getImage } from "../../assets/helpers/imagesHelper";
import MyButton from "../../components/MyButton";
import { openModal } from "../../redux/actions/modal";
import EditAd from "../../components/EditAd";

export default function Advertisings() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const history = useHistory();

	const [data, setData] = useState({});

	const columns = [
		{
			name: "image",
			render: (data) => <img className="col-ad-image" src={getImage(data.image, "advertisings")} alt="" />,
		},
		{
			name: "expirationDate",
			type: "date",
			align: "center",
		},
		{
			name: "action",
		},
		{
			label: "Edit",
			render: (data) => <MyButton label={`Edit Ad`} onClick={() => editAd(data)} />,
			align: "center",
			width: 90,
			permission: isAllowedToPerformActivity(user, "/panel/edit-ad"),
		},
	];

	const editAd = (ad) => {
		dispatch(openModal(<EditAd onFinish={getAds} ad={ad} />, "Edit Ad"));
	};

	useEffect(() => {
		getAds();
	}, []);

	const getAds = () => {
		dispatch(
			fetchAds(
				null,
				(response) => {
					const data = {
						totalRows: response.length,
						data: response,
					};
					setData(data);
				},
				(error) => {}
			)
		);
	};

	const addNewAd = () => {
		history.push("/panel/add-ads");
	};

	const onDelete = (selectedData) => {
		const request = {
			ids: selectedData.map((data) => data.id),
		};
		dispatch(
			deleteAds(
				request,
				(response) => {
					getAds();
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
				}
			)
		);
	};

	return (
		<div className="page-container advertisings">
			<MyTable
				title={{
					icon: <CampaignIcon />,
					label: "Ads Table",
				}}
				data={data}
				columns={columns}
				pageNumber={1}
				pageLimit={10}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onAddNew={{
					label: "Add New Ad",
					onClick: () => addNewAd(),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-ad"),
				}}
				actionButtons={[
					{
						label: "Delete",
						onClick: onDelete,
						permission: isAllowedToPerformActivity(user, "/panel/delete-ads"),
					},
				]}
				hideSearch
			/>
		</div>
	);
}
