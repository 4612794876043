export function isDateWithinDaysFromNow(utcDateFromDB, thresholdDays) {
	const dbDate = new Date(utcDateFromDB);
	const currentDate = new Date();
	const timeDifference = currentDate.getTime() - dbDate.getTime();
	const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
	return daysDifference <= thresholdDays;
}

export function convertUtcToLocal(utcDateString) {
	const utcDate = new Date(utcDateString);
	const localTimeZoneOffset = new Date().getTimezoneOffset();
	return new Date(utcDate.getTime() - localTimeZoneOffset * 60000);
}

export function convertLocalDateStringToUTC(localDateString) {
	const localDate = new Date(localDateString);
	const formattedUTCDate = localDate.toISOString().slice(0, 19).replace("T", " ");
	return formattedUTCDate;
}

export function convertLocalToUtc(localDate) {
	const localTimeZoneOffset = localDate.getTimezoneOffset();
	const utcDate = new Date(localDate.getTime() + localTimeZoneOffset * 60000);
	return utcDate;
}

export function convertDateToInputFormat(inputDateString) {
	const inputDate = convertUtcToLocal(inputDateString);

	const year = inputDate.getFullYear();
	const month = `0${inputDate.getMonth() + 1}`.slice(-2);
	const day = `0${inputDate.getDate()}`.slice(-2);
	const hours = `0${inputDate.getHours()}`.slice(-2);
	const minutes = `0${inputDate.getMinutes()}`.slice(-2);

	const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

	return formattedDate;
}

export function convertLocalDateToInputFormat(inputDate) {
	const year = inputDate.getFullYear();
	const month = `0${inputDate.getMonth() + 1}`.slice(-2);
	const day = `0${inputDate.getDate()}`.slice(-2);
	const hours = `0${inputDate.getHours()}`.slice(-2);
	const minutes = `0${inputDate.getMinutes()}`.slice(-2);

	const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

	return formattedDate;
}
