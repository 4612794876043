import "./style.scss";
import Logo from "../../assets/images/logo.jpg";
import TextInput from "../../components/TextInput";
import MyButton from "../../components/MyButton";
import { useDispatch } from "react-redux";
import { login, logout, setUser } from "../../redux/actions/user";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";

export default function LoginPage() {
	const dispatch = useDispatch();
	const [loadingButton, setLoadingButton] = useState(false);

	const onLogin = (e) => {
		e.preventDefault();
		const username = e.target.username.value;
		const password = e.target.password.value;
		const request = {
			username,
			password,
		};
		setLoadingButton(true);
		dispatch(
			login(
				request,
				null,
				(response) => {
					setLoadingButton(false);
					dispatch(setUser(response));
				},
				(error) => {
					setLoadingButton(false);
					switch (error.response?.data?.errorCode) {
						case 1005:
							enqueueSnackbar(`Incorrect username or password`, { variant: "error" });
							dispatch(logout());
							break;
						default:
							enqueueSnackbar(error.response?.data?.errorMessage, { variant: "error" });
							return;
					}
				}
			)
		);
	};

	return (
		<div className="login-page">
			<div className="login-page-body">
				<img className="logo" src={Logo} alt="" />
				<form className="login-form" onSubmit={onLogin}>
					<TextInput label="Username" name="username" />
					<TextInput label="Password" type="password" name="password" />
					<div className="action-buttons">
						<MyButton type="submit" label="Login" isLoading={loadingButton} />
					</div>
				</form>
			</div>
		</div>
	);
}
