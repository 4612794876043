/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";
import { useEffect, useState } from "react";
import { addTicketMessage, getTicketMessages, markTicketAsRead, toggleSupportTicketClosed } from "../../redux/actions/calls";
import { convertLocalToUtc, convertUtcToLocal } from "../../assets/helpers/dateHelper";
import CircularProgress from "@mui/material/CircularProgress";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import Constants from "../../constants/Constants";
import SendIcon from "@mui/icons-material/Send";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const limit = 10;

export default function Ticket(props) {
	const { id, closed, updateStatus, closeTicket } = props;
	const dispatch = useDispatch();
	const user = useSelector((state) => state.user);

	const [ticketId] = useState(id);
	const [isClosed, setIsClosed] = useState(closed);
	const [message, setMessage] = useState("");
	const [loadingConversation, setLoadingConversation] = useState(false);
	const [loadingMessage, setLoadingMessage] = useState(false);
	const [loadingClosed, setLoadingClosed] = useState(false);
	const [offset, setOffset] = useState(0);
	const [totalRows, setTotalRows] = useState(0);
	const [conversation, setConversation] = useState([]);

	useEffect(() => {
		if (ticketId) {
			markAsRead();
		}
	}, []);

	useEffect(() => {
		if (ticketId) {
			fetchTicketConversation();
		}
	}, [offset]);

	const markAsRead = () => {
		const request = {
			ticketId: ticketId,
		};
		dispatch(
			markTicketAsRead(
				request,
				(response) => {},
				(error) => {}
			)
		);
	};

	const fetchTicketConversation = () => {
		const request = {
			ticketId: ticketId,
			offset: offset,
			limit: limit,
		};
		setLoadingConversation(true);
		dispatch(
			getTicketMessages(
				request,
				(response) => {
					setTotalRows(response.totalRows);
					setConversation(offset === 0 ? response.data : [...conversation, ...response.data]);
					setLoadingConversation(false);
				},
				(error) => {
					setTotalRows(0);
					setConversation([]);
					setLoadingConversation(false);
				}
			)
		);
	};

	const onSendMessage = () => {
		sendTicketMessage(ticketId);
	};

	const sendTicketMessage = (ticketId) => {
		const request = {
			message: message,
			ticketId: ticketId,
		};
		setLoadingMessage(true);
		dispatch(
			addTicketMessage(
				request,
				(response) => {
					const newMessage = {
						message: message,
						username: user.username,
						fullName: user.fullName,
						sysCreationDate: convertLocalToUtc(new Date()),
					};
					setConversation([newMessage, ...conversation]);
					setMessage("");
					setTotalRows(totalRows + 1);
					setLoadingMessage(false);
				},
				(error) => {}
			)
		);
	};

	const onLoadMoreMessages = () => {
		if (offset + limit < totalRows) {
			setOffset(offset + limit);
		} else if (offset < totalRows - limit) {
			setOffset(totalRows - limit);
		}
	};

	const onToggleClosed = () => {
		const request = {
			ticketId: ticketId,
		};
		setLoadingClosed(true);
		dispatch(
			toggleSupportTicketClosed(
				request,
				(response) => {
					const newStatus = !isClosed;
					setIsClosed(newStatus);
					updateStatus(newStatus);
					setLoadingClosed(false);
				},
				(error) => {
					setLoadingClosed(false);
				}
			)
		);
	};

	return (
		<div className="user-ticket-chat">
			<div className="conversation-header">
				<div className="ticket-left-header-wrapper">
					<ArrowBackIosIcon className="ticket-back-btn" onClick={closeTicket} />
					<label>
						Ticket: <b>{ticketId}</b>
					</label>
				</div>
				<div>
					<FormControlLabel control={<Switch checked={isClosed} color="primary" size="medium" onChange={onToggleClosed} disabled={loadingClosed} />} label="Mark as closed" />
				</div>
			</div>
			<div className="ticket-conversation-wrapper">
				{conversation.map((message, index) => (
					<div key={index} className={message.username === user.username ? "message-container myMessage" : "message-container"}>
						<div className={message.username === user.username ? "message-wrapper myMessage" : "message-wrapper"}>
							<label className="message-sender">{message.fullName || message.username}</label>
							<label className="message-text">{message.message}</label>
							<label className="message-date-time">{convertUtcToLocal(message.sysCreationDate).toLocaleString().replace(",", "\n")}</label>
						</div>
					</div>
				))}
				{loadingConversation && (
					<div className="loading-conversation">
						<CircularProgress size={24} style={{ color: "var(--tint)" }} />
					</div>
				)}
				{conversation.length !== totalRows && (
					<button className="load-more-messages-btn" onClick={onLoadMoreMessages}>
						Load more messages
					</button>
				)}
			</div>
			<div className="new-message-container">
				{isClosed ? (
					<label className="closed-ticket">This ticket has been marked as closed.</label>
				) : (
					<>
						<TextareaAutosize
							className="message-resize-input"
							value={message}
							placeholder="Type your message here..."
							onChange={(e) => setMessage(e.target.value)}
							autoComplete="off"
							maxLength={Constants.ticketMessageMaxLength}
							minRows={1}
							maxRows={5}
							disabled={loadingConversation || loadingMessage}
						/>
						{message.trim().length > 0 && (
							<button className="send-message-wrapper" onClick={onSendMessage} disabled={message.trim().length === 0 || loadingMessage || isClosed}>
								{loadingMessage ? <CircularProgress size={17} style={{ color: "#FFF" }} /> : <SendIcon size={17} style={{ color: "#FFF" }} />}
							</button>
						)}
					</>
				)}
			</div>
		</div>
	);
}
