/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { fetchPrivacyPolicy } from "../../redux/actions/calls";
import { useDispatch } from "react-redux";
import i18n from "../../i18n";
import { CircularProgress } from "@mui/material";
import Config from "../../config.json";
import { withTranslation } from "react-i18next";
import { convertUtcToLocal } from "../../assets/helpers/dateHelper";

function PrivacyPage(props) {
	const { t } = props;
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	}, []);

	useEffect(() => {
		getPrivacyPolicy();
	}, []);

	const getPrivacyPolicy = () => {
		setLoading(true);
		dispatch(
			fetchPrivacyPolicy({}, (response) => {
				setData(response);
				setLoading(false);
			})
		);
	};

	const language = Config.languages.find((language) => language.code === i18n.language) || Config.languages.find((language) => language.code === Config.fallbackLng);

	return (
		<div className="privacy">
			<div className="page">
				{loading ? (
					<div className="loading-div">
						<CircularProgress style={{ color: "var(--tint)" }} />
					</div>
				) : (
					<>
						<p className="page-title">{t("privacyPolicy")}</p>
						<p className="privacy-last-update">
							{t("lastUpdate")}: {convertUtcToLocal(data.sysUpdateDate).toLocaleString()}
						</p>
						<div className={language.rtl ? "body-line-content se-rtl sun-editor-editable" : "body-line-content sun-editor-editable"} dangerouslySetInnerHTML={{ __html: data[i18n.language] }} />
					</>
				)}
			</div>
		</div>
	);
}

export default withTranslation()(PrivacyPage);
