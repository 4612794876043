const error = {
	message: null,
	code: null,
};

const errorReducer = (state = error, action) => {
	switch (action.type) {
		case "SET_ERROR":
			state.message = action.payload.errorMsg;
			state.code = action.payload.errorCode;
			return { ...state };
		case "CLEAR_ERROR":
			state.message = null;
			state.code = null;
			return { ...state };
		default:
			return state;
	}
};

export default errorReducer;
