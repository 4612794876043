/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import "./style.scss";
import { useSelector } from "react-redux";
import { getImage } from "../../assets/helpers/imagesHelper";
import { getProductNameWithVariations } from "../../assets/helpers/namesHelper";
import ProductPrice from "../ProductPrice";

export default function ProductCard(props) {
	const { product, orderItem } = props;

	const variations = useSelector((state) => state.products.variations);

	return (
		<div className="product-card">
			<img className="product-image" src={getImage(product.images[0], "product_images")} alt="" />
			<div className="product-info">
				<p className="product-name">{getProductNameWithVariations(product, variations, "en", true)}</p>
				<div className="order-info">
					<ProductPrice product={product} quantity={orderItem.quantity} price={orderItem.price} oldPrice={orderItem.fullPrice} />
					<span>
						Qty: <b>{orderItem.quantity}</b>
					</span>
				</div>
			</div>
		</div>
	);
}
