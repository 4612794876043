/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchCoupons } from "../../redux/actions/products";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { openModal } from "../../redux/actions/modal";
import { deleteCoupons } from "../../redux/actions/calls";
import { enqueueSnackbar } from "notistack";
import DiscountIcon from "@mui/icons-material/Discount";
import AddOrEditCoupon from "../../components/AddOrEditCoupon";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

export default function CouponsTablePage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const [data, setData] = useState({});

	const columns = [
		{
			name: "code",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-coupon") ? (
					<button className="table-row-name" onClick={() => openEditCouponModal(data)}>
						{data.code}
					</button>
				) : (
					data.code
				),
		},
		{
			name: "username",
			render: (data) => (
				<NavLink className="table-row-name" to={"/panel/accounts/" + data.accountId}>
					{data.username}
				</NavLink>
			),
		},
		{
			name: "brand",
		},
		{
			name: "category",
		},
		{
			name: "discount",
		},
		{
			name: "newUsersOnly",
			render: (data) => (data.newUsersOnly ? "Yes" : "No"),
		},
		{
			name: "startDate",
			type: "date",
		},
		{
			name: "endDate",
			type: "date",
		},
	];

	useEffect(() => {
		getCoupons();
	}, []);

	const getCoupons = () => {
		dispatch(
			fetchCoupons({}, (response) => {
				const data = {
					totalRows: response.length,
					data: response,
				};
				setData(data);
			})
		);
	};

	const openEditCouponModal = (coupon) => {
		dispatch(openModal(<AddOrEditCoupon onFinish={getCoupons} coupon={coupon} />, "Edit Coupon"));
	};

	const addNewCouponHandle = () => {
		dispatch(openModal(<AddOrEditCoupon onFinish={getCoupons} />, "Add New Coupon"));
	};

	const onDelete = (selectedData) => {
		const request = {
			ids: selectedData.map((data) => data.id),
		};
		dispatch(
			deleteCoupons(
				request,
				(response) => {
					getCoupons();
				},
				(error) => {
					enqueueSnackbar(error?.response?.data?.errorMessage, { variant: "error" });
				}
			)
		);
	};

	return (
		<div className="page-container coupons-list">
			<MyTable
				title={{
					icon: <DiscountIcon />,
					label: "Coupons List",
				}}
				data={data}
				columns={columns}
				pageNumber={1}
				pageLimit={10}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				onAddNew={{
					label: "Add New Coupon",
					onClick: () => addNewCouponHandle(),
					permission: isAllowedToPerformActivity(user, "/panel/add-new-coupon"),
				}}
				actionButtons={[
					{
						label: "Delete",
						onClick: onDelete,
						permission: isAllowedToPerformActivity(user, "/panel/delete-coupons"),
					},
				]}
			/>
		</div>
	);
}
