const Colors = [
	["--text", "#000"],
	["--lightText", "gray"],
	["--background", "#efefef"],
	["--onBackground", "#fff"],
	["--tint", "#0096d4"],
	["--lightTint", "#0096d430"],
	["--tintLow", "#0096d420"],
	["--secondary", "#f66608"],
	["--red", "#d90000"],
	["--borderColor", "#e4e4e4"],
	["--boxShadowColor", "rgb(161 172 184 / 15%)"],
	["--maxWidth", "1400px"],
];

export default Colors;
