import "./style.scss";

export default function Container(props) {
	const { title, children, vertical, childrenContainerStyle } = props;

	return (
		<div className={vertical ? "my-container vertical" : "my-container"} style={title ? {} : { border: "none", paddingTop: 0 }}>
			<span className="container-title">{title}</span>
			<div className="container-children" style={childrenContainerStyle}>
				{children}
			</div>
		</div>
	);
}
