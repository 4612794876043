/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@mui/icons-material/Add";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchShopByCategories, removeShopByCategory } from "../../redux/actions/calls";
import { getImage } from "../../assets/helpers/imagesHelper";
import AddShopByCategory from "../../components/AddShopByCategory";
import { openModal } from "../../redux/actions/modal";
import DeleteIcon from "@mui/icons-material/Delete";
import { enqueueSnackbar } from "notistack";
import { translateString } from "../../assets/helpers/namesHelper";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";

export default function ShopByCategory() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);

	const [categories, setCategories] = useState([]);

	useEffect(() => {
		getShopByCategories();
	}, []);

	const getShopByCategories = () => {
		dispatch(
			fetchShopByCategories(
				{},
				(response) => {
					setCategories(response);
				},
				(error) => {
					setCategories([]);
				}
			)
		);
	};

	const onAddNewCategory = () => {
		dispatch(openModal(<AddShopByCategory onFinish={getShopByCategories} />, "Add New Category"));
	};

	const onDeleteCategory = (category) => {
		const request = {
			categoryId: category.id,
			image: category.image,
		};
		dispatch(
			removeShopByCategory(
				request,
				(response) => {
					getShopByCategories();
					enqueueSnackbar(`Category [${translateString(category.categoryName, "en")}] successfully remove from shop by category list`, { variant: "success" });
				},
				(error) => {}
			)
		);
	};

	return (
		<div className="page-container shop-by-category">
			<div className="shop-by-category-card-wrapper">
				{categories.map((category, i) => (
					<div className="shop-by-category-card allow-delete" key={i}>
						{isAllowedToPerformActivity(user, "/panel/remove-shop-by-category") && (
							<IconButton className="remove-shop-by-category-btn" onClick={() => onDeleteCategory(category)}>
								<DeleteIcon style={{ fontSize: 18 }} />
							</IconButton>
						)}
						<img className="shop-by-category-img" src={getImage(category.image, "shop_by_category")} alt="" />
						<label>{translateString(category.categoryName, "en")}</label>
					</div>
				))}
				{isAllowedToPerformActivity(user, "/panel/add-new-shop-by-category") && (
					<div className="shop-by-category-card">
						<IconButton className="add-new-shop-by-category-btn" onClick={onAddNewCategory}>
							<AddIcon />
						</IconButton>
					</div>
				)}
			</div>
		</div>
	);
}
