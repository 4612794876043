/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import Image1 from "../../assets/images/image1.png";
import Image3 from "../../assets/images/image3.png";
import AppStore from "../../assets/images/app-store.webp";
import PlayStore from "../../assets/images/play-store.webp";
import OverviewCard from "../../components/OverviewCard";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import MyButton from "../../components/MyButton";
import { useDispatch, useSelector } from "react-redux";
import { contactUs, getWebData } from "../../redux/actions/calls";
import { useEffect, useState } from "react";
import { enqueueSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import { scrollTo } from "../../assets/helpers/navHelper";
import { withTranslation } from "react-i18next";
import Config from "../../config.json";

function LandingPage(props) {
	const { t } = props;

	const dispatch = useDispatch();
	const params = useParams();
	const system = useSelector((state) => state.system);

	const [state, setState] = useState({
		loadingSubmitContactUs: false,
		contactThankYou: {
			done: false,
			id: null,
		},
		loadingWebData: true,
		products: 0,
		users: 0,
		orders: 0,
	});

	useEffect(() => {
		if (params.viewId) {
			scrollTo(`#${params.viewId}`);
		}
		dispatch(
			getWebData(
				{},
				(response) => {
					setState({
						...state,
						loadingWebData: false,
						products: response.products < 200 ? response.products + 200 : response.products,
						users: response.users < 1000 ? response.users + 1000 : response.users,
						orders: response.orders < 500 ? response.orders + 500 : response.orders,
					});
				},
				(error) => {
					setState({
						...state,
						loadingWebData: false,
					});
				}
			)
		);
	}, []);

	const padNumberWithZeros = (number, minLength) => {
		const numberString = number.toString();

		if (numberString.length >= minLength) {
			return numberString;
		} else {
			const zerosToAdd = minLength - numberString.length;
			const zeroString = "0".repeat(zerosToAdd);
			return zeroString + numberString;
		}
	};

	const onSubmitContactUs = (e) => {
		e.preventDefault();
		const fullName = e.target.full_name.value;
		const phoneNumber = e.target.phone_number.value;
		const email = e.target.email.value;
		const message = e.target.message.value;
		if (fullName?.length < 2 || fullName?.length > 45) {
			enqueueSnackbar(t("nameError", { length: fullName.length }), { variant: "error" });
			changeBorderColor(e, "full_name", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (phoneNumber?.length < 9 || phoneNumber?.length > 15) {
			enqueueSnackbar(t("phoneError", { length: phoneNumber.length }), { variant: "error" });
			changeBorderColor(e, "phone_number", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (email?.length < 6 || email?.length > 45) {
			enqueueSnackbar(t("emailError", { length: email.length }), { variant: "error" });
			changeBorderColor(e, "email", "contact-us-input-wrapper", "var(--red)");
			return;
		}
		if (message?.length < 2 || message?.length > 512) {
			enqueueSnackbar(t("messageError", { length: message.length }), { variant: "error" });
			changeBorderColor(e, "message", "contact-us-textarea-wrapper", "var(--red)");
			return;
		}
		const request = {
			fullName: fullName,
			phone: phoneNumber,
			email: email,
			message: message,
		};
		setState({ ...state, loadingSubmitContactUs: true });
		dispatch(
			contactUs(
				request,
				(response) => {
					const contactThankYou = {
						done: true,
						id: padNumberWithZeros(response.id, 5),
					};
					setState({ ...state, loadingSubmitContactUs: false, contactThankYou: contactThankYou });
				},
				(error) => {
					setState({
						...state,
						loadingSubmitContactUs: false,
						contactThankYou: {
							done: false,
							id: null,
						},
					});
				}
			)
		);
		e.target.reset();
	};

	const changeBorderColor = (e, name, wrapper, color) => {
		const element = name ? e.target[name].closest("." + wrapper) : e.target.closest("." + wrapper);
		element.style.borderColor = color;
	};

	return (
		<div className="landing-page">
			<div className="home-page-container" id="home">
				<div className="home-page-container-content">
					<div className="container-content">
						<p className="protein-app-name">{t("appName")}</p>
						<p className="app-signature">
							{t("stronger")}. <label style={{ color: "var(--tint)" }}>{t("faster")}.</label> {t("healthier")}
						</p>
						<p className="app-intro">{t("welcomeIntro")}</p>
						{(system?.app?.appStoreLink || system?.app?.playStoreLink) && (
							<div className="download-now-container">
								<p className="download-now">{t("downloadNow")}</p>
								<div className="download-buttons">
									{system?.app?.appStoreLink && (
										<a href={system.app.appStoreLink} className="download-btn" target="_blank" rel="noreferrer">
											<img src={AppStore} alt="" />
										</a>
									)}
									{system?.app?.playStoreLink && (
										<a href={system.app.playStoreLink} className="download-btn" target="_blank" rel="noreferrer">
											<img src={PlayStore} alt="" />
										</a>
									)}
								</div>
							</div>
						)}
					</div>
					<div className="container-content-right">
						<img className="container-image" src={Image1} alt="" />
					</div>
				</div>
			</div>

			<div className="home-page-container" id="about_us">
				<div className="home-page-container-content" style={{ gap: 40 }}>
					<div className="container-content" style={{ backgroundColor: "var(--tint)", flex: 3, boxShadow: "0 0 rgba(0, 0, 0, 0), 0 0 rgba(0, 0, 0, 0), 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)" }}>
						<p className="who-we-are">{t("whoWeAre")}</p>
						<p className="who-we-are-value">
							{t("whoWeAre1")}
							<br />
							{t("whoWeAre2")}
							<br />
							{t("whoWeAre3")}
						</p>
					</div>
					<div className="container-content-right" style={{ flex: 4 }}>
						<p className="overview-title">{t("overviewTitle")}</p>
						<OverviewCard
							label={t("products")}
							value={state.products}
							isLoading={state.loadingWebData}
							description={t("productsDescription", { value: state.loadingWebData ? "" : Number(state.products).toLocaleString() + "+ " })}
						/>
						<OverviewCard label={t("users")} value={state.users} isLoading={state.loadingWebData} description={t("usersDescription", { value: state.loadingWebData ? "" : Number(state.users).toLocaleString() + "+ " })} />
						<OverviewCard
							label={t("orders")}
							value={state.orders}
							isLoading={state.loadingWebData}
							description={`${state.loadingWebData ? t("orders") : t("overOrders", { value: Number(state.orders).toLocaleString() })} ${t("ordersDescription")}`}
						/>
					</div>
				</div>
			</div>

			<div className="home-page-container" style={{ background: "var(--borderColor)" }}>
				<div className="home-page-container-content reverse">
					<div className="container-content" id="services">
						<p className="our-services-title">
							{t("ourUnmatched")} <label style={{ color: "var(--tint)" }}>{Config.appName}</label>
						</p>
						<div className="our-services">
							<div className="our-service">
								<div className="service-dot" />
								<p className="service-title">{t("serviceTitle1")}</p>
								<p className="service-description">{t("serviceDescription1")}</p>
							</div>
							<div className="our-service">
								<div className="service-dot" />
								<p className="service-title">{t("serviceTitle2")}</p>
								<p className="service-description">{t("serviceDescription2")}</p>
							</div>
							<div className="our-service">
								<div className="service-dot" />
								<p className="service-title">{t("serviceTitle3")}</p>
								<p className="service-description">{t("serviceDescription3")}</p>
							</div>
							<div className="our-service">
								<div className="service-dot" />
								<p className="service-title">{t("serviceTitle4")}</p>
								<p className="service-description">{t("serviceDescription4")}</p>
							</div>
						</div>
					</div>
					<div className="container-content-right">
						<img className="container-image" src={Image3} alt="" />
					</div>
				</div>
			</div>

			<div className="contact-us" id="contact_us">
				<form className="contact-us-form" onSubmit={onSubmitContactUs}>
					<p className="contact-us-title">{t("getInTouch")}</p>
					{state.contactThankYou.done ? (
						<div>
							<p className="contact-thanku-message">{t("thankYouForReachingOut")}</p>
							<p className="contact-thanku-message">
								{t("messageReceived")} <b style={{ color: "var(--tint)" }}>#[{state.contactThankYou.id}]</b>. {t("getBackToYou")}
							</p>
						</div>
					) : (
						<>
							<div className="contact-us-input-wrapper">
								<PersonIcon className="contact-us-input-icon" />
								<input className="contact-us-input" name="full_name" type="text" placeholder={t("yourName")} autoComplete="off" onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)} required />
							</div>
							<div className="contact-us-input-wrapper">
								<PhoneIcon className="contact-us-input-icon" />
								<input
									className="contact-us-input"
									name="phone_number"
									type="number"
									inputMode="numeric"
									placeholder={t("yourPhoneNumber")}
									autoComplete="off"
									onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)}
									required
									onWheel={(e) => e.target.blur()}
								/>
							</div>
							<div className="contact-us-input-wrapper">
								<EmailIcon className="contact-us-input-icon" />
								<input className="contact-us-input" name="email" type="email" placeholder={t("yourEmail")} autoComplete="off" onChange={(e) => changeBorderColor(e, null, "contact-us-input-wrapper", null)} required />
							</div>
							<div className="contact-us-textarea-wrapper">
								<textarea className="contact-us-textarea" name="message" placeholder={t("yourMessage")} autoComplete="off" onChange={(e) => changeBorderColor(e, null, "contact-us-textarea-wrapper", null)} required />
							</div>
							<MyButton label={t("submit")} type="submit" isLoading={state.loadingSubmitContactUs} disabled={state.contactThankYou.done} buttonStyle={state.contactThankYou.done ? "softDark" : "primary"} />
						</>
					)}
				</form>
			</div>
		</div>
	);
}

export default withTranslation()(LandingPage);
