import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { applyMiddleware, compose, legacy_createStore as createStore } from "redux";
import combinedReducers from "./redux/reducers";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
	apiKey: "AIzaSyC2O45gC-2ukGM2a5KlsIaaWPrgtSK9EUk",
	authDomain: "protein-plus-cd121.firebaseapp.com",
	projectId: "protein-plus-cd121",
	storageBucket: "protein-plus-cd121.appspot.com",
	messagingSenderId: "110703837330",
	appId: "1:110703837330:web:1cfc3de26088147a7a264b",
	measurementId: "G-7811MXQP37",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
getAnalytics(app);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(combinedReducers, composeEnhancers(applyMiddleware(thunk)));
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<BrowserRouter>
		<Provider store={store}>
			<App />
		</Provider>
	</BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
