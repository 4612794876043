import { useEffect, useState } from "react";
import "./style.scss";

export default function TextInput(props) {
	const { label, required, onChange, type = "text", inputMode, value, placeholder, name, disabled = false, step, dir } = props;
	const [inputValue, setInputValue] = useState(value || "");

	useEffect(() => {
		setInputValue(value || "");
	}, [value]);

	const onValueChange = (e) => {
		let newValue = e.target.value;
		if (type === "number" && newValue.includes("e")) {
			newValue = newValue.replaceAll("e", "");
		}
		setInputValue(newValue);
		onChange && onChange(type === "number" ? Number(newValue) : newValue);
	};

	return (
		<div className="my-text-input">
			<div className="text-input-wrapper">
				<label className={`text-input-label${inputValue || placeholder ? " with-value" : ""}${required ? " required" : ""}`}>{label}</label>
				{type === "textarea" ? (
					<textarea className="text-area" value={inputValue} onChange={onValueChange} placeholder={placeholder} name={name} rows={10} disabled={disabled} />
				) : (
					<input
						className="text-input"
						type={type}
						inputMode={inputMode}
						value={inputValue}
						onChange={onValueChange}
						onWheel={(e) => e.target.blur()}
						placeholder={placeholder}
						name={name}
						disabled={disabled}
						dir={dir}
						step={step}
					/>
				)}
			</div>
		</div>
	);
}
