import React from "react";
import "./style.scss";
import Button from "@material-ui/core/Button";
import CircularProgress from "@mui/material/CircularProgress";

export default function MyButton(props) {
	const { label, type, onClick, icon, isLoading = false, disabled = false, buttonStyle = "primary" } = props;

	return (
		<Button
			variant="contained"
			type={type}
			className={`my-button${disabled ? " disabled" : ""} ${buttonStyle}`}
			onClick={onClick}
			disabled={isLoading || disabled}
			startIcon={icon}
			endIcon={isLoading && <CircularProgress className="loading-button" />}
		>
			<span className="my-button-label">{label}</span>
		</Button>
	);
}
