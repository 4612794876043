/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import MyTable from "../../components/MyTable";
import { useDispatch, useSelector } from "react-redux";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { openModal } from "../../redux/actions/modal";
import EditBasePrice from "../../components/EditBasePrice";
import { getBasePrices } from "../../redux/actions/products";
import { getProductNameWithVariations } from "../../assets/helpers/namesHelper";
import { NavLink } from "react-router-dom";
import { toPrice } from "../../assets/helpers/numbersHelper";
import EditProductPrice from "../../components/EditProductPrice";

export default function BasePricesTablePage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const variations = useSelector((state) => state.products.variations);

	const [data, setData] = useState({});
	const [pageNumber, setPageNumber] = useState(1);
	const [limit, setLimit] = useState(localStorage.getItem("table-limit") ? Number(localStorage.getItem("table-limit")) : 10);
	const [searchInput, setSearchInput] = useState("");

	const columns = [
		{
			name: "productImages",
			type: "image",
			folder: "product_images",
			align: "center",
			label: "Image",
			width: 120,
		},
		{
			name: "productName",
			label: "Product Name",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/edit-product") || isAllowedToPerformActivity(user, "/panel/edit-product-description") || isAllowedToPerformActivity(user, "/panel/edit-product-entries") ? (
					<NavLink to={"/panel/products-table/" + data.productId} className="table-row-name">
						{getProductNameWithVariations(data, variations, "en", true, "productName")}
					</NavLink>
				) : (
					getProductNameWithVariations(data, variations, "en", true, "productName")
				),
			width: 500,
		},
		{
			name: "basePrice",
			label: "Base Price",
			type: "number",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/update-product-base-price") ? (
					<button className="table-row-name" onClick={() => openEditDataModal(data)}>
						{data.basePrice}
					</button>
				) : (
					data.basePrice
				),
		},
		{
			name: "productPrice",
			label: "Product Price",
			render: (data) =>
				isAllowedToPerformActivity(user, "/panel/update-product-price") ? (
					<button className="table-row-price" onClick={() => openEditProductPriceModal(data)}>
						<label
							style={{
								fontWeight: "bold",
								fontSize: 16,
								color: data.productPrice > data.basePrice ? "#00814d" : data.productPrice === data.basePrice ? "#e8b100" : "var(--red)",
							}}
						>
							{data.productPrice.toLocaleString()}
						</label>
					</button>
				) : (
					<label
						style={{
							fontWeight: "bold",
							fontSize: 16,
							color: data.productPrice > data.basePrice ? "#00814d" : data.productPrice === data.basePrice ? "#e8b100" : "var(--red)",
						}}
					>
						{data.productPrice.toLocaleString()}
					</label>
				),
		},
		{
			name: "productDiscount",
			label: "Product Discounted Price",
			render: (data) => {
				const discountedPrice = calculateProductDiscountedPrice(data);
				return (
					data.productDiscount && (
						<div>
							<label
								style={{
									fontWeight: "bold",
									fontSize: 16,
									color: discountedPrice > data.basePrice ? "#00814d" : discountedPrice === data.basePrice ? "#e8b100" : "var(--red)",
								}}
							>
								{discountedPrice.toLocaleString()}
							</label>
							<p style={{ margin: 0 }}>
								<b>{data.productDiscount.includes("%") ? data.productDiscount : toPrice(data.productDiscount, "ILS")}</b> Discount
							</p>
						</div>
					)
				);
			},
		},
		{
			label: "Net Profit",
			render: (data) => {
				const discountedPrice = calculateProductDiscountedPrice(data);
				const netProfit = discountedPrice - data.basePrice;
				return (
					<div>
						<label
							style={{
								fontWeight: "bold",
								fontSize: 16,
							}}
						>
							{netProfit.toLocaleString()}
						</label>
					</div>
				);
			},
		},
	];

	function calculateProductDiscountedPrice(product) {
		if (product.productDiscount) {
			if (product.productDiscount.includes("%")) {
				const discount = Number(product.productDiscount.replace("%", ""));
				return product.productPrice - (product.productPrice * discount) / 100;
			} else {
				return product.productPrice - Number(product.productDiscount);
			}
		}
		return product.productPrice;
	}

	useEffect(() => {
		fetchGetPrices();
	}, [pageNumber, limit, searchInput]);

	const fetchGetPrices = () => {
		const request = {
			offset: (pageNumber - 1) * limit,
			limit: limit,
			searchInput: searchInput,
		};
		dispatch(
			getBasePrices(request, (response) => {
				setData({ ...response });
			})
		);
	};

	const openEditDataModal = (data) => {
		dispatch(openModal(<EditBasePrice onFinish={fetchGetPrices} initData={data} />, "Update Base Price"));
	};

	const openEditProductPriceModal = (data) => {
		dispatch(openModal(<EditProductPrice onFinish={fetchGetPrices} initData={data} />, "Update Base Price"));
	};

	const onPageNumberChange = (pageNumber) => {
		setPageNumber(pageNumber);
	};

	const onPageLimitChange = (pageLimit) => {
		setLimit(pageLimit);
	};

	const onTableSearch = (input) => {
		if (input === searchInput) {
			setData((prevData) => ({ ...prevData }));
			return;
		}
		setSearchInput(input);
		setPageNumber(1);
	};

	return (
		<div className="page-container base-prices-table">
			<MyTable
				primaryKey="productEntryId"
				title={{
					icon: <PrecisionManufacturingIcon />,
					label: "Base Prices Table",
				}}
				data={data}
				columns={columns}
				pageNumber={pageNumber}
				pageLimit={limit}
				onPageNumberChange={onPageNumberChange}
				onPageLimitChange={onPageLimitChange}
				onSearch={onTableSearch}
				pageLimitOptions={[10, 25, 50, 100, 150, 200, 500, 1000]}
				exportButton
			/>
		</div>
	);
}
