/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import TextInput from "../../components/TextInput";
import Container from "../../components/Container";
import Config from "../../config.json";
import MySelect from "../../components/MySelect";
import React, { useCallback, useEffect, useState } from "react";
import { Checkbox, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { translateString } from "../../assets/helpers/namesHelper";
import { DropzoneArea } from "material-ui-dropzone";
import MyButton from "../../components/MyButton";
import { addNewProduct, getBrands, getCategories, getVariationValues, getVariations } from "../../redux/actions/products";
import { convertFileToBase64 } from "../../assets/helpers/fileHelper";
import { enqueueSnackbar } from "notistack";
import MyEditor from "../../components/MyEditor";
import { openModal } from "../../redux/actions/modal";
import AddNewBrand from "../../components/AddNewBrand";
import AddNewCategory from "../../components/AddNewCategory";
import AddNewVariationValue from "../../components/AddNewVariationValue";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import InventoryIcon from "@mui/icons-material/Inventory";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import { validateDoubleString } from "../../assets/helpers/validations";

const newProductStructure = {
	name: {},
	brandId: null,
	categoryId: null,
	description: {},
	entries: [],
	optionWithImage: null,
	optionWithPrice: null,
};

export default function AddNewProductPage() {
	const dispatch = useDispatch();

	const user = useSelector((state) => state.user);
	const specialties = useSelector((state) => state.products.specialties);

	const [newProduct, setNewProduct] = useState({ ...newProductStructure });
	const [variations, setVariations] = useState([]);
	const [brands, setBrands] = useState([]);
	const [categories, setCategories] = useState([]);
	const [checkedVariations, setCheckedVariations] = useState([]);
	const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
	const [loadingFetchVariationValues, setLoadingFetchVariationValues] = useState(false);
	const [variationValues, setVariationValues] = useState({});
	const [entries, setEntries] = useState([
		{
			barcode: null,
			quantity: null,
			price: null,
			discount: null,
			images: [],
		},
	]);

	const init = useCallback(() => {
		dispatch(
			getVariations({}, (response) => {
				setVariations(response);
			})
		);
		fetchBrands();
		fetchCategories();
	}, [dispatch]);

	useEffect(() => {
		init();
	}, [init]);

	const fetchBrands = () => {
		dispatch(
			getBrands({}, (response) => {
				setBrands(response);
			})
		);
	};

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onProductNameChange = (languageCode, value) => {
		newProduct.name[languageCode] = value;
	};

	const onBrandSelect = (value) => {
		newProduct.brandId = Number(value);
	};

	const onCategorySelect = (value) => {
		newProduct.categoryId = Number(value);
	};

	const onSpecialtyCheck = (isChecked, specialty) => {
		if (!newProduct.specialties) {
			newProduct.specialties = [];
		}
		if (isChecked) {
			newProduct.specialties.push(specialty.id);
		} else {
			const newSpecialties = newProduct.specialties?.filter((productSpecialty) => specialty.id !== productSpecialty);
			newProduct.specialties = newSpecialties;
		}
		setNewProduct({ ...newProduct });
	};

	const onOptionWithImageSelect = (value) => {
		if (newProduct.optionWithPrice === value) {
			newProduct.optionWithPrice = null;
		}
		newProduct.optionWithImage = value;
		setNewProduct({ ...newProduct });
	};

	const onOptionWithPriceSelect = (value) => {
		if (newProduct.optionWithImage === value) {
			newProduct.optionWithImage = null;
		}
		newProduct.optionWithPrice = value;
		setNewProduct({ ...newProduct });
	};

	const onVariationCheck = (checkedVariation) => {
		const variation = checkedVariations.find((variation) => variation.name === checkedVariation.name);
		if (variation) {
			const index = checkedVariations.indexOf(variation);
			checkedVariations.splice(index, 1);
		} else {
			checkedVariations.push(checkedVariation);
		}
		entries.forEach((entry) => delete entry[checkedVariation.name]);
		fetchVariationValues(checkedVariation);
		setCheckedVariations([...checkedVariations]);
	};

	const fetchVariationValues = (variation, forceFetch = false) => {
		setLoadingFetchVariationValues(true);
		if (!forceFetch) {
			if (Object.keys(variationValues).includes(variation.name)) {
				setLoadingFetchVariationValues(false);
				return;
			}
		}
		const request = {
			variationTable: variation.tableName,
		};
		dispatch(
			getVariationValues(
				request,
				(response) => {
					setVariationValues((prevVariationValues) => {
						return { ...prevVariationValues, [variation.name]: response };
					});
					setLoadingFetchVariationValues(false);
				},
				(error) => {
					setLoadingFetchVariationValues(false);
				}
			)
		);
	};

	const onEntryChange = (index, column, value) => {
		entries[index][column] = value;
	};

	const onEntryImagesChange = (entry, images) => {
		entry.images = images;
	};

	const validNewProductRequest = (request) => {
		const productName = JSON.parse(request.name);
		const productDescription = JSON.parse(request.description);
		const discountRegex = /^(\d{1,2}(\.\d+)?|100)%$|^\d+(\.\d+)?$/;

		for (const language of Config.languages) {
			if (!productName || !productName[language.code] || productName[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert product name in ${language.label}`, { variant: "error" });
				return false;
			}
			if (!productDescription[language.code] || productDescription[language.code].replace(/(<([^>]+)>)/gi, "").length === 0) {
				enqueueSnackbar(`Please insert product description in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		if (!request.brandId) {
			enqueueSnackbar(`Please choose brand`, { variant: "error" });
			return false;
		}
		if (!request.categoryId) {
			enqueueSnackbar(`Please choose category`, { variant: "error" });
			return false;
		}
		if (request.entries.length === 0) {
			enqueueSnackbar(`Please add at least one entry`, { variant: "error" });
			return false;
		}
		for (let i = 0; i < request.entries.length; i++) {
			const entry = request.entries[i];
			for (let v = 0; v < checkedVariations.length; v++) {
				const variation = checkedVariations[v];
				if (!entry[variation.name]) {
					enqueueSnackbar(`Please choose [Entry number ${i + 1}] ${translateString(variation.variation, "en")}`, { variant: "error" });
					return false;
				}
			}
			if (!entry.barcode || entry.barcode.trim().length === 0) {
				enqueueSnackbar(`Please insert [Entry number ${i + 1}] product entry barcode`, { variant: "error" });
				return false;
			}
			if (entry.quantity === null || entry.quantity < 0) {
				enqueueSnackbar(`Please insert valid [Entry number ${i + 1}] product entry quantity`, { variant: "error" });
				return false;
			}
			if (entry.quantity > 500) {
				enqueueSnackbar(`The quantity of [Entry number ${i + 1}] product cannot exceed 500`, { variant: "error" });
				return false;
			}
			if (!entry.price || entry.price <= 0 || !validateDoubleString(entry.price)) {
				enqueueSnackbar(`Please insert valid [Entry number ${i + 1}] product entry price`, { variant: "error" });
				return false;
			}
			if (entry.images.length === 0) {
				enqueueSnackbar(`Please add at least one image for [Entry number ${i + 1}] product entry`, { variant: "error" });
				return false;
			}
			if (entry.discount?.length > 0 && !discountRegex.test(entry.discount)) {
				enqueueSnackbar(`Please insert a valid discount for [Entry number ${i + 1}] product`, { variant: "error" });
				return false;
			}
		}
		return true;
	};

	const onProductDescriptionChange = (languageCode, value) => {
		newProduct.description[languageCode] = value;
	};

	const resetData = () => {
		setEntries([
			{
				barcode: null,
				quantity: null,
				price: null,
				discount: null,
				images: [],
			},
		]);
		setCheckedVariations([]);
		setNewProduct({
			name: {},
			brandId: null,
			categoryId: null,
			description: {},
			entries: [],
			optionWithImage: null,
			optionWithPrice: null,
		});
	};

	const onAddNewProductSubmit = async (e) => {
		e.preventDefault();
		const base64Entries = [];
		for (const entry of entries) {
			const base64Images = await Promise.all(
				entry.images.map(async (image) => {
					const base64 = await convertFileToBase64(image);
					return { path: base64 };
				})
			);
			base64Entries.push({
				...entry,
				images: base64Images,
			});
		}
		const request = {
			...newProduct,
			name: JSON.stringify(newProduct.name),
			entries: base64Entries,
			description: JSON.stringify(newProduct.description),
		};
		if (!validNewProductRequest(request)) {
			return;
		}
		setLoadingSubmitButton(true);
		dispatch(
			addNewProduct(
				request,
				(response) => {
					resetData();
					enqueueSnackbar(`New product added successfully`, { variant: "success" });
					setLoadingSubmitButton(false);
				},
				(error) => {
					setLoadingSubmitButton(false);
					switch (error.response?.data?.errorCode) {
						case 1008:
							enqueueSnackbar(`Product entry barcodes must be unique, Another product with same barcode was found`, { variant: "error" });
							break;
						default:
							return;
					}
				}
			)
		);
	};

	const addNewBrandHandle = () => {
		dispatch(openModal(<AddNewBrand onFinish={fetchBrands} />, "Add New Brand"));
	};

	const addNewCategoryHandle = () => {
		dispatch(openModal(<AddNewCategory onFinish={fetchCategories} />, "Add New Category"));
	};

	const addNewVariationValueHandle = (variation) => {
		dispatch(openModal(<AddNewVariationValue onFinish={() => fetchVariationValues(variation, true)} variation={variation} />, `Add New ${translateString(variation.variation, "en")} Value`));
	};

	const onAddNewEntry = () => {
		entries.push({
			barcode: null,
			quantity: null,
			price: null,
			discount: null,
			images: [],
		});
		setEntries([...entries]);
	};

	const onRemoveEntry = (index) => {
		entries.splice(index, 1);
		setEntries([...entries]);
	};

	return (
		<div className="page-container add-new-product">
			<div className="page-container-name">
				<InventoryIcon />
				<p>Add New Product</p>
			</div>
			<form className="add-new-product-form" onSubmit={onAddNewProductSubmit}>
				<Container title="Product Name">
					{Config.languages.map((language, i) => (
						<TextInput
							key={i}
							label={language.label}
							required
							value={newProduct.name[language.code] || ""}
							onChange={(value) => onProductNameChange(language.code, value)}
							dir={language.rtl ? "rtl" : "ltr"}
						/>
					))}
				</Container>
				<Container title="Brand & Category">
					<MySelect
						options={brands.map((brand) => {
							return { label: brand.name, value: brand.id };
						})}
						label="Brand"
						value={newProduct.brandId}
						addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-brand") && addNewBrandHandle}
						onChange={onBrandSelect}
						onRefresh={fetchBrands}
						required
					/>
					<MySelect
						options={categories.map((category) => {
							return { label: translateString(category.name, "en"), value: category.id };
						})}
						label="Category"
						value={newProduct.categoryId}
						addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-category") && addNewCategoryHandle}
						onChange={onCategorySelect}
						onRefresh={fetchCategories}
						required
					/>
				</Container>
				<Container title="Product Specialties">
					{specialties?.map((specialty, i) => (
						<FormControlLabel
							key={i}
							control={<Switch checked={newProduct.specialties?.includes(specialty.id) || false} color="primary" size="medium" onChange={(e, isChecked) => onSpecialtyCheck(isChecked, specialty)} />}
							label={translateString(specialty.name, "en")}
						/>
					))}
				</Container>
				<Container title="Description">
					{Config.languages.map((language, i) => (
						<div key={newProduct.description[language.code] + i || i} className="description-editor">
							<p className="description-tite">{language.label}</p>
							<MyEditor onChange={(code) => onProductDescriptionChange(language.code, code)} setContents={newProduct.description[language.code]} rtl={language.rtl} />
						</div>
					))}
				</Container>
				{variations.length > 0 && (
					<Container title="Variations">
						{variations?.map((variation, i) => (
							<FormControlLabel
								key={i}
								control={<Checkbox style={{ color: "var(--tint)" }} onChange={() => onVariationCheck(variation)} checked={checkedVariations.find((v) => v.name === variation.name) ? true : false} />}
								label={translateString(variation.variation, "en")}
								style={{ userSelect: "none" }}
							/>
						))}
					</Container>
				)}
				{checkedVariations.length > 0 && (
					<Container title="Options style">
						<MySelect
							options={checkedVariations.map((checkedVariation) => {
								return { label: translateString(checkedVariation.variation, "en"), value: checkedVariation.name };
							})}
							label="Option with image"
							withoutSearch
							onChange={onOptionWithImageSelect}
							value={newProduct.optionWithImage}
						/>
						<MySelect
							options={checkedVariations.map((checkedVariation) => {
								return { label: translateString(checkedVariation.variation, "en"), value: checkedVariation.name };
							})}
							label="Option with price"
							withoutSearch
							onChange={onOptionWithPriceSelect}
							value={newProduct.optionWithPrice}
						/>
					</Container>
				)}
				<Container title={`${entries.length} Product Entries`} childrenContainerStyle={{ backgroundColor: "var(--background)", borderRadius: "10px" }}>
					{entries.length === 0 ? (
						<p className="zero-entries-message">Please add at least one entry</p>
					) : (
						entries.map((entry, i) => (
							<div key={i} className="add-new-entry-row">
								<div className="new-entry-header">
									<RemoveCircleIcon className="remove-entry" onClick={() => onRemoveEntry(i)} />
									<label className="entry-number">{`Entry number ${i + 1}`}</label>
								</div>
								<div className="new-entry-row-inputs">
									{checkedVariations.map(
										(variation) =>
											variationValues[variation.name] && (
												<MySelect
													key={variation.name}
													options={variationValues[variation.name].map((variationValue) => {
														return { label: translateString(variationValue.value, "en"), name: variation.name, value: variationValue.id };
													})}
													label={translateString(variation.variation, "en")}
													addNewAction={isAllowedToPerformActivity(user, "/panel/add-new-variation-value") ? () => addNewVariationValueHandle(variation) : null}
													onChange={(selected) => onEntryChange(i, variation.name, selected)}
													value={Number(entry[variation.name])}
													onRefresh={() => fetchVariationValues(variation, true)}
													required
												/>
											)
									)}
									{loadingFetchVariationValues && <CircularProgress style={{ color: "var(--tint)" }} />}
									<TextInput label={"barcode"} onChange={(value) => onEntryChange(i, "barcode", value)} value={entry.barcode} required />
									<TextInput
										label={"Quantity"}
										onChange={(value) => onEntryChange(i, "quantity", value)}
										value={entry.quantity !== null ? entry.quantity + "" : ""}
										type="number"
										inputMode="numeric"
										required
									/>
									{isAllowedToPerformActivity(user, "/panel/update-product-base-price") && (
										<TextInput label={"Base Price"} onChange={(value) => onEntryChange(i, "basePrice", value)} value={entry.basePrice} type="number" inputMode="numeric" />
									)}
									<TextInput label={"Price"} onChange={(value) => onEntryChange(i, "price", value)} value={entry.price} type="number" inputMode="numeric" required />
									<TextInput
										label={"Discount"}
										onChange={(value) => onEntryChange(i, "discount", value)}
										value={entry.discount !== null ? entry.discount + "" : ""}
										placeholder="ex: 10%, 13.5%, 50, 99.5"
									/>
								</div>
								<div className="new-product-entry-images">
									<DropzoneArea
										key={new Date().getTime() + i}
										clearOnUnmount
										acceptedFiles={["image/*"]}
										dropzoneText={"Drag and drop an image here or click"}
										onChange={(files) => onEntryImagesChange(entry, files)}
										initialFiles={entry.images}
										showPreviews
										showPreviewsInDropzone={false}
										filesLimit={5}
										showAlerts={false}
										onAlert={(message, alertType) => alertType === "error" && enqueueSnackbar(`${message}`, { variant: alertType })}
									/>
								</div>
							</div>
						))
					)}
				</Container>
				<div className="add-new-entry">
					<button type="button" className="add-new-entry-btn" onClick={onAddNewEntry}>
						<AddCircleIcon className="add-new-entry-icon" />
						<label className="add-new-entry-label">Add New Entry</label>
					</button>
				</div>
				<div className="action-buttons-wrapper">
					<MyButton type="submit" label="Add Product" isLoading={loadingSubmitButton} />
				</div>
			</form>
		</div>
	);
}
