export const openSideBar = () => {
	return {
		type: "OPEN",
	};
};

export const closeSideBar = () => {
	return {
		type: "CLOSE",
	};
};

export const toggleSideBarKeepOpen = () => {
	return {
		type: "TOGGLE_KEEP_OPEN",
	};
};
