import "./style.scss";
import CircularProgress from "@mui/material/CircularProgress";

export default function OverviewCard(props) {
	const { label, value, description, isLoading } = props;

	return (
		<div className="overview-card">
			<div className="overview-label-value">
				<p className="overview-label">{label}</p>
				{isLoading ? <CircularProgress /> : <p className="overview-value">{value.toLocaleString()}</p>}
			</div>
			<p className="overview-description">{description}</p>
		</div>
	);
}
