import React from "react";
import "./style.scss";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { align, font, fontSize, formatBlock, hiliteColor, horizontalRule, image, link, list, paragraphStyle, table, textStyle, lineHeight, fontColor, blockquote } from "suneditor/src/plugins";

const MyEditor = (props) => {
	const { onChange, setContents, rtl } = props;
	return (
		<SunEditor
			className="sun-editor-editable se-rtl"
			setAllPlugins={true}
			setOptions={{
				height: window.innerHeight - 400,
				rtl: rtl,
				plugins: [align, font, fontSize, formatBlock, hiliteColor, horizontalRule, image, link, list, paragraphStyle, table, textStyle, lineHeight, fontColor, blockquote],
				attributesWhitelist: {
					all: "style|data-.+",
					input: "checked|name",
				},
				buttonList: [
					["undo", "redo"],
					["font", "fontSize", "formatBlock"],
					["bold", "underline", "italic", "strike", "subscript", "superscript"],
					["fontColor", "hiliteColor", "textStyle"],
					["removeFormat"],
					["outdent", "indent"],
					["align", "list", "lineHeight"],
					["table", "link", "image"],
					["fullScreen"],
				],
			}}
			defaultValue=""
			setContents={setContents}
			onChange={(code) => onChange && onChange(code)}
		/>
	);
};

export default MyEditor;
