export function toPrice(number, currency, minimumFractionDigits = 0, maximumFractionDigits = 2) {
	const formatter = new Intl.NumberFormat("he-IL", {
		style: "currency",
		currency,
		minimumFractionDigits,
		maximumFractionDigits,
	});

	return formatter.format(number);
}

export function calculateProductPrice(product) {
	if (product.discount) {
		if (product.discount.includes("%")) {
			const discount = Number(product.discount.replace("%", ""));
			return product.price - (product.price * discount) / 100;
		} else {
			return product.price - Number(product.discount);
		}
	}
	return product.price;
}

export function calculateDiscount(product, digitsAfterDot = 1) {
	return Math.abs(((calculateProductPrice(product) - product.price) / product.price) * 100).toFixed(digitsAfterDot);
}

export function calculateDiscountPercent(oldPrice, newPrice, digitsAfterDot = 1) {
	return Math.abs(((newPrice - oldPrice) / oldPrice) * 100).toFixed(digitsAfterDot);
}

export function toFixedNumber(num, fixed = 0) {
	if (num >= 1000000000000) {
		return (num / 1000000000000).toFixed(fixed) + "T";
	} else if (num >= 1000000000) {
		return (num / 1000000000).toFixed(fixed) + "B";
	} else if (num >= 1000000) {
		return (num / 1000000).toFixed(fixed) + "M";
	} else if (num >= 1000) {
		return (num / 1000).toFixed(fixed) + "K";
	} else return num;
}
