/* eslint-disable react-hooks/exhaustive-deps */
import "./style.scss";
import TextInput from "../../components/TextInput";
import Container from "../../components/Container";
import Config from "../../config.json";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DropzoneArea } from "material-ui-dropzone";
import MyButton from "../../components/MyButton";
import { enqueueSnackbar } from "notistack";
import InventoryIcon from "@mui/icons-material/Inventory";
import { validateDoubleString } from "../../assets/helpers/validations";
import { addNewPackage, getProducts } from "../../redux/actions/products";
import MySelect from "../../components/MySelect";
import { translateString } from "../../assets/helpers/namesHelper";
import { getImage } from "../../assets/helpers/imagesHelper";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { convertFileToBase64 } from "../../assets/helpers/fileHelper";
import { convertLocalDateStringToUTC } from "../../assets/helpers/dateHelper";

const newPackageStructure = {
	name: {},
	images: [],
	price: null,
	discount: null,
	startDate: null,
	expirationDate: null,
};

export default function AddNewPackagePage() {
	const dispatch = useDispatch();

	const variations = useSelector((state) => state.products.variations);

	const [newPackage, setNewPackage] = useState({ ...newPackageStructure });
	const [loadingSubmitButton, setLoadingSubmitButton] = useState(false);
	const [products, setProducts] = useState([]);
	const [entries, setEntries] = useState([
		{
			productId: null,
			variations: null,
		},
	]);

	useEffect(() => {
		init();
	}, []);

	const init = useCallback(() => {
		fetchProducts();
	}, []);

	const fetchProducts = (keyword = "") => {
		const request = {
			searchInput: keyword || "",
			hideOutOfStock: false,
			groupBy: "id",
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setProducts(response.data);
				},
				(error) => {
					setProducts([]);
				}
			)
		);
	};

	const onPackageNameChange = (languageCode, value) => {
		newPackage.name[languageCode] = value;
	};

	const onPackageDataChange = (key, value) => {
		newPackage[key] = value;
	};

	const onPackageImagesChange = (images) => {
		newPackage.images = images;
	};

	const onPackageDateChange = (event, key) => {
		const date = event.target.value;
		newPackage[key] = date;
		setNewPackage({ ...newPackage });
	};

	const isDateBefore = (date1Str, date2Str) => {
		const date1 = new Date(date1Str);
		const date2 = new Date(date2Str);

		return date1 < date2;
	};

	const onProductSelect = async (entry, value) => {
		entry.productId = Number(value);
		const request = {
			productIds: [value],
			limit: -1,
			hideOutOfStock: false,
		};
		await dispatch(
			getProducts(
				request,
				(response) => {
					entry.productEntries = response.data;
				},
				(error) => {
					entry.productEntries = [];
				}
			)
		);
		setEntries([...entries]);
	};

	const onRemoveEntry = (index) => {
		entries.splice(index, 1);
		setEntries([...entries]);
	};

	const onAddNewEntry = () => {
		entries.push({
			productEntry: null,
			variations: null,
		});
		setEntries([...entries]);
	};

	const onVariationClick = (entry, key, value) => {
		if (!entry.variations) {
			entry.variations = {};
		}
		if (entry.variations[key]?.includes(value)) {
			entry.variations[key] = entry.variations[key].filter((variationValue) => variationValue !== value);
		} else {
			if (Array.isArray(entry.variations[key])) {
				entry.variations[key].push(value);
			} else {
				entry.variations[key] = [value];
			}
		}
		setEntries([...entries]);
	};

	const validInput = () => {
		const discountRegex = /^(\d{1,2}(\.\d+)?|100)%$|^\d+(\.\d+)?$/;
		for (const language of Config.languages) {
			if (!newPackage.name || !newPackage.name[language.code] || newPackage.name[language.code].trim().length === 0) {
				enqueueSnackbar(`Please insert package name in ${language.label}`, { variant: "error" });
				return false;
			}
		}
		if (newPackage.images.length === 0) {
			enqueueSnackbar(`Please add at least one package image`, { variant: "error" });
			return false;
		}
		if (!newPackage.price || newPackage.price <= 0 || !validateDoubleString(newPackage.price)) {
			enqueueSnackbar(`Please insert valid package price`, { variant: "error" });
			return false;
		}
		if (newPackage.discount?.length > 0 && !discountRegex.test(newPackage.discount)) {
			enqueueSnackbar(`Please insert a valid discount value`, { variant: "error" });
			return false;
		}
		if (!newPackage.startDate || newPackage.startDate.length === 0) {
			enqueueSnackbar(`Please insert start date`, { variant: "error" });
			return false;
		}
		if (!newPackage.expirationDate || newPackage.expirationDate.length === 0) {
			enqueueSnackbar(`Please insert expiration date`, { variant: "error" });
			return false;
		}
		if (!isDateBefore(newPackage.startDate, newPackage.expirationDate)) {
			enqueueSnackbar(`Make sure that start date is before expiration date`, { variant: "error" });
			return false;
		}
		for (let i = 0; i < entries.length; i++) {
			const entry = entries[i];
			if (!entry.productId) {
				enqueueSnackbar(`Please choose product for [Package Product #${i + 1}]`, { variant: "error" });
				return false;
			}
		}
		return true;
	};

	const onAddNewPackageSubmit = async (e) => {
		e.preventDefault();
		if (!validInput()) {
			return;
		}
		setLoadingSubmitButton(true);
		const request = {
			name: JSON.stringify(newPackage.name),
			images: await Promise.all(
				newPackage.images.map(async (image) => {
					const base64 = await convertFileToBase64(image);
					return { path: base64 };
				})
			),
			price: newPackage.price,
			discount: newPackage.discount,
			startDate: convertLocalDateStringToUTC(newPackage.startDate),
			expirationDate: convertLocalDateStringToUTC(newPackage.expirationDate),
			products: entries.map((entry) => {
				return { productId: entry.productId, selectedVariations: JSON.stringify(entry.variations) };
			}),
		};
		dispatch(
			addNewPackage(
				request,
				(response) => {
					setEntries([
						{
							productId: null,
							variations: null,
						},
					]);
					setNewPackage({
						name: {},
						images: [],
						price: null,
						discount: null,
						startDate: null,
						expirationDate: null,
					});
					enqueueSnackbar(`New package added successfully`, { variant: "success" });
					setLoadingSubmitButton(false);
				},
				(error) => {
					setLoadingSubmitButton(false);
				}
			)
		);
	};

	return (
		<div className="page-container add-new-package">
			<div className="page-container-name">
				<InventoryIcon />
				<p>Add New Package</p>
			</div>
			<form className="add-new-package-form" onSubmit={onAddNewPackageSubmit}>
				<Container title="Package Name">
					{Config.languages.map((language, i) => (
						<TextInput
							key={i}
							label={language.label}
							required
							value={newPackage.name[language.code] || ""}
							onChange={(value) => onPackageNameChange(language.code, value)}
							dir={language.rtl ? "rtl" : "ltr"}
						/>
					))}
				</Container>
				<div className="new-package-entry-images">
					<DropzoneArea
						key={newPackage.images}
						clearOnUnmount
						acceptedFiles={["image/*"]}
						dropzoneText={"Drag and drop an image here or click"}
						onChange={(files) => onPackageImagesChange(files)}
						initialFiles={newPackage.images}
						showPreviews
						showPreviewsInDropzone={false}
						filesLimit={5}
						showAlerts={false}
						onAlert={(message, alertType) => alertType === "error" && enqueueSnackbar(`${message}`, { variant: alertType })}
					/>
				</div>
				<Container title="Package Price">
					<TextInput label={"Price"} onChange={(value) => onPackageDataChange("price", value)} value={newPackage.price} type="number" inputMode="numeric" required />
					<TextInput
						label={"Discount"}
						onChange={(value) => onPackageDataChange("discount", value)}
						value={newPackage.discount !== null ? newPackage.discount + "" : ""}
						placeholder="ex: 10%, 13.5%, 50, 99.5"
					/>
				</Container>
				<Container title="Package Effective Date">
					<div>
						<p className="aa-label-title">Start Date:</p>
						<input type="datetime-local" className="date-range-picker" value={newPackage.startDate || ""} onChange={(e) => onPackageDateChange(e, "startDate")} required />
					</div>
					<div>
						<p className="aa-label-title">Expiration Date:</p>
						<input type="datetime-local" className="date-range-picker" value={newPackage.expirationDate || ""} onChange={(e) => onPackageDateChange(e, "expirationDate")} required />
					</div>
				</Container>
				<Container title={`${entries.length} Package Products`} childrenContainerStyle={{ backgroundColor: "var(--background)", borderRadius: "10px" }}>
					{entries.length === 0 ? (
						<p className="zero-entries-message">Please add at least one entry</p>
					) : (
						entries.map((entry, i) => {
							const product = entry.productEntries ? entry.productEntries[0] : null;

							return (
								<div key={i} className="add-new-entry-row">
									<div className="new-entry-header">
										<RemoveCircleIcon className="remove-entry" onClick={() => onRemoveEntry(i)} />
										<label className="entry-number">{`Package Product #${i + 1}`}</label>
									</div>
									<div className="new-entry-row-inputs">
										<MySelect
											label="Products"
											options={products?.map((product) => {
												return { label: translateString(product.name, "en"), value: product.id };
											})}
											value={entry.productId}
											onChange={(value) => onProductSelect(entry, value)}
											onSearch={(input) => fetchProducts(input)}
											required
										/>
										{product && (
											<div className="selected-product-card">
												<img className="product-image" src={getImage(product.images[0], "product_images")} alt="" />
												<div className="product-info">
													<p className="product-name">{translateString(product.name, "en")}</p>
													{Object.keys(product)
														.filter((key) => key.endsWith("OptionId") && product[key])
														.map((optionKey, optionIndex) => {
															const optionValues = [];
															return (
																<div className="variation-btns-container" key={optionIndex}>
																	<label className="variation-name-label">
																		{translateString(variations.find((variation) => variation.name === optionKey.replace("Id", "")).variation, "en")}
																	</label>
																	<div className="variation-btns-wrapper">
																		{entry.productEntries.map((productEntry, vi) => {
																			if (!optionValues.includes(productEntry[optionKey])) {
																				optionValues.push(productEntry[optionKey]);
																				return (
																					<button
																						key={vi}
																						className={
																							entry.variations && entry.variations[optionKey]?.includes(productEntry[optionKey])
																								? "variation-option-btn checked"
																								: "variation-option-btn"
																						}
																						type="button"
																						onClick={() => onVariationClick(entry, optionKey, productEntry[optionKey])}
																					>
																						{translateString(productEntry[optionKey.replace("Id", "")], "en")}
																					</button>
																				);
																			}
																			return <label key={vi}></label>;
																		})}
																	</div>
																</div>
															);
														})}
												</div>
											</div>
										)}
									</div>
								</div>
							);
						})
					)}
				</Container>
				<div className="add-new-entry">
					<button type="button" className="add-new-entry-btn" onClick={onAddNewEntry}>
						<AddCircleIcon className="add-new-entry-icon" />
						<label className="add-new-entry-label">Add New Entry</label>
					</button>
				</div>
				<div className="action-buttons-wrapper">
					<MyButton type="submit" label="Add Package" isLoading={loadingSubmitButton} />
				</div>
			</form>
		</div>
	);
}
