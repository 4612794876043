/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import TextInput from "../TextInput";
import { useState } from "react";
import { enqueueSnackbar } from "notistack";
import { updateProductBasePrice } from "../../redux/actions/products";

export default function EditBasePrice(props) {
	const { initData, onFinish } = props;
	const dispatch = useDispatch();
	const [data, setData] = useState(initData.basePrice);
	const [loadingButton, setLoadingButton] = useState(false);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					<MyButton label="Update" onClick={editButtonHandle} isLoading={loadingButton} />
				</>
			)
		);
	}, [dispatch, loadingButton, data]);

	const onDataChange = (value) => {
		setData(value);
	};

	const editButtonHandle = () => {
		if (!data || data < 0) {
			enqueueSnackbar(`Please insert base price`, { variant: "error" });
			return;
		}
		const request = {
			productId: initData.productEntryId,
			price: data,
		};
		setLoadingButton(true);
		dispatch(
			updateProductBasePrice(
				request,
				(response) => {
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
				},
				(error) => {
					setLoadingButton(false);
				}
			)
		);
	};

	return (
		<div className="edit-base-price">
			<TextInput label="Base Price" onChange={onDataChange} value={data} type="number" inputMode="numeric" required />
		</div>
	);
}
