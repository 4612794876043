/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { editAppData, fetchAppConfig } from "../../redux/actions/calls";
import Container from "../../components/Container";
import TextInput from "../../components/TextInput";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import MyButton from "../../components/MyButton";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import { enqueueSnackbar } from "notistack";
import { validateDoubleString } from "../../assets/helpers/validations";
import SettingsIcon from "@mui/icons-material/Settings";

export default function AppConfigPage() {
	const dispatch = useDispatch();
	const [data, setData] = useState({});
	const [updateAppLoading, setUpdateAppLoading] = useState(false);

	const user = useSelector((state) => state.user);

	const isEditAllowed = isAllowedToPerformActivity(user, "/panel/edit-app-data");

	useEffect(() => {
		getAppConfig();
	}, []);

	const getAppConfig = () => {
		dispatch(
			fetchAppConfig({}, (response) => {
				setData(response);
			})
		);
	};

	const onDataChange = (column, value) => {
		setData({ ...data, [column]: value });
	};

	const validInput = () => {
		if (!data.storeLatitude || !validateDoubleString(data.storeLatitude)) {
			enqueueSnackbar(`Please insert a valid "Latitude" value`, { variant: "error" });
			return false;
		}
		if (!data.storeLongitude || !validateDoubleString(data.storeLongitude)) {
			enqueueSnackbar(`Please insert a valid "Longitude" value`, { variant: "error" });
			return false;
		}
		if (!data.deliveryTime || data.deliveryTime < 1) {
			enqueueSnackbar(`Please insert a valid "Delivery Time" value`, { variant: "error" });
			return false;
		}
		if (!data.minimumShippingFee || !validateDoubleString(data.minimumShippingFee)) {
			enqueueSnackbar(`Please insert a valid "Minimum Shipping Fee" value`, { variant: "error" });
			return false;
		}
		if (!data.shippingPerKM || !validateDoubleString(data.shippingPerKM)) {
			enqueueSnackbar(`Please insert a valid "Shipping Per KM" value`, { variant: "error" });
			return false;
		}
		if (!data.maxShippingFee || !validateDoubleString(data.maxShippingFee)) {
			enqueueSnackbar(`Please insert a valid "Max Shipping Fee" value`, { variant: "error" });
			return false;
		}
		if (!data.extraShippingOverKM || !validateDoubleString(data.extraShippingOverKM)) {
			enqueueSnackbar(`Please insert a valid "Extra Shipping Over KM" value`, { variant: "error" });
			return false;
		}
		if (!data.extraShippingFee || !validateDoubleString(data.extraShippingFee)) {
			enqueueSnackbar(`Please insert a valid "Extra Shipping Fee" value`, { variant: "error" });
			return false;
		}
		if (!data.freeShippingFrom || !validateDoubleString(data.freeShippingFrom)) {
			enqueueSnackbar(`Please insert a valid "Free Shipping From" value`, { variant: "error" });
			return false;
		}
		return true;
	};

	const updateAppHandle = (e) => {
		e.preventDefault();
		if (!validInput()) {
			return;
		}
		setUpdateAppLoading(true);
		dispatch(
			editAppData(
				data,
				(response) => {
					setUpdateAppLoading(false);
					enqueueSnackbar("App configurations successfully updated", { variant: "success" });
				},
				(error) => {
					setUpdateAppLoading(false);
				}
			)
		);
	};

	return (
		<div className="page-container app-config">
			<div className="page-container-name">
				<SettingsIcon />
				<p>App Configurations</p>
			</div>
			<form onSubmit={updateAppHandle}>
				<Container title="Store Location">
					<TextInput
						label="Latitude"
						value={data.storeLatitude + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("storeLatitude", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Longitude"
						value={data.storeLongitude + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("storeLongitude", value)}
						required
						disabled={!isEditAllowed}
					/>
				</Container>
				<Container title="Shipping">
					<TextInput
						label="Delivery Time (In days)"
						value={data.deliveryTime + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("deliveryTime", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Free Shipping From"
						value={data.freeShippingFrom + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("freeShippingFrom", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Minimum Shipping Fee"
						value={data.minimumShippingFee + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("minimumShippingFee", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Shipping Per KM"
						value={data.shippingPerKM + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("shippingPerKM", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Max Shipping Fee"
						value={data.maxShippingFee + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("maxShippingFee", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Extra Shipping Over KM"
						value={data.extraShippingOverKM + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("extraShippingOverKM", value)}
						required
						disabled={!isEditAllowed}
					/>
					<TextInput
						label="Extra Shipping Fee"
						value={data.extraShippingFee + ""}
						type="number"
						inputMode="numeric"
						step="any"
						onChange={(value) => onDataChange("extraShippingFee", value)}
						required
						disabled={!isEditAllowed}
					/>
				</Container>
				<Container title="Sensitive Configurations" vertical>
					<div style={{ display: "flex", flexDirection: "column" }}>
						<FormControlLabel
							control={<Switch checked={data.allowCash || false} color="primary" size="medium" onChange={(e, checked) => onDataChange("allowCash", checked)} disabled={!isEditAllowed} />}
							label="Allow Cash"
						/>
						<FormControlLabel
							control={<Switch checked={data.stopApp || false} color="primary" size="medium" onChange={(e, checked) => onDataChange("stopApp", checked)} disabled={!isEditAllowed} />}
							label="Stop App"
						/>
					</div>
				</Container>
				<Container title="Links" vertical childrenContainerStyle={{ marginTop: 15 }}>
					<TextInput label="App store link" value={data.appStoreLink} onChange={(value) => onDataChange("appStoreLink", value)} disabled={!isEditAllowed} />
					<TextInput label="Play store link" value={data.playStoreLink} onChange={(value) => onDataChange("playStoreLink", value)} disabled={!isEditAllowed} />
				</Container>
				<Container title="Social Media" vertical childrenContainerStyle={{ marginTop: 15 }}>
					<TextInput label="Whatsapp Number" value={data.whatsapp} onChange={(value) => onDataChange("whatsapp", value)} disabled={!isEditAllowed} />
					<TextInput label="Instagram username" value={data.instagram} onChange={(value) => onDataChange("instagram", value)} disabled={!isEditAllowed} />
					<TextInput label="Facebook user" value={data.facebook} onChange={(value) => onDataChange("facebook", value)} disabled={!isEditAllowed} />
				</Container>
				{isEditAllowed && (
					<div className="action-buttons-wrapper">
						<MyButton type="submit" label="Update App" isLoading={updateAppLoading} disabled={!isEditAllowed} />
					</div>
				)}
			</form>
		</div>
	);
}
