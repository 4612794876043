/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import { convertLocalDateStringToUTC, convertLocalDateToInputFormat, convertUtcToLocal } from "../../assets/helpers/dateHelper";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardData, getDashboardYearlyEarnings } from "../../redux/actions/calls";
import "./style.scss";
import { CircularProgress } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { stringToColour } from "../../assets/helpers/objectsHelper";
import { toPrice } from "../../assets/helpers/numbersHelper";
import { getImage } from "../../assets/helpers/imagesHelper";
import { getProductNameWithVariations } from "../../assets/helpers/namesHelper";
import StarsIcon from "@mui/icons-material/Stars";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import OrderViewer from "../../components/OrderViewer";
import { openModal } from "../../redux/actions/modal";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import LineChart from "../../components/LineChart";
import MySelect from "../../components/MySelect";
import { isAllowedToPerformActivity } from "../../assets/helpers/AuthHelper";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import { getProducts } from "../../redux/actions/products";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";

const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const oosLimit = 10;

export default function HomePage() {
	const dispatch = useDispatch();

	const variations = useSelector((state) => state.products.variations);
	const user = useSelector((state) => state.user);

	const thisMonth = new Date();
	thisMonth.setDate(1);
	thisMonth.setHours(0);
	thisMonth.setMinutes(0);
	const nextMonth = new Date();
	nextMonth.setMonth(nextMonth.getMonth() + 1);
	nextMonth.setDate(0);
	nextMonth.setHours(0);
	nextMonth.setMinutes(0);

	const [data, setData] = useState({});
	const [loadingData, setLoadingData] = useState(true);
	const [fromDate, setFromDate] = useState(convertLocalDateToInputFormat(thisMonth));
	const [toDate, setToDate] = useState(convertLocalDateToInputFormat(nextMonth));
	const [loadingEarningsData, setLoadingEarningsData] = useState(true);
	const [earningsYear, setEarningsYear] = useState(new Date().getFullYear());
	const [yearlyEarningsValues, setYearlyEarningsValues] = useState([]);
	const [outOfStock, setOutOfStock] = useState([]);
	const [outOfStockTotalRows, setOutOfStockTotalRows] = useState(0);
	const [oosOfset, setoosOffset] = useState(0);

	useEffect(() => {
		if (isAllowedToPerformActivity(user, "/panel/get-dashboard-data")) {
			const request = {
				fromDate: convertLocalDateStringToUTC(fromDate),
				toDate: convertLocalDateStringToUTC(toDate),
			};
			setLoadingData(true);

			dispatch(
				getDashboardData(
					request,
					(response) => {
						setData(response);
						setLoadingData(false);
					},
					(error) => {
						setData({});
						setLoadingData(false);
					}
				)
			);
		} else {
			setData({});
			setLoadingData(false);
		}
	}, [fromDate, toDate]);

	useEffect(() => {
		fetchOutOfStockProducts();
	}, [oosOfset]);

	const fetchOutOfStockProducts = () => {
		const request = {
			offset: oosOfset,
			limit: oosLimit,
			outOfStockOnly: true,
		};
		dispatch(
			getProducts(
				request,
				(response) => {
					setOutOfStock(oosOfset === 0 ? response.data : [...outOfStock, ...response.data]);
					setOutOfStockTotalRows(response.totalRows);
				},
				(error) => {
					setOutOfStock([]);
					setOutOfStockTotalRows(0);
				}
			)
		);
	};

	useEffect(() => {
		if (isAllowedToPerformActivity(user, "/panel/get-dashboard-earnings-by-year")) {
			const request = {
				year: earningsYear,
			};
			setLoadingEarningsData(true);
			dispatch(
				getDashboardYearlyEarnings(
					request,
					(response) => {
						setYearlyEarningsValues(response.data);
						setLoadingEarningsData(false);
					},
					(error) => {
						setYearlyEarningsValues([]);
						setLoadingEarningsData(false);
					}
				)
			);
		} else {
			setYearlyEarningsValues([]);
			setLoadingEarningsData(false);
		}
	}, [earningsYear]);

	const onOrderTogglePriceDetails = (e) => {
		const button = e.target.closest(".recent-order-total-btn");
		const priceDeatils = button.closest(".recent-order-price-container").querySelector(".recent-order-price-details-container");
		priceDeatils.classList.toggle("hidden");
		button.classList.toggle("visible");
	};

	const onViewRecentOrder = (order) => {
		dispatch(openModal(<OrderViewer order={order} />, `Order number: ${order.id}`));
	};

	const generateYearsOptions = () => {
		const response = [];
		for (let y = 2023; y <= new Date().getFullYear(); y++) {
			response.push({ label: y + "", value: y });
		}
		return response;
	};

	const onEarningsYearChange = (value) => {
		setEarningsYear(value);
	};

	const onLoadMoreOOSProducts = () => {
		if (oosOfset + oosLimit < outOfStockTotalRows) {
			setoosOffset(oosOfset + oosLimit);
		} else if (oosOfset < outOfStockTotalRows - oosLimit) {
			setoosOffset(outOfStockTotalRows - oosLimit);
		}
	};

	const chartThisYearData = {
		labels: monthNames,
		values: yearlyEarningsValues,
	};

	return (
		<div className="dashboard">
			{((isAllowedToPerformActivity(user, "/panel/get-dashboard-data") && data && Object.keys(data).length > 0) ||
				(isAllowedToPerformActivity(user, "/panel/get-dashboard-earnings-by-year") && yearlyEarningsValues.length > 0)) && (
				<div className="dashborad-toolbar">
					<div className="page-container-name">
						<GridViewIcon />
						<p>Dashboard</p>
					</div>
					{isAllowedToPerformActivity(user, "/panel/get-dashboard-data") && (
						<div className="dashboard-date-range">
							<div className="date-range-picker-wrapper">
								<label className="date-range-picker-label">From Date:</label>
								<input type="datetime-local" className="date-range-picker" value={fromDate} onChange={(e) => setFromDate(e.target.value)} required />
							</div>
							<div className="date-range-picker-wrapper">
								<label className="date-range-picker-label">To Date:</label>
								<input type="datetime-local" className="date-range-picker" value={toDate} onChange={(e) => setToDate(e.target.value)} required />
							</div>
						</div>
					)}
				</div>
			)}
			<div className="dashboard-body">
				{isAllowedToPerformActivity(user, "/panel/get-dashboard-data") && data && Object.keys(data).length > 0 && (
					<>
						<div className="dashboard-horizontal">
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.ordersCount + "") }}>
													{Number(data.ordersCount).toLocaleString()}
												</label>
												<label className="dashboard-box-label">Orders</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => ["new_card", "card"].includes(order.paymentMethod)).length}</b> Orders paid with a card
													</p>
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.paymentMethod === "cash").length}</b> Orders paid with a cash
													</p>
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.paymentMethod === "apple_pay").length}</b> Orders paid with apple pay
													</p>
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.paymentMethod === "google_pay").length}</b> Orders paid with google pay
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.fullPriceTotalSum + "") }}>
													{toPrice(Number(data.fullPriceTotalSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Full Price Sum</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														Order avg<b>{toPrice(Number(data.fullPriceTotalSum) / data.orders?.length || 0, "ILS")}</b>
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.subtotalSum + "") }}>
													{toPrice(Number(data.subtotalSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Subtotal Sum</label>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.discountsSum + "") }}>
													{toPrice(Number(data.discountsSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Discounts Sum</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.discounts > 0).length}</b> Orders with discount
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.couponDiscountsSum + "") }}>
													{toPrice(Number(data.couponDiscountsSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Coupon Discounts Sum</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.couponDiscounts > 0).length}</b> Orders with coupon discount
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.deliveryFeeSum + "") }}>
													{toPrice(Number(data.deliveryFeeSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Delivery Fee Sum</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														Delivery fee avg<b>{toPrice(Number(data.deliveryFeeSum) / data.orders?.length || 0, "ILS")}</b>
													</p>
													<p className="box-data-more-info">
														<b>{data.orders?.filter((order) => order.deliveryFee === 0).length}</b> Orders with free delivery
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box" style={{ minWidth: 150 }}>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="dashboard-box-label-value">
												<label className="dashboard-box-value" style={{ color: stringToColour(data.totalSum + "") }}>
													{toPrice(Number(data.totalSum), "ILS")}
												</label>
												<label className="dashboard-box-label">Total Sum</label>
												<div className="box-more-info-container">
													<p className="box-data-more-info">
														<b>{toPrice(data.totalCardSum, "ILS")}</b> paid with a card
													</p>
													<p className="box-data-more-info">
														<b>{toPrice(data.totalCashSum, "ILS")}</b> paid with a cash
													</p>
													<p className="box-data-more-info">
														<b>{toPrice(data.totalApplePaySum, "ILS")}</b> paid with apple pay
													</p>
													<p className="box-data-more-info">
														<b>{toPrice(data.totalGooglePaySum, "ILS")}</b> paid with google pay
													</p>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
						<div className="dashboard-horizontal">
							<div className="dashboard-box" style={{ maxWidth: 800, maxHeight: 500, margin: "0 auto" }}>
								<div className="dashboard-box-header">
									<div className="dashboard-box-header-left">
										<ReceiptLongIcon />
										<div className="dashboard-box-header-title-wrapper">
											<label className="dashboard-box-header-title">Income Breakdown</label>
											<label className="dashboard-box-header-subtitle">See a clear breakdown of where your money comes from</label>
										</div>
									</div>
									<div className="dashboard-box-header-right">
										<label className="from-to-date">
											{new Date(fromDate).toLocaleString()} <ArrowRightAltIcon style={{ color: "var(--lightText)" }} />
											{new Date(toDate).toLocaleString()}
										</label>
									</div>
								</div>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											<div className="income-breakdown-container">
												<div className="income-breakdown-detail">
													<label className="income-breakdown-detail-label">Orders</label>
													<b className="income-breakdown-detail-value">{data.orders?.length}</b>
												</div>
												<hr />
												{(data.couponDiscountsSum > 0 || data.discountsSum > 0) && (
													<>
														<div className="income-breakdown-detail">
															<label className="income-breakdown-detail-label">Full price</label>
															<b className="income-breakdown-detail-value">{toPrice(data.fullPriceTotalSum, "ILS")}</b>
														</div>
														{data.discountsSum > 0 && (
															<div className="income-breakdown-detail">
																<label className="income-breakdown-detail-label">Sale</label>
																<b className="income-breakdown-detail-value" style={{ color: "var(--red)" }}>
																	-{toPrice(data.discountsSum, "ILS")}
																</b>
															</div>
														)}
														{data.couponDiscountsSum > 0 && (
															<div className="income-breakdown-detail">
																<label className="income-breakdown-detail-label">Coupon Discounts</label>
																<b className="income-breakdown-detail-value" style={{ color: "var(--red)" }}>
																	-{toPrice(data.couponDiscountsSum, "ILS")}
																</b>
															</div>
														)}
													</>
												)}
												{(data.couponDiscountsSum > 0 || data.discountsSum > 0) && <hr />}
												<div className="income-breakdown-detail">
													<label className="income-breakdown-detail-label">Subtotal</label>
													<b className="income-breakdown-detail-value">{toPrice(data.subtotalSum, "ILS")}</b>
												</div>
												<div className="income-breakdown-detail">
													<label className="income-breakdown-detail-label">Shipping</label>
													<b className="income-breakdown-detail-value">{toPrice(data.deliveryFeeSum || 0, "ILS")}</b>
												</div>
												<hr />
												<div className="income-breakdown-detail">
													<label className="income-breakdown-detail-label">Total</label>
													<b className="income-breakdown-detail-value">{toPrice(data.totalSum, "ILS")}</b>
												</div>
											</div>
										</div>
									</div>
								)}
							</div>
							{outOfStockTotalRows > 0 && (
								<div className="dashboard-box" style={{ maxWidth: 800, maxHeight: 500, margin: "0 auto" }}>
									<div className="dashboard-box-header">
										<div className="dashboard-box-header-left">
											<ReportGmailerrorredIcon />
											<div className="dashboard-box-header-title-wrapper">
												<label className="dashboard-box-header-title">Out Of Stock Products</label>
												<label className="dashboard-box-header-subtitle">Track and manage out-of-stock products easily</label>
											</div>
										</div>
									</div>

									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body">
											{outOfStockTotalRows === 0 ? (
												<p className="nothing-to-show">Nothing to show</p>
											) : (
												outOfStock?.map((outOfStockProduct, index) => {
													return (
														<div key={index} className="oos-product-card">
															<img className="product-image" src={getImage(outOfStockProduct.images[0], "product_images")} alt="" />
															<div className="oos-product-info">
																<label className="product-name">{getProductNameWithVariations(outOfStockProduct, variations, "en", true)}</label>
																<label className="oos-product-label">Out Of Stock</label>
																<NavLink to={"/panel/products-table/" + outOfStockProduct.id} className="view-product-btn">
																	View Product
																</NavLink>
															</div>
														</div>
													);
												})
											)}
											{outOfStock.length !== outOfStockTotalRows && (
												<button className="load-more-oos-products-btn" onClick={onLoadMoreOOSProducts}>
													Load more products
												</button>
											)}
										</div>
									</div>
								</div>
							)}
						</div>
						<div className="dashboard-horizontal">
							<div className="dashboard-box">
								<div className="dashboard-box-header">
									<div className="dashboard-box-header-left">
										<StarsIcon />
										<div className="dashboard-box-header-title-wrapper">
											<label className="dashboard-box-header-title">Top {data.topProducts?.length} Best-Selling Products</label>
											<label className="dashboard-box-header-subtitle">Discover the hottest picks within your specified date ranges</label>
										</div>
									</div>
									<div className="dashboard-box-header-right">
										<label className="from-to-date">
											{new Date(fromDate).toLocaleString()} <ArrowRightAltIcon style={{ color: "var(--lightText)" }} />
											{new Date(toDate).toLocaleString()}
										</label>
									</div>
								</div>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body" style={{ minWidth: 300 }}>
											{data.topProducts?.length === 0 ? (
												<p className="nothing-to-show">Nothing to show</p>
											) : (
												data.topProducts?.map((topProduct, index) => {
													const product = topProduct.product;
													return (
														<div key={index} className="top-product-card">
															<img className="product-image" src={getImage(product.images[0], "product_images")} alt="" />
															<div className="top-product-info">
																<label className="product-name">{getProductNameWithVariations(product, variations, "en", true)}</label>
																<label className="top-product-sold-count">
																	<b>Sold Count: </b>
																	{topProduct.soldCount} Pcs
																</label>
															</div>
														</div>
													);
												})
											)}
										</div>
									</div>
								)}
							</div>
							<div className="dashboard-box">
								<div className="dashboard-box-header">
									<div className="dashboard-box-header-left">
										<ShoppingCartIcon />
										<div className="dashboard-box-header-title-wrapper">
											<label className="dashboard-box-header-title">Recent Order</label>
											<label className="dashboard-box-header-subtitle">Discover recent orders within your specified date ranges</label>
										</div>
									</div>
									<div className="dashboard-box-header-right">
										<label className="from-to-date">
											{new Date(fromDate).toLocaleString()} <ArrowRightAltIcon style={{ color: "var(--lightText)" }} />
											{new Date(toDate).toLocaleString()}
										</label>
									</div>
								</div>
								{loadingData ? (
									<div className="loading-box">
										<CircularProgress style={{ color: "var(--tint)" }} />
									</div>
								) : (
									<div className="dashboard-box-body-wrapper">
										<div className="dashboard-box-body" style={{ maxHeight: 500, minWidth: 300 }}>
											{data.orders?.length === 0 ? (
												<p className="nothing-to-show">Nothing to show</p>
											) : (
												data.orders?.map((order, index) => (
													<div key={index} className="recent-order-card">
														<div
															className="dot"
															style={{ backgroundColor: stringToColour(order.fullPriceTotal + ""), boxShadow: `${stringToColour(order.fullPriceTotal + "")} 0px 2px 4px 0px` }}
														/>
														<p className="datetime">{convertUtcToLocal(order.sysCreationDate).toLocaleString().replace(",", "")}</p>
														<div>
															<label className="recent-order-label">
																{order.accountId ? (
																	<NavLink className="recent-order-label-username" to={"/panel/accounts/" + order.accountId}>
																		<b>{order.fullName || order.username}</b>
																	</NavLink>
																) : (
																	<b style={{ color: "var(--red)" }}>DELETED ACCOUNT</b>
																)}{" "}
																has recently placed a new order <b>#{order.id}</b>
															</label>
															<div>
																<button className="view-recent-order-btn" onClick={() => onViewRecentOrder(order)}>
																	View Order
																</button>
															</div>
															<div className="recent-order-price-container">
																<button className="recent-order-total-btn" onClick={onOrderTogglePriceDetails}>
																	<label>Total:</label>
																	<div className="recent-order-total-value">
																		<b>{toPrice(order.total, "ILS")}</b>
																		<KeyboardArrowDownIcon className="recent-order-total-arrow" />
																	</div>
																</button>
																<div className="recent-order-price-details-container hidden">
																	{(order.couponDiscounts > 0 || order.discounts > 0) && (
																		<>
																			<div className="recent-order-price-detail">
																				<label className="recent-order-price-detail-label">Items Total ({order.items.length})</label>
																				<b className="recent-order-price-detail-value">{toPrice(order.fullPriceTotal, "ILS")}</b>
																			</div>

																			{order.discounts > 0 && (
																				<div className="recent-order-price-detail">
																					<label className="recent-order-price-detail-label">Sale</label>
																					<b className="recent-order-price-detail-value" style={{ color: "var(--red)" }}>
																						-{toPrice(order.discounts, "ILS")}
																					</b>
																				</div>
																			)}
																			{order.couponDiscounts > 0 && (
																				<div className="recent-order-price-detail">
																					<label className="recent-order-price-detail-label">Coupon Discounts</label>
																					<b className="recent-order-price-detail-value" style={{ color: "var(--red)" }}>
																						-{toPrice(order.couponDiscounts, "ILS")}
																					</b>
																				</div>
																			)}
																		</>
																	)}
																	{(order.couponDiscounts > 0 || order.discounts > 0) && <hr />}
																	<div className="recent-order-price-detail">
																		<label className="recent-order-price-detail-label">Subtotal</label>
																		<b className="recent-order-price-detail-value">{toPrice(order.subtotal, "ILS")}</b>
																	</div>
																	<div className="recent-order-price-detail">
																		<label className="recent-order-price-detail-label">Shipping</label>
																		<b className="recent-order-price-detail-value">{order.deliveryFee > 0 ? toPrice(order.deliveryFee || 0, "ILS") : "FREE"}</b>
																	</div>
																	<hr />
																	<div className="recent-order-price-detail">
																		<label className="recent-order-price-detail-label">Total</label>
																		<b className="recent-order-price-detail-value">{toPrice(order.total, "ILS")}</b>
																	</div>
																</div>
															</div>
														</div>
													</div>
												))
											)}
										</div>
									</div>
								)}
							</div>
						</div>
					</>
				)}
				{isAllowedToPerformActivity(user, "/panel/get-dashboard-earnings-by-year") && yearlyEarningsValues.length > 0 && (
					<div className="dashboard-horizontal">
						<div className="dashboard-box">
							<div className="dashboard-box-header">
								<div className="dashboard-box-header-left">
									<AttachMoneyIcon />
									<div className="dashboard-box-header-title-wrapper">
										<label className="dashboard-box-header-title">Total Earnings in {earningsYear}</label>
										<label className="dashboard-box-header-subtitle">Explore the monthly earnings graph for the specified year.</label>
									</div>
								</div>
								<div className="dashboard-box-header-right">
									<MySelect options={generateYearsOptions()} label="Year" style={{ marginLeft: "10px" }} value={earningsYear} onChange={onEarningsYearChange} withoutSearch required />
								</div>
							</div>
							{loadingEarningsData ? (
								<div className="loading-box">
									<CircularProgress style={{ color: "var(--tint)" }} />
								</div>
							) : (
								<LineChart data={chartThisYearData} vertical currency={"ILS"} />
							)}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}
