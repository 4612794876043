/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import "./style.scss";
import MyButton from "../MyButton";
import { useDispatch } from "react-redux";
import { closeModal, setModalFooter } from "../../redux/actions/modal";
import { enqueueSnackbar } from "notistack";
import { useState } from "react";
import { getCategories } from "../../redux/actions/products";
import MySelect from "../MySelect";
import { translateString } from "../../assets/helpers/namesHelper";
import Cropper from "react-easy-crop";
import { addNewShopByCategory } from "../../redux/actions/calls";

export default function AddShopByCategory(props) {
	const { onFinish } = props;
	const dispatch = useDispatch();
	const [categories, setCategories] = useState([]);
	const [loadingButton, setLoadingButton] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1);
	const [image, setImage] = useState(null);
	const [openCropper, setOpenCropper] = useState(false);
	const [croppedImage, setCroppedImage] = useState(null);

	useEffect(() => {
		fetchCategories();
	}, []);

	useEffect(() => {
		dispatch(
			setModalFooter(
				<>
					<MyButton label="Close" buttonStyle="soft" onClick={() => dispatch(closeModal())} />
					{selectedCategory && croppedImage && !openCropper && <MyButton label="Add" onClick={addButtonHandle} isLoading={loadingButton} />}
				</>
			)
		);
	}, [dispatch, loadingButton, selectedCategory, croppedImage, openCropper]);

	const fetchCategories = () => {
		dispatch(
			getCategories({}, (response) => {
				setCategories(response);
			})
		);
	};

	const onCategorySelect = (value) => {
		setSelectedCategory(Number(value));
	};

	const addButtonHandle = () => {
		if (!selectedCategory) {
			enqueueSnackbar(`Please select category`, { variant: "error" });
			return false;
		}
		if (!croppedImage) {
			enqueueSnackbar(`Please choose image to display`, { variant: "error" });
			return false;
		}
		const request = {
			categoryId: selectedCategory,
			image: croppedImage,
		};
		setLoadingButton(true);
		dispatch(
			addNewShopByCategory(
				request,
				(response) => {
					enqueueSnackbar(`new category successfully added to shop by category list`, { variant: "success" });
					setLoadingButton(false);
					onFinish && onFinish();
					dispatch(closeModal());
				},
				(error) => {
					setLoadingButton(false);
					switch (error.response?.data?.errorCode) {
						case 1003:
							enqueueSnackbar(`Something went wrong, check if the selected category already in shop by category list`, { variant: "error" });
							break;
						default:
							return;
					}
				}
			)
		);
	};

	const handleImageUpload = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = () => {
				setImage(reader.result);
				setOpenCropper(true);
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCropSubmit = () => {
		setOpenCropper(false);
	};

	const getCroppedImg = (imageSrc, pixelCrop, bgColor = "#ffffff") => {
		const canvas = document.createElement("canvas");
		const ctx = canvas.getContext("2d");

		const img = new Image();
		img.src = imageSrc;

		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;

		ctx.fillStyle = bgColor;
		ctx.fillRect(0, 0, canvas.width, canvas.height);

		ctx.drawImage(img, pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height, 0, 0, pixelCrop.width, pixelCrop.height);

		const base64Image = canvas.toDataURL("image/jpeg");
		return base64Image;
	};

	const onCropComplete = (croppedArea, croppedAreaPixels) => {
		const croppedImg = getCroppedImg(image, croppedAreaPixels);
		setCroppedImage(croppedImg);
	};

	return (
		<div className="add-new-category">
			<MySelect
				options={categories.map((category) => {
					return { label: translateString(category.name, "en"), value: category.id };
				})}
				label="Category"
				onChange={onCategorySelect}
				onRefresh={fetchCategories}
				required
			/>
			<div>
				<p className="asc-choose-image">Choose image:</p>
				<input type="file" onChange={handleImageUpload} accept="image/*" />
			</div>
			{openCropper ? (
				<div className="image-cropper">
					<div className="image-cropper-wrapper">
						<Cropper image={image} crop={crop} zoom={zoom} aspect={1 / 1} onCropChange={setCrop} onCropComplete={onCropComplete} onZoomChange={setZoom} />
					</div>
					<MyButton label="Crop Image" onClick={handleCropSubmit} />
				</div>
			) : (
				croppedImage && (
					<div className="image-data-container">
						<img className="cropped-image" src={croppedImage} alt="" />
					</div>
				)
			)}
		</div>
	);
}
