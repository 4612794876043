import { call } from "../../api/call";

export const getWebData = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "web/get-web-data", request);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const contactUs = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "web/contact-us", request);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewBrand = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-brand", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewSpecialty = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-specialty", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewCategory = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-category", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewVariationValue = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-variation-value", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editCategory = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-category", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editBrand = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-brand", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editSpecialty = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-specialty", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteBrands = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-brands", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteSpecialties = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-specialties", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteCategories = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-categories", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editVariationValue = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-variation-value", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteVariationValues = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-variation-values", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewAd = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-ad", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const fetchShopByCategories = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "fetch-shop-by-categories", request);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const removeShopByCategory = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/remove-shop-by-category", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewShopByCategory = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-shop-by-category", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const fetchAds = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/fetch-ads", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteAds = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-ads", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editAd = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-ad", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const deleteCoupons = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/delete-coupons", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editCoupon = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-coupon", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addNewCoupon = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/add-new-coupon", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const sendPushNotifications = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/send-push-notifications", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const toggleProductsHiddenInd = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/toggle-products-hidden-ind", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const toggleProductEntriesHiddenInd = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/toggle-product-entries-hidden-ind", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getStaffMembers = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-staff-members", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getAccounts = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-accounts", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getPermissions = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-permissions", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editAccount = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-account", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const toggleAccountPermission = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/toggle-account-permission", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const toggleStaffMember = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/toggle-staff-member", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const fetchAppConfig = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "fetch-app-config", request);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editAppData = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-app-data", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getSupportTickets = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-support-tickets", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const markTicketAsRead = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "mark-ticket-as-read", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getTicketMessages = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "get-ticket-messages", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const addTicketMessage = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "add-ticket-message", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const toggleSupportTicketClosed = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/toggle-support-ticket-closed", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getDashboardData = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-dashboard-data", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getDashboardDataPremium = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-dashboard-data-premium", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getAccountAddresses = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-account-addresses", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getAccountCards = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-account-cards", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getAccountLogs = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-account-logs", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const decrypt = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/decrypt", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const getDashboardYearlyEarnings = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/get-dashboard-earnings-by-year", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const editPackage = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/edit-package", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const updatePrivacyPolicy = (request, onSuccess, onFail) => {
	return async (dispatch, getState) => {
		try {
			const user = getState().user;
			const headers = {
				Authorization: user.id,
			};
			const response = await call(dispatch, "POST", "panel/update-privacy-policy", request, headers);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};

export const fetchPrivacyPolicy = (request, onSuccess, onFail) => {
	return async (dispatch) => {
		try {
			const response = await call(dispatch, "POST", "fetch-privacy-policy", request);
			onSuccess && onSuccess(response);
		} catch (error) {
			onFail && onFail(error);
		}
	};
};
